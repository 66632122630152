import React from "react";
import "./footerpages.css";
import Header from "../Header";
import Footer from "../Footer";

function GroupHealthInsurance() {
  return (
    <>
      <Header />
      <div>
        <div className="insurance-cantainer">
          <h1>Group Health insurance</h1>
        </div>
        <div className="cantainer">
          <div className="box-info">
            <div className="box-1">
              <h2>
                "Exploring Group Health Insurance: Benefits and Coverage
                Details"
              </h2>
              <p>
                Group health insurance is a type of insurance coverage that
                provides healthcare benefits to a group of individuals,
                typically employees of a company or members of an organization.
                It is a cost-effective way for employers to offer healthcare
                benefits to their employees and their families. Group health
                insurance policies provide coverage for a wide range of medical
                services, including doctor visits, hospitalization, prescription
                drugs, preventive care, and more. One of the primary advantages
                of group health insurance is that it often comes with lower
                premiums compared to individual health insurance policies.
              </p>
              <p>
                Another benefit is that group health insurance typically covers
                pre-existing conditions, and members are not usually required to
                undergo medical underwriting. Additionally, it offers a sense of
                security and access to a network of healthcare providers for
                necessary medical care. Understanding the terms and conditions
                of the group health insurance policy is crucial to make the most
                of the benefits. Employees should be aware of the coverage
                details, deductibles, co-payments, and any restrictions on
                healthcare providers. It's essential for both employers and
                employees to review and choose a plan that suits the needs of
                the group while providing adequate healthcare coverage for all
                members.
              </p>
            </div>
            <div className="box-2 group-health">
              <img src="./assetss/images/group-health.png" alt="Erroe" />
            </div>
          </div>
        </div>
        <div className="vision-info">
          <div className="vision-box">
            <div className="img-box">
              <img src="./assetss/images/vision.png" alt="Error" />
            </div>
            <div className="vision-box1">
              <h2>Our vision</h2>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend to repeat predefined chunks as necessary,
              </p>
            </div>
          </div>
        </div>
        <div className="btn-cantainer">
          <div className="btn-1">
            <h1>Solving your Insurance Needs on Time</h1>
          </div>
          <div className="btn-1">
            <h2>
              Get world-class insurance coverage that not only protects you but
              also helps you build wealth. Let us be your insurance partner.
            </h2>
            <div className="btn-info">
              <button>Get Started</button>
              <button>Contact Us</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default GroupHealthInsurance;
