import React from 'react'
import { addonsQues } from '../../../utility/constants';
import { Modal, ModalBody } from 'reactstrap';
import { useSelector } from 'react-redux';
import { dispatchMotorQuote } from '../../../../store/actions/userActions';

const PrevAddonsModal = ({togglePrevAddonsModal, prevAddonsModal,motorQuotation, addonsQuesAsk, planDetail, toggleModal2}) => {
    const motorRequest = useSelector((state) => state?.root?.motorRequest);
  console.log("addonsQuesAsk", addonsQuesAsk);
  const handleChangeAddon = (e) => {
    const { name, checked } = e.target;
    console.log("name checked", name, checked);
    dispatchMotorQuote(`${name}`, checked);
  };

  const handlePrevAddon = () => {
    console.log("motorRequest", motorRequest);
    if (
      !motorRequest?.isPrevZeroDepreciationCover ||
      !motorRequest?.isPrevInvoiceCover ||
      !motorRequest?.isPrevEngineProtection ||
      !motorRequest?.isPrevTyreProtection
    ) {
      dispatchMotorQuote("updatedFutureQuotes", true);
      motorQuotation(motorRequest);
    } else {
      toggleModal2(true)
    }
    togglePrevAddonsModal();
  };


    
    return (
        <Modal
          isOpen={prevAddonsModal}
          toggle={togglePrevAddonsModal}
          size="md"
          // centered
          backdrop="static"
        >
          <ModalBody>
            <div className="vehicle_modal">
              <div className="modal-logo">
                <a>
                  <img src="/assets/images/ex_new_2.png"></img>
                </a>
              </div>
              <div className="modal_heading w-100 mb-4">
                <h4 className="text-center">
                  Do you have these Addons in Previous Policy?
                 
                </h4>
                <div onClick={togglePrevAddonsModal} class="v2_close false">
                  <a>
                    <img
                      src="/assets/images/delete_icon.svg"
                      title="Close"
                      alt="close"
                    />
                  </a>
                </div>
              </div>
              <div>
              {addonsQuesAsk &&
              addonsQuesAsk.map((ques) => (
                <div className="my-2 d-flex align-items-center">
                  <input
                    type="checkbox"
                    id={ques}
                    className="mx-2"
                    style={{width:"20px",height:"20px"}}
                    name={`${
                      ques === addonsQues.zeroDep.ques
                        ? addonsQues.zeroDep.prevAddon
                        : ques === addonsQues.invoiceCover.ques
                        ? addonsQues.invoiceCover.prevAddon
                        : ques === addonsQues.engineProtection.ques
                        ? addonsQues.engineProtection.prevAddon
                        : ques === addonsQues.tyreProtection.ques
                        ? addonsQues.tyreProtection.prevAddon
                        : null
                    }`}
                    defaultChecked
                    onChange={handleChangeAddon}
                  />
                  <label htmlFor={ques} className='fs-3'>{`${
                    ques === addonsQues.zeroDep.ques
                      ? addonsQues.zeroDep.label
                      : ques === addonsQues.invoiceCover.ques
                      ? addonsQues.invoiceCover.label
                      : ques === addonsQues.engineProtection.ques
                      ? addonsQues.engineProtection.label
                      : ques === addonsQues.tyreProtection.ques
                      ? addonsQues.tyreProtection.label
                      : null
                  }`}</label>
                </div>
              ))}
              </div>
              <div className="d-flex justify-content-center mt-4">
            <button
              type="button"
              className="btn btn-primary mb-5 mt-3 py-3 w-100 fs-1 fw-bold"
              onClick={handlePrevAddon}
            >
              {!motorRequest?.isPrevZeroDepreciationCover ||
              !motorRequest?.isPrevInvoiceCover ||
              !motorRequest?.isPrevEngineProtection ||
              !motorRequest?.isPrevTyreProtection
                ? "Update"
                : "Continue"}
            </button>
          </div>
             
               
              
            </div>
          </ModalBody>
        </Modal>
      );
}

export default PrevAddonsModal