import React, { useState } from "react";
import { BeatLoader } from "react-spinners";
import { Modal, ModalBody } from "reactstrap";

const RedirectionModal = ({ show, setModal, url }) => {
  const toggleRedirectionModal = () => setModal(!show);
  const [loading, setLoading] = useState(true);
  console.log("url", url);

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <>
      <Modal
        isOpen={show}
        toggle={toggleRedirectionModal}
        centered
        size="xl"
        backdrop="static"
        keyboard={false}
        id="redirectionModal"
      >
        <ModalBody className="position-relative">
          <div class="">
            <div class="gsc_modalWrapper">
              <>
                <div className="modal-logo">
                  <a>
                    <img src="/assets/images/insurefastlogos.svg"></img>
                  </a>
                </div>
                <div className="modal_heading w-100 mb-4">
                  {/* <h4 className="text-center">Login with Mobile Number</h4> */}
                  <div onClick={toggleRedirectionModal} class="v2_close false">
                    <a>
                      <img
                        src="/assets/images/delete_icon.svg"
                        title="Close"
                        alt="close"
                      />
                    </a>
                  </div>
                </div>
                <div
                  className="d-flex flex-column justify-content-center align-items-center"
                  style={{ height: "100%" }}
                >
                  {loading && (
                    <div
                      className="loader"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <BeatLoader color="#56b96e" />
                    </div>
                  )}

                  <iframe
                    className="position-relative"
                    style={{
                      width: "100%",
                      minHeight: "600px",
                      maxHeight: "600px",
                      border: "none",
                    }}
                    src={url}
                    title="External Content"
                    onLoad={handleLoad}
                  />
                </div>
              </>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default RedirectionModal;
