import React from "react";
import { useForm } from "react-hook-form";
import {
  handleBecomePos,
  handleGetBranch,
  handleGetDistrict,
  handleGetStates,
  handleRegisterPos,
  sendErrorInfo,
  sendErrorMessage,
  sendSuccessMessage,
} from "../../Services/PosService";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../common/Layout";
import { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useEffect } from "react";
import { BankNames } from "../../utility/BankNames";
const BecomePos = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    clearErrors,
  } = useForm({ mode: "onChange" });
  const {
    register: register1,
    setValue,
    formState: { errors: errors1 },
    handleSubmit: handleSubmit1,
    reset: reset1,
  } = useForm({ mode: "onChange" });

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const [activeStep, setActiveStep] = useState(1);
  const [userData, setUserData] = useState(null);
  const [stateData, setStateData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [userError, setUserError] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");
  const [checkboxAccepted, setCheckboxAccepted] = useState(false);
  const [checkboxError, setCheckboxError] = useState(false);
  const [allowRedirect, setAllowRedirect] = useState(true);
  const [images, setImages] = React.useState({
    aadhar_front: null,
    aadhar_back: null,
    marksheet_image: null,
    pan_image: null,
    passbook_cheque: null,
    other_doc: null,
    profile_picture: null,
  });

  useEffect(() => {
    handleGetStates().then((response) => {
      if (response.status === true) {
        setStateData(response.data);
      }
    });
  }, []);

  const handleStepSelection = (step) => {
    setActiveStep(step);
  };

  const getDistricts = (id) => {
    handleGetDistrict(id).then((response) => {
      if (response.status === true) {
        setDistrictData(response.data);
      }
    });
  };
  const getBranches = (id) => {
    handleGetBranch(id).then((response) => {
      if (response.status === true) {
        setBranchData(response.data);
      }
    });
  };
  const handleUploadFile = (e) => {
    //check if the file is an image

    if (
      e.target.files[0].type.includes("image") === true ||
      e.target.files[0].type.includes("pdf") === true
    ) {
      setImages((prevImages) => ({
        ...prevImages,
        [e.target.name]: e.target.files[0],
      }));
    } else {
      return sendErrorInfo("Please upload an image or pdf file");
    }
  };

  const navigate = useNavigate();
  const onSubmit = (data) => {
    console.log("become pos", data);
    let postData = {
      name: data.firstname + " " + data.lastname,
      phone: data.phone,
      email: data.email,
    };
    const formdata = new FormData();
    formdata.append("name", data.firstname + " " + data.lastname);
    formdata.append("phone", data.phone);
    formdata.append("email", data.email);

    handleBecomePos(formdata).then((response) => {
      if (response.status === true) {
        setUserError(false);
        // setModal(true)
        setActiveStep(activeStep + 1);
        postData.id = response.data.id;
        setUserData(postData);
        reset();
        // navigate("/posregister", {
        //   state: {
        //     posData: {
        //       id: response.data.id,
        //       ...data,
        //     },
        //   },
        // });
      } else {
        // sendErrorMessage(response);
        console.log("error check", response);
        setUserError(true);
        setErrorResponse(response?.data?.message);
        console.log("userErrorResponse", userError);
        // setModal(false);
        setActiveStep(activeStep);
      }
    });
  };
  const onSubmitPos = (data) => {
    if (!checkboxAccepted) {
      setCheckboxError(true);
      return;
    }
    const formData = new FormData();
    formData.append("aadhar_front", images.aadhar_front);
    formData.append("aadhar_back", images.aadhar_back);
    formData.append("marksheet_image", images.marksheet_image);
    formData.append("pan_image", images.pan_image);
    formData.append("passbook_cheque", images.passbook_cheque);
    formData.append("other_doc", images.other_doc);
    formData.append("profile_picture", images.profile_picture);
    formData.append("address", data.address);
    formData.append("aadhar_number", data.aadhar_number);
    formData.append("pan_number", data.pan_number);
    formData.append("accountNumber", data.accountNumber);
    formData.append("ifsc", data.ifsc);
    formData.append("stateId", data.stateId);
    formData.append("districtId", data.districtId);
    formData.append("branchId", data.branchId);

    handleRegisterPos(userData?.id, formData)
      .then((response) => {
        if (response.status === true) {
          setModal(true);
          // navigate("/");
          // sendSuccessMessage(response);
          // setModal(false);
          reset1();
          setUserData(null);
        } else {
          sendErrorMessage(response);
          // setModal(false);
        }
      })
      .catch((error) => console.log(error));
  };

  const removeImage = (imageName) => {
    setImages((prevImages) => ({ ...prevImages, [imageName]: null }));

    const input = document.querySelector(`input[name="${imageName}"]`);
    if (input) {
      input.value = null;
    }
  };

  const handleNextClick = () => {
    const currentTabId = `step${activeStep}`;
    const currentTabPane = document.getElementById(currentTabId);
    if (currentTabPane) {
      currentTabPane.classList.remove("show", "active");
    }

    setActiveStep(activeStep + 1);

    const nextTabId = `step${activeStep + 1}`;
    const nextTabPane = document.getElementById(nextTabId);
    if (nextTabPane) {
      nextTabPane.classList.add("show", "active");
    }
  };
  return (
    <Layout>
      <section className="about-us-section">
        {/*----- Blur set effect -----*/}
        <div className="breadcrumb">
          <h2>Become a POS</h2>
          {/* <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <span>Become a POS</span>
            </li>
          </ul> */}
        </div>

        <div className="posreg mb-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="posheading mt-5">POS Registration</h2>
                <div className="posreg">
                  <ul
                    className="nav nav-tabs formsteps formstepspos"
                    id="myTab1"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          activeStep === 1 ? "active" : ""
                        }`}
                        // onClick={() => handleStepSelection(1)}
                        id="step1-tab"
                        // data-bs-toggle="tab"
                        // data-bs-target="#step1"
                        type=""
                        role=""
                        aria-controls={"step1"}
                        aria-selected="activeStep === 1"
                      >
                        1
                      </button>
                      <span className="posnamemobile">POS Information</span>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${
                          activeStep === 2 ? "active" : ""
                        }`}
                        onClick={() => handleStepSelection(2)}
                        id="step2-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#step2"
                        type=""
                        role=""
                        aria-controls="step2"
                        aria-selected={activeStep === 2}
                      >
                        2
                      </button>
                      <span className="addressposmobile">Address Details</span>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent1">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      {activeStep === 1 && (
                        <div
                          className={`tab-pane fade ${
                            activeStep === 1 ? "show active" : ""
                          }`}
                          id="step1"
                          role="tabpanel"
                          aria-labelledby="step1-tab"
                        >
                          <div className="posformbox">
                            <ul>
                              <div style={{ marginTop: "3.5rem" }}>
                                <li>
                                  <label className="my-1">
                                    First Name
                                    <span
                                      className="text-danger mx-1"
                                      style={{
                                        position: "relative",
                                        top: "0.3rem",
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    name="FirstName"
                                    maxLength={12}
                                    {...register("firstname", {
                                      required: "First Name is required",
                                      maxLength: {
                                        value: 11,
                                        message:
                                          "First Name should not exceed 10 characters.",
                                      },
                                      pattern: {
                                        value: /^[^\d]+$/,
                                        message:
                                          "Name should not contain numeric characters",
                                      },
                                    })}
                                    className="form-control fs-2"
                                    placeholder="First Name"
                                    onInput={(e) => {
                                      (e.target.value = e.target.value.replace(
                                        /[\d\s]/g,
                                        ""
                                      )),
                                        setUserError(false);
                                    }}
                                  />
                                  {errors.firstname && (
                                    <p className="f-error fs-4 mx-2">
                                      {errors?.firstname?.message}
                                    </p>
                                  )}
                                </li>
                              </div>

                              <div style={{ marginTop: "3.5rem" }}>
                                <li>
                                  <label className="my-1">
                                    Last Name
                                    <span
                                      className="text-danger mx-1"
                                      style={{
                                        position: "relative",
                                        top: "0.3rem",
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    name="LastName"
                                    maxLength={21}
                                    {...register("lastname", {
                                      required: "Last Name is required",
                                      maxLength: {
                                        value: 20,
                                        message:
                                          "Last Name should not exceed 20 characters.",
                                      },
                                      pattern: {
                                        value: /^[A-Za-z\s]+$/,
                                        message:
                                          "Name should only contain alphabetic characters and spaces",
                                      },
                                    })}
                                    className="form-control fs-2"
                                    placeholder="Last Name"
                                    onInput={(e) => {
                                      (e.target.value = e.target.value.replace(
                                        /^[\d\s][\w\s]*$/g,
                                        ""
                                      )),
                                        setUserError(false);
                                    }}
                                  />
                                  <p className="f-error fs-4 mx-2">
                                    {errors?.lastname?.message}
                                  </p>
                                </li>
                              </div>

                              <div style={{ marginTop: "3.5rem" }}>
                                <li>
                                  <label className="my-1 mx-1">
                                    Mobile No.
                                    <span
                                      className="text-danger"
                                      style={{
                                        position: "relative",
                                        top: "0.3rem",
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="number"
                                    name="Mobile No."
                                    {...register("phone", {
                                      required: "Mobile Number is required",
                                      pattern: {
                                        value:
                                          /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/,
                                        message: "Invalid Mobile Number",
                                      },
                                    })}
                                    maxLength="10"
                                    className="form-control fs-2"
                                    placeholder="Mobile No."
                                    onInput={(e) => {
                                      e.target.value = e.target.value.slice(
                                        0,
                                        10
                                      );
                                      setUserError(false);
                                    }}
                                  />
                                  {
                                    <p className="f-error fs-4 mx-2">
                                      {errors?.phone?.message}
                                    </p>
                                  }
                                </li>
                              </div>

                              <div style={{ marginTop: "3.5rem" }}>
                                <li>
                                  <label className="my-1">
                                    Email Id
                                    <span
                                      className="text-danger mx-1"
                                      style={{
                                        position: "relative",
                                        top: "0.3rem",
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    name="EmailId"
                                    maxLength={31}
                                    {...register("email", {
                                      required: "Email is required",
                                      maxLength: {
                                        value: 30,
                                        message:
                                          "Email should not exceed 30 characters.",
                                      },
                                      pattern: {
                                        value:
                                          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: "Invalid email address",
                                      },
                                    })}
                                    className="form-control fs-2"
                                    placeholder="Email Id"
                                    onInput={(e) => setUserError(false)}
                                  />
                                  {errors.email && (
                                    <p className="f-error fs-4 mx-2">
                                      {errors?.email?.message}
                                    </p>
                                  )}
                                  {userError && (
                                    <p className="f-error fs-2 mx-2 my-4">
                                      User Already exists
                                    </p>
                                  )}
                                </li>
                              </div>
                            </ul>
                            {/* <div className="note">
                              <strong>Note:</strong> Please enter POS
                              certificate number or employee code for referral.
                              If you are coming without referral, please enter
                              “New”.
                            </div> */}
                          </div>
                          <div className="text-center mt-5">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              // onClick={handleNextClick}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      )}
                    </form>

                    <form onSubmit={handleSubmit1(onSubmitPos)}>
                      {activeStep === 2 && (
                        <div
                          className={`tab-pane fade ${
                            activeStep === 2 ? "show active" : ""
                          }`}
                          id="step2"
                          role="tabpanel"
                          aria-labelledby="step2-tab"
                        >
                          <div className="posformbox mx-auto">
                            <ul className="twoinsuranceform row m-0">
                              <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                                <li className="position-relative mb-1">
                                  <label>Full Name</label>
                                  <input
                                    type="text"
                                    value={userData?.name}
                                    readOnly
                                    name="Fullname"
                                    className="form-control fs-2"
                                  />
                                </li>
                              </div>
                              <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                                <li className="position-relative mb-1">
                                  <label>Mobile Number</label>
                                  <input
                                    type="text"
                                    value={userData?.phone}
                                    readOnly
                                    name="MobileNumber"
                                    className="form-control fs-2 "
                                  />
                                </li>
                              </div>
                              <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                                <li className="position-relative mb-1">
                                  <label>Email</label>
                                  <input
                                    type="text"
                                    value={userData?.email}
                                    readOnly
                                    name="Email"
                                    className="form-control fs-2"
                                  />
                                </li>
                              </div>
                              <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                                <li className="position-relative mb-1">
                                  <label className="my-1">
                                    Aadhar Number
                                    <span
                                      className="text-danger mx-1"
                                      style={{
                                        position: "relative",
                                        top: "0.3rem",
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    name="AadharNumber"
                                    {...register1("aadhar_number", {
                                      required: "Aadhar is required",
                                      pattern: {
                                        value:
                                          /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
                                        message: "Invalid Aadhar Number",
                                      },
                                    })}
                                    maxLength={12}
                                    className="form-control fs-2"
                                    placeholder="Aadhar Number"
                                  />
                                  <span
                                    className="text-danger fs-4 mx-1"
                                    style={{
                                      position: "relative",
                                      bottom: "2rem",
                                    }}
                                  >
                                    {errors1?.aadhar_number?.message}
                                  </span>
                                </li>
                              </div>

                              <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                                <li className="position-relative mb-1">
                                  <label className="my-1">
                                    Pan Number
                                    <span
                                      className="text-danger mx-1"
                                      style={{
                                        position: "relative",
                                        top: "0.3rem",
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    name="PanNumber"
                                    {...register1("pan_number", {
                                      required: "Pan is required",
                                      pattern: {
                                        value: /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/,
                                        message: "Invalid Pan Number",
                                      },
                                    })}
                                    maxLength={10}
                                    className="form-control fs-2 text-uppercase"
                                    placeholder="Pan Number"
                                  />
                                  <span
                                    className="text-danger fs-4 mx-1"
                                    style={{
                                      position: "relative",
                                      bottom: "2rem",
                                    }}
                                  >
                                    {errors1?.pan_number?.message}
                                  </span>
                                </li>
                              </div>

                              <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                                <li className="position-relative mb-1">
                                  <label className="my-1">
                                    Address{" "}
                                    <span
                                      className="text-danger"
                                      style={{
                                        position: "relative",
                                        top: "0.3rem",
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    name="Address"
                                    {...register1("address", {
                                      required: "Address is required",
                                    })}
                                    // style={{ height: "100px" }}
                                    className="form-control fs-2"
                                    placeholder="Address"
                                  />
                                  <span
                                    className="text-danger fs-4 mx-1"
                                    style={{
                                      position: "relative",
                                      bottom: "2rem",
                                    }}
                                  >
                                    {errors1?.address?.message}
                                  </span>
                                </li>
                              </div>

                              <div
                                className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4"
                                style={{ marginBottom: "20px" }}
                              >
                                <li className="position-relative mb-1">
                                  <label className="my-1">
                                    State
                                    <span
                                      className="text-danger mx-1"
                                      style={{
                                        position: "relative",
                                        top: "0.3rem",
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <select
                                    className="form-modal fs-2 text-secondary"
                                    {...register1("stateId", {
                                      required: "State is required",
                                    })}
                                    onChange={(e) => {
                                      getDistricts(e.target.value);
                                      setValue("stateId", e.target.value, {
                                        shouldValidate: true,
                                      });
                                    }}
                                    id="casetype1as"
                                  >
                                    <option
                                      className="d-none"
                                      value=""
                                      defaultValue
                                    >
                                      Select State
                                    </option>
                                    {stateData &&
                                      stateData.length > 0 &&
                                      stateData.map((item, i) => (
                                        <option value={item.id} key={i}>
                                          {item.state}
                                        </option>
                                      ))}
                                  </select>

                                  <span className="text-danger fs-4 mx-1">
                                    {errors1?.stateId?.message}
                                  </span>
                                </li>
                              </div>

                              <div
                                className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4"
                                style={{ marginBottom: "20px" }}
                              >
                                <li className="position-relative mb-1">
                                  <label className="my-1">
                                    District{" "}
                                    <span
                                      className="text-danger"
                                      style={{
                                        position: "relative",
                                        top: "0.3rem",
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <select
                                    className="form-modal fs-2 text-secondary"
                                    {...register1("districtId", {
                                      required: "District is required",
                                    })}
                                    id="casetype1as"
                                    onChange={(e) => {
                                      getBranches(e.target.value);
                                      setValue("districtId", e.target.value, {
                                        shouldValidate: true,
                                      });
                                    }}
                                  >
                                    <option
                                      className="d-none"
                                      value=""
                                      selected
                                    >
                                      Select District
                                    </option>
                                    {districtData &&
                                      districtData.length > 0 &&
                                      districtData.map((item, i) => (
                                        <option value={item.id} key={i}>
                                          {item.district}
                                        </option>
                                      ))}
                                  </select>
                                  {errors1?.districtId && (
                                    <span className="text-danger fs-4 mx-1">
                                      {errors1?.districtId?.message}
                                    </span>
                                  )}
                                </li>
                              </div>

                              <div
                                className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4"
                                style={{ marginBottom: "20px" }}
                              >
                                <li className="position-relative mb-1">
                                  <label className="my-1">
                                    Select City{" "}
                                    <span
                                      className="text-danger"
                                      style={{
                                        position: "relative",
                                        top: "0.3rem",
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <select
                                    className="form-modal fs-2 text-secondary"
                                    {...register1("branchId", {
                                      required: "Branch is required",
                                    })}
                                    id="casetype1as"
                                  >
                                    <option
                                      className="d-none"
                                      value=""
                                      selected
                                    >
                                      Select Branch
                                    </option>
                                    {branchData &&
                                      branchData.length > 0 &&
                                      branchData.map((item, i) => (
                                        <option value={item.id} key={i}>
                                          {item.name}
                                        </option>
                                      ))}
                                  </select>
                                  <span className="text-danger fs-4 mx-1">
                                    {errors1?.branchId?.message}
                                  </span>
                                </li>
                              </div>

                              <div
                                className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4"
                                style={{ marginBottom: "20px" }}
                              >
                                <li className="position-relative mb-1">
                                  <label className="my-1">
                                    Bank Name
                                    <span
                                      className="text-danger mx-1"
                                      style={{
                                        position: "relative",
                                        top: "0.3rem",
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <select
                                    className="form-modal fs-2 text-secondary"
                                    {...register1("bank_name", {
                                      required: "Bank Name is required",
                                    })}
                                    id="casetype1as"
                                  >
                                    <option
                                      className="d-none"
                                      value=""
                                      selected
                                    >
                                      Select Bank Name
                                    </option>
                                    {BankNames.map((item, i) => (
                                      <option value={item} key={i}>
                                        {item}
                                      </option>
                                    ))}
                                  </select>
                                  <span className="text-danger fs-4 mx-1">
                                    {errors1?.bank_name?.message}
                                  </span>
                                </li>
                              </div>

                              <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                                <li className="position-relative mb-1">
                                  <label className="my-1">
                                    Account Number{" "}
                                    <span
                                      className="text-danger"
                                      style={{
                                        position: "relative",
                                        top: "0.3rem",
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    maxLength={19}
                                    {...register1("accountNumber", {
                                      required: "Account Number is required",
                                      pattern: {
                                        value: /^[0-9]{9,18}$/,
                                        message: "Invalid Account Number",
                                      },
                                    })}
                                    className="form-control fs-2"
                                    id="Account1"
                                    placeholder="Account Number"
                                  />
                                  <span
                                    className="text-danger fs-4 mx-1"
                                    style={{
                                      position: "relative",
                                      bottom: "2rem",
                                    }}
                                  >
                                    {errors1?.accountNumber?.message}
                                  </span>
                                </li>
                              </div>

                              <div className="col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4">
                                <li className="position-relative mb-1">
                                  <label className="my-1">
                                    IFSC Code
                                    <span
                                      className="text-danger mx-1"
                                      style={{
                                        position: "relative",
                                        top: "0.3rem",
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    {...register1("ifsc", {
                                      required: "IFSC Code is required",
                                      pattern: {
                                        value: /^[A-Za-z]{4}[0-9]{7}$/,
                                        message: "Invalid IFSC Code",
                                      },
                                    })}
                                    className="form-control fs-2 text-uppercase"
                                    id="ifsc"
                                    placeholder="IFSC Code"
                                    maxLength={11}
                                  />
                                  <span
                                    className="text-danger fs-4 mx-1"
                                    style={{
                                      position: "relative",
                                      bottom: "2rem",
                                    }}
                                  >
                                    {errors1?.ifsc?.message}
                                  </span>
                                </li>
                              </div>

                              <div className="d-flex flex-wrap gap-5">
                                <li>
                                  <div className="file-wrapper pancard">
                                    <span className="docimg">
                                      {images.profile_picture ? (
                                        <>
                                          {images.profile_picture && (
                                            <>
                                              <img
                                                src={URL.createObjectURL(
                                                  images.profile_picture
                                                )}
                                                className="w-100 mt-3 mb-4 docimg"
                                              />
                                              <p
                                                className="remove-btn fs-4"
                                                onClick={() =>
                                                  removeImage("profile_picture")
                                                }
                                              >
                                                x
                                              </p>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <img
                                          src={
                                            "/assetss/images/profilephoto.png"
                                          }
                                          alt="img"
                                          className="img-fluid"
                                        />
                                      )}{" "}
                                      <strong>Profile Picture</strong>
                                    </span>
                                    <input
                                      type="file"
                                      name="profile_picture"
                                      {...register1("profile_picture", {
                                        required: "Photo is requried",
                                        onChange: (e) => handleUploadFile(e),
                                      })}
                                      accept="image/*"
                                    />
                                    <div className="close-btn">×</div>
                                  </div>
                                  <span className="text-danger fs-4 mx-1">
                                    {errors1?.profile_picture?.message}
                                  </span>
                                </li>

                                <li>
                                  <div className="file-wrapper ">
                                    <span className="docimg">
                                      {images.aadhar_front ? (
                                        <>
                                          {images.aadhar_front && (
                                            <>
                                              <img
                                                src={URL.createObjectURL(
                                                  images.aadhar_front
                                                )}
                                                className="w-100 mt-3 mb-4 docimg"
                                              />
                                              <p
                                                className="remove-btn-aadhar fs-4 "
                                                onClick={() =>
                                                  removeImage("aadhar_front")
                                                }
                                              >
                                                x
                                              </p>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <img
                                          src={
                                            "/assetss/images/adharcardimg.png"
                                          }
                                          alt="img"
                                          className="img-fluid"
                                        />
                                      )}{" "}
                                      <strong>
                                        Aadhaar Card <span>( Front )</span>
                                      </strong>
                                    </span>
                                    <input
                                      type="file"
                                      name="aadhar_front"
                                      {...register1("aadhar_front", {
                                        required: "Aadhar Front is required",
                                        onChange: (e) => handleUploadFile(e),
                                      })}
                                      accept="image/*"
                                    />
                                    <div className="close-btn">×</div>
                                  </div>
                                  <span className="text-danger fs-4 mx-1 ">
                                    {errors1?.aadhar_front?.message}
                                  </span>
                                </li>

                                <li>
                                  <div className="file-wrapper">
                                    <span className="docimg">
                                      {images.aadhar_back ? (
                                        <>
                                          {images.aadhar_back && (
                                            <>
                                              <img
                                                src={URL.createObjectURL(
                                                  images.aadhar_back
                                                )}
                                                className="w-100 mt-3 mb-4 docimg"
                                              />
                                              <p
                                                className="remove-btn-aadhar fs-4"
                                                onClick={() =>
                                                  removeImage("aadhar_back")
                                                }
                                              >
                                                x
                                              </p>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <img
                                          src={
                                            "/assetss/images/adharcardimg.png"
                                          }
                                          alt="img"
                                          className="img-fluid"
                                        />
                                      )}{" "}
                                      <strong>
                                        Aadhaar Card <span>( Back )</span>
                                      </strong>
                                    </span>
                                    <input
                                      type="file"
                                      name="aadhar_back"
                                      {...register1("aadhar_back", {
                                        required: "Aadhar Back is required",
                                        onChange: (e) => handleUploadFile(e),
                                      })}
                                      accept="image/*"
                                    />
                                    <div className="close-btn">×</div>
                                  </div>
                                  <span className="text-danger fs-4 mx-1">
                                    {errors1?.aadhar_back?.message}
                                  </span>
                                </li>

                                <li>
                                  <div className="file-wrapper">
                                    <span className="docimg">
                                      {images.marksheet_image ? (
                                        <>
                                          {images.marksheet_image && (
                                            <>
                                              <img
                                                src={URL.createObjectURL(
                                                  images.marksheet_image
                                                )}
                                                className="w-100 mt-3 mb-4 docimg"
                                              />
                                              <p
                                                className="remove-btn fs-4"
                                                onClick={() =>
                                                  removeImage("marksheet_image")
                                                }
                                              >
                                                x
                                              </p>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <img
                                          src={
                                            "/assetss/images/qualification.png"
                                          }
                                          alt="img"
                                          className="img-fluid"
                                        />
                                      )}{" "}
                                      <strong>Qualification</strong>
                                    </span>
                                    <input
                                      type="file"
                                      {...register1("marksheet_image", {
                                        required: "Marksheet is required",
                                        onChange: (e) => handleUploadFile(e),
                                      })}
                                      name="marksheet_image"
                                      accept="image/*"
                                    />
                                    <div className="close-btn">×</div>
                                  </div>
                                  <span className="text-danger fs-4 mx-1">
                                    {errors1?.marksheet_image?.message}
                                  </span>
                                </li>

                                <li>
                                  <div className="file-wrapper">
                                    <span className="docimg">
                                      {images.passbook_cheque ? (
                                        <>
                                          {images.passbook_cheque && (
                                            <>
                                              <img
                                                src={URL.createObjectURL(
                                                  images.passbook_cheque
                                                )}
                                                className="w-100 mt-3 mb-4 docimg"
                                              />
                                              <p
                                                className="remove-btn fs-4"
                                                onClick={() =>
                                                  removeImage("passbook_cheque")
                                                }
                                              >
                                                x
                                              </p>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <img
                                          src={"/assetss/images/cheque.png"}
                                          alt="img"
                                          className="img-fluid"
                                        />
                                      )}{" "}
                                      <strong>Cheque</strong>
                                    </span>
                                    <input
                                      type="file"
                                      name="passbook-cheque"
                                      {...register1("passbook_cheque", {
                                        required: "Cheque Image is required",
                                        onChange: (e) => handleUploadFile(e),
                                      })}
                                      accept="image/*"
                                    />
                                    <div className="close-btn">×</div>
                                  </div>
                                  <span className="text-danger fs-4 mx-1 ">
                                    {errors1?.passbook_cheque?.message}
                                  </span>
                                </li>

                                <li>
                                  <div className="file-wrapper">
                                    <span className="docimg">
                                      {images.pan_image ? (
                                        <>
                                          {images.pan_image && (
                                            <>
                                              <img
                                                src={URL.createObjectURL(
                                                  images.pan_image
                                                )}
                                                className="w-100 mt-3 mb-4 docimg"
                                              />
                                              <p
                                                className="remove-btn fs-4"
                                                onClick={() =>
                                                  removeImage("pan_image")
                                                }
                                              >
                                                x
                                              </p>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <img
                                          src={
                                            "/assetss/images/pancardicon.png"
                                          }
                                          alt="img"
                                          className="img-fluid"
                                        />
                                      )}{" "}
                                      <strong>Pan Card</strong>
                                    </span>
                                    <input
                                      type="file"
                                      name="pan-img"
                                      {...register1("pan_image", {
                                        required: "Pan Card is required",
                                        onChange: (e) => handleUploadFile(e),
                                      })}
                                      accept="image/*"
                                    />
                                    <div className="close-btn">×</div>
                                  </div>
                                  <span className="text-danger fs-4 mx-1">
                                    {errors1?.pan_image?.message}
                                  </span>
                                </li>
                              </div>
                            </ul>
                            {checkboxError && (
                              <p
                                className="f-error fs-3  mx-2"
                                style={{ position: "relative" }}
                              >
                                You must select the checkbox to proceed
                              </p>
                            )}
                            <div className="declare">
                              <label className="declarebox fs-2">
                                I hereby declare that the above information is
                                true and correct.
                                <input
                                  type="checkbox"
                                  checked={checkboxAccepted}
                                  onChange={() => {
                                    setCheckboxAccepted(!checkboxAccepted);
                                    setCheckboxError(false);
                                  }}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </div>

                          <div className="text-center mt-5 mx-2">
                            <button type="submit" className="btn btn-primary">
                              Become a POS
                            </button>
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ----- container start ----- */}
        <div className="container-lg container-fluid">
          <div className="row justify-content-between align-items-center">
            {/* <div className="col-lg-8 col-12 text-center mx-auto my-5 py-5">
              <h1 className="text-capitalize about-heading mx-auto">
                Become a Pos with Expert Cover
              </h1>
              <p className="my-5 fs-3">
                A motor insurance agent is someone who works with an insurance
                company to sell specific motor insurance products. If you were
                to be a Motor Agent or POSP, you would help customers choose the
                right motor insurance policy as per their requirements from all
                motor vehicle-related insurance plans. At Expert Cover, Point Of
                Sales Person (POSp) gives self-sustainability for an individual
                by allowing him to design own source of income and live
                independently being an agent.
                <br />
                <span className="text-primary fw-bold fs-2">
                  || Be a POS today ||
                </span>
              </p>
            </div> */}
            <div className="col-12" />
            {/*----- About us image -----*/}
            {/* <div className="col-md-5 col-lg-6 col-12">
              <img
                src="assets/images/posbroker.svg"
                alt=""
                className="w-100 me-auto d-block"
              />
            </div> */}

            {/* <div className="posreg">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="posheading">POS Registration</h2>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="col-md-6 col-12">
              <h1 className="mb-4 mt-lg-0 mt-4 fs-1">
                Complete The Registration
              </h1>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mx-0 px-0">
                  <div className="col-sm-6 ps-lg-0 mb-4">
                    <div className="form-floating pb-4">
                      <input
                        type="text"
                        {...register("firstname", {
                          required: "First name is required",
                        })}
                        className="form-control"
                        id="lastname"
                        placeholder="Last Name"
                      />
                      <label htmlFor="lastname">First Name</label>

                      <p className="f-error">{errors?.firstname?.message}</p>
                    </div>
                  </div>

                  <div className="col-sm-6 pe-lg-0 mb-4">
                    <div className="form-floating pb-4">
                      <input
                        type="text"
                        {...register("lastname", {
                          required: "Last name is required",
                        })}
                        className="form-control"
                        id="lastname"
                        placeholder="Last Name"
                      />

                      <label htmlFor="lastname">Last Name</label>

                      <p className="f-error">{errors?.lastname?.message}</p>
                    </div>
                  </div>

                  <div className="col-lg-12 px-lg-0 mb-4">
                    <div className="form-floating pb-4">
                      <input
                        type="email"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Invalid email address",
                          },
                        })}
                        className="form-control"
                        id="email"
                        placeholder="email"
                      />
                      <label htmlFor="email">Enter Email</label>

                      <p className="f-error">{errors?.email?.message}</p>
                    </div>
                  </div>
                  <div className="col-lg-12 px-lg-0 mb-4">
                    <div className="form-floating pb-4">
                      <input
                        type="text"
                        {...register("phone", {
                          required: "Contact Number is required",
                          pattern: {
                            value:
                              /((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/,
                            message: "Invalid Mobile Number",
                          },
                        })}
                        className="form-control"
                        id="mobnum"
                        placeholder="Mobile Number"
                        maxLength="10"
                      />
                      <label htmlFor="mobnum">Mobile Number</label>

                      <p className="f-error">{errors?.phone?.message}</p>
                    </div>
                  </div>

                  <div className="col-sm-5 col-12 ps-lg-0 mt-3">
                    <button
                      type="submit"
                      className="btn btn-outline-primary w-100 py-3 fs-3"
                    >
                      Register Now
                    </button>
                  </div>
                  <div className="col-lg-8 col-sm-5 col-12 pe-0 align-self-end mt-3">
                    <Link
                      to="/login"
                      className="fs-3 fw-bold text-decoration-underline"
                    >
                      Already Have An Account?
                    </Link>
                  </div>
                </div>
              </form>
            </div> */}
          </div>
        </div>
        {/*----- container End -----*/}
      </section>
      {/* <Modal scrollable size="lg" isOpen={modal}>
        <ModalHeader>
          {" "}
          <h3 className="fs-1 px-5 py-3">Expert Cover PosP Registration</h3>
        </ModalHeader>

        <ModalBody>
          <form onSubmit={handleSubmit1(onSubmitPos)}>
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-md-12 col-12">
                  <div className="row mx-0 px-0">
                    <div className="col-lg-6 ps-lg-0 mb-4">
                      <div className="form-floating">
                        <input
                          type="text"
                          value={userData?.name}
                          readOnly
                          className="form-control"
                          id="fullname"
                          placeholder="Full Name"
                        />
                        <label htmlFor="fullname">Full Name</label>
                      </div>
                    </div>
                    <div className="col-lg-6 pe-lg-0 mb-4">
                      <div className="form-floating">
                        <input
                          type="text"
                          value={userData?.phone}
                          className="form-control"
                          id="mobnum1"
                          placeholder="Mobile Number"
                        />
                        <label htmlFor="mobnum1">Mobile Number</label>
                      </div>
                    </div>
                    <div className="col-lg-6  ps-lg-0 mb-4">
                      <div className="form-floating">
                        <input
                          type="email"
                          value={userData?.email}
                          className="form-control"
                          id="email1"
                          placeholder="email"
                        />
                        <label htmlFor="email1">Enter Email</label>
                      </div>
                    </div>
                    <div className="col-lg-6 pe-lg-0 mb-4">
                      <div className="form-floating">
                        <input
                          type="text"
                          {...register1("aadhar_number", {
                            required: "Aadhar is required",
                            pattern: {
                              value: /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
                              message: "Invalid Aadhar Number",
                            },
                          })}
                          className="form-control"
                          id="aanum"
                          placeholder="Aadhar Number"
                        />
                        <label htmlFor="aanum">Aadhar Number</label>
                      </div>
                      <span className="text-danger">
                        {errors1?.aadhar_number?.message}
                      </span>
                    </div>
                    <div className="col-lg-6 ps-lg-0 mb-4">
                      <div className="form-floating">
                        <input
                          type="text"
                          {...register1("pan_number", {
                            required: "Pan is required",
                            // pattern:{
                            //   value:/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                            //   message:"Invalid Pan Number"
                            // }
                          })}
                          className="form-control"
                          id="pannum"
                          placeholder="PAN Number"
                        />
                        <label htmlFor="pannum">PAN Number</label>
                      </div>
                      <span className="text-danger">
                        {errors1?.pan_number?.message}
                      </span>
                    </div>
                    <div className="col-lg-12 px-lg-0 mb-4">
                      <div className="form-floating">
                        <textarea
                          className="form-control"
                          placeholder="Address"
                          id="Addreaa1"
                          {...register1("address", {
                            required: "Address is required",
                          })}
                          style={{ height: "100px" }}
                        />
                        <label htmlFor="Addreaa1">Address</label>
                      </div>
                      <span className="text-danger">
                        {errors1?.address?.message}
                      </span>
                    </div>
                    <div className="col-lg-6 ps-lg-0 mb-4">
                      <div className="position-relative">
                        <div className="form-floating">
                          <select
                            className="form-select"
                            {...register1("stateId", {
                              required: "State is required",
                            })}
                            onChange={(e) => getDistricts(e.target.value)}
                            id="casetype1as"
                          >
                            <option className="d-none" value="" selected>
                              Select State
                            </option>
                            {stateData &&
                              stateData.length > 0 &&
                              stateData.map((item, i) => (
                                <option value={item.id} key={i}>
                                  {item.state}
                                </option>
                              ))}
                          </select>
                          <label htmlFor="casetype1as">
                            State
                            <span className="text-danger">*</span>
                          </label>
                        </div>
                      </div>
                      <span className="text-danger">
                        {errors1?.stateId?.message}
                      </span>
                    </div>
                    <div className="col-lg-6 ps-lg-0 mb-4">
                      <div className="position-relative">
                        <div className="form-floating">
                          <select
                            className="form-select"
                            {...register1("districtId", {
                              required: "District is required",
                            })}
                            id="casetype1as"
                            onChange={(e) => getBranches(e.target.value)}
                          >
                            <option className="d-none" value="" selected>
                              Select District
                            </option>
                            {districtData &&
                              districtData.length > 0 &&
                              districtData.map((item, i) => (
                                <option value={item.id} key={i}>
                                  {item.district}
                                </option>
                              ))}
                          </select>
                          <label htmlFor="casetype1as">
                            District
                            <span className="text-danger">*</span>
                          </label>
                        </div>
                      </div>
                      <span className="text-danger">
                        {errors1?.districtId?.message}
                      </span>
                    </div>
                    <div className="col-lg-6 ps-lg-0 mb-4">
                      <div className="position-relative">
                        <div className="form-floating">
                          <select
                            className="form-select"
                            {...register1("branchId", {
                              required: "Branch is required",
                            })}
                            id="casetype1as"
                          >
                            <option className="d-none" value="" selected>
                              Select Branch
                            </option>
                            {branchData &&
                              branchData.length > 0 &&
                              branchData.map((item, i) => (
                                <option value={item.id} key={i}>
                                  {item.name}
                                </option>
                              ))}
                          </select>
                          <label htmlFor="casetype1as">
                            Branch Name
                            <span className="text-danger">*</span>
                          </label>
                        </div>
                      </div>
                      <span className="text-danger">
                        {errors1?.branchId?.message}
                      </span>
                    </div>
                    <div className="col-lg-6 ps-lg-0 mb-4">
                      <div className="position-relative">
                        <div className="form-floating">
                          <select
                            className="form-select"
                            {...register1("bank_name", {
                              required: "Bank Name is required",
                            })}
                            id="casetype1as"
                          >
                            <option className="d-none" value="" selected>
                              Select Bank Name
                            </option>
                            {BankNames.map((item, i) => (
                              <option value={item} key={i}>
                                {item}
                              </option>
                            ))}
                          </select>
                          <label htmlFor="casetype1as">
                            Bank Name
                            <span className="text-danger">*</span>
                          </label>
                        </div>
                      </div>
                      <span className="text-danger">
                        {errors1?.bank_name?.message}
                      </span>
                    </div>
                    <div className="col-lg-6 ps-lg-0 mb-4">
                      <div className="form-floating">
                        <input
                          type="text"
                          {...register1("accountNumber", {
                            required: "Account Number is required",
                            pattern: {
                              value: /^[0-9]{9,18}$/,
                              message: "Invalid Account Number",
                            },
                          })}
                          className="form-control"
                          id="Account1"
                          placeholder="Account Number"
                        />
                        <label htmlFor="Account1">Account Number</label>
                      </div>
                      <span className="text-danger">
                        {errors1?.accountNumber?.message}
                      </span>
                    </div>
                    <div className="col-lg-6 ps-lg-0 mb-4">
                      <div className="form-floating">
                        <input
                          type="text"
                          {...register1("ifsc", {
                            required: "IFSC Code is required",
                            // pattern:{
                            //   value:/^[A-Z]{4}[0-9]{7}$/,
                            //   message:"Invalid IFSC Code"
                            // }
                          })}
                          className="form-control"
                          id="ifsc"
                          placeholder="IFSC Code"
                        />
                        <label htmlFor="ifsc">IFSC Code</label>
                      </div>
                      <span className="text-danger">
                        {errors1?.ifsc?.message}
                      </span>
                    </div>
                    <div className="col-12 px-lg-0 my-3">
                      <h2 className="text-decoration-underline">
                        Add Legal Documents :-
                      </h2>
                    </div>
                    <div className="col-lg-4 col-12 ps-lg-0">
                      <input
                        type="file"
                        name="profile_picture"
                        {...register1("profile_picture", {
                          required: "Passport size phoy is requried",
                          onChange: (e) => handleUploadFile(e),
                        })}
                        className="d-none"
                        id="profile_picture"
                      />
                      <label
                        htmlFor="profile_picture"
                        className="btn btn-primary w-100 fs-3 py-3 mb-3"
                      >
                        Passport Size Photo
                      </label>
                      {images.profile_picture && (
                        <img
                          src={URL.createObjectURL(images.profile_picture)}
                          className="w-100 mt-3 mb-4 docimg"
                        />
                      )}
                      <span className="text-danger">
                        {errors1?.profile_picture?.message}
                      </span>
                    </div>
                    <div className="col-lg-4 col-12">
                      <input
                        type="file"
                        name="aadhar_front"
                        {...register1("aadhar_front", {
                          required: "Aadhar Front is required",
                          onChange: (e) => handleUploadFile(e),
                        })}
                        className="d-none"
                        id="aadharfront"
                      />
                      <label
                        htmlFor="aadharfront"
                        className="btn btn-primary w-100 fs-3 py-3 mb-3"
                      >
                        Aadhar Front
                      </label>
                      {images.aadhar_front && (
                        <img
                          src={URL.createObjectURL(images.aadhar_front)}
                          className="w-100 mt-3 mb-4 docimg"
                        />
                      )}
                      <span className="text-danger">
                        {errors1?.aadhar_front?.message}
                      </span>
                    </div>
                    <div className="col-lg-4 col-12 pe-lg-0">
                      <input
                        type="file"
                        name="aadhar_back"
                        {...register1("aadhar_back", {
                          required: "Aadhar Back is required",
                          onChange: (e) => handleUploadFile(e),
                        })}
                        className="d-none"
                        id="aadharback"
                      />
                      <label
                        htmlFor="aadharback"
                        className="btn btn-primary w-100 fs-3 py-3 mb-3"
                      >
                        Aadhar Back
                      </label>
                      {images.aadhar_back && (
                        <img
                          src={URL.createObjectURL(images.aadhar_back)}
                          className="w-100 mt-3 mb-4 docimg"
                        />
                      )}
                      <span className="text-danger">
                        {errors1?.aadhar_back?.message}
                      </span>
                    </div>
                    <div className="col-lg-4 col-12 ps-lg-0">
                      <input
                        type="file"
                        name="pan_image"
                        {...register1("pan_image", {
                          required: "Pan Card is required",
                          onChange: (e) => handleUploadFile(e),
                        })}
                        className="d-none"
                        id="pancardimg"
                      />
                      <label
                        htmlFor="pancardimg"
                        className="btn btn-primary w-100 fs-3 py-3 mb-3"
                      >
                        PAN Card
                      </label>
                      {images.pan_image && (
                        <img
                          src={URL.createObjectURL(images.pan_image)}
                          className="w-100 mt-3 mb-4 docimg"
                        />
                      )}
                      <span className="text-danger">
                        {errors1?.pan_image?.message}
                      </span>
                    </div>
                    <div className="col-lg-4 col-12 ">
                      <input
                        name="marksheet_image"
                        {...register1("marksheet_image", {
                          required: "Marksheet is required",
                          onChange: (e) => handleUploadFile(e),
                        })}
                        type="file"
                        className="d-none"
                        id="marksheet"
                      />
                      <label
                        htmlFor="marksheet"
                        className="btn btn-primary w-100 fs-3 py-3 mb-3"
                      >
                        10th Marksheet
                      </label>
                      {images.marksheet_image && (
                        <img
                          src={URL.createObjectURL(images.marksheet_image)}
                          className="w-100 mt-3 mb-4 docimg"
                        />
                      )}
                      <span className="text-danger">
                        {errors1?.marksheet_image?.message}
                      </span>
                    </div>
                    <div className="col-lg-4 col-12 pe-lg-0">
                      <input
                        type="file"
                        name="passbook_cheque"
                        {...register1("passbook_cheque", {
                          required: "Passbook/Cheque Image is required",
                          onChange: (e) => handleUploadFile(e),
                        })}
                        className="d-none"
                        id="pcimg"
                      />
                      <label
                        htmlFor="pcimg"
                        className="btn btn-primary w-100 fs-3 py-3 mb-3"
                      >
                        Passbook/Cheque
                      </label>
                      {images.passbook_cheque && (
                        <img
                          src={URL.createObjectURL(images.passbook_cheque)}
                          className="w-100 mt-3 mb-4 docimg"
                        />
                      )}
                      <span className="text-danger">
                        {errors1?.passbook_cheque?.message}
                      </span>
                    </div>
                    <div className="col-lg-4 col-12  ps-lg-0">
                      <input
                        type="file"
                        name="other_doc"
                        {...register1("other_doc", {
                          required: false,
                          onChange: (e) => handleUploadFile(e),
                        })}
                        className="d-none"
                        id="pcimg1"
                      />
                      <label
                        htmlFor="pcimg1"
                        className="btn btn-primary w-100 fs-3 py-3 mb-3"
                      >
                        Other Documents
                      </label>
                      {images.other_doc && (
                        <img
                          src={URL.createObjectURL(images.other_doc)}
                          className="w-100 mt-3 mb-4 docimg"
                        />
                      )}
                      <span className="text-danger">
                        {errors1?.other_doc?.message}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="formsubmitdiv text-center my-4">
                <button
                  type="submit"
                  className="btn btn-primary fs-2 px-5 py-3"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal> */}

      {/* Success Modal */}

      <Modal
        isOpen={modal}
        toggle={toggleModal}
        size="md"
        className="successModal"
        onMouseDown={(e) => e.stopPropagation()}
      >
        <ModalBody>
          <div className="cardModal">
            <div className="checkMarkWrapper">
              <i className="success-checkmark">✓</i>
            </div>
            <div className="success-container">
              <h1 className="success-header">Success</h1>
              <p className="fs-3 text-center">
                Thankyou for Registering with us. We will notify you through
                Mail if your request is Approved or Rejected
              </p>
              <Link className="" to="/">
                <button
                  className="homeButton"
                  id="modalBtn"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    window.location.href = "/";
                  }}
                >
                  Go to Home
                </button>
              </Link>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Layout>
  );
};

export default BecomePos;
