import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import Footer from '../../common/Footer'
import Header from '../../common/Header'
import Layout from '../../common/Layout'
import { handleRegisterPos, sendErrorInfo, sendErrorMessage, sendSuccessMessage } from '../../Services/PosService'

const BecomePos = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { register, formState: { errors }, handleSubmit } = useForm({ mode: "onBlur" })
  const [userData, setUserData] = useState(null)
  const [images, setImages] = React.useState({
    aadhar_front: null,
    aadhar_back: null,
    marksheet_image: null,
    pan_image: null,
    passbook_cheque: null,
  });
  useEffect(() => {
    setUserData(location.state.posData)
  }, [])
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('aadhar_front', images.aadhar_front)
    formData.append('aadhar_back', images.aadhar_back)
    formData.append('marksheet_image', images.marksheet_image)
    formData.append('pan_image', images.pan_image)
    formData.append('passbook_cheque', images.passbook_cheque)
    formData.append('address', data.address)
    formData.append("aadhar_number", data.aadhar_number)
    formData.append("pan_number", data.pan_number)
    formData.append("accountNumber", data.accountNumber)
    formData.append("ifsc", data.ifsc);

    handleRegisterPos(userData?.id, formData)
      .then(response => {
        if (response.status === true) {
          navigate("/")
          sendSuccessMessage(response)
        } else {
          sendErrorMessage(response)
        }
      })
      .catch(error => console.log(error))
  }

  const handleUploadFile = (e) => {
    //check if the file is an image
    if (e.target.files[0].type.indexOf('image') < 0) {
      return sendErrorInfo('Please upload an image file')
    }
    setImages({
      ...images,
      [e.target.name]: e.target.files[0]
    })
  }
  return (
    <Layout>
      <div className="main-content-area">
        {/*---- Section Tile Container End ----*/}
        <section className="page-title layer-overlay overlay-dark-9 section-typo-light bg-img-center" data-tm-bg-img="images/bg/bg1.jpg">
          <div className="container pt-50 pb-50">
            <div className="section-content">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h2 className="title">Policy Expert Register Form</h2>
                  <nav className="breadcrumbs" role="navigation" aria-label="Breadcrumbs">
                    <div className="breadcrumbs">
                      <span><a href="#" rel="home">Home</a></span>
                      <span className="mx-1"><i className="fa fa-angle-right" /></span>
                      <span className="active">Registration Form</span>
                    </div>
                  </nav>
                  {/*---- Breadcrumbs End ----*/}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*---- Section Tile Container End ----*/}
        {/*----- Login Contaienr Start -----*/}
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                {/*----- Registration Form Start -----*/}
                <form name="reg-form" className="register-form" onSubmit={handleSubmit(onSubmit)} >
                  <div className="icon-box mb-0 p-0 clearfix">
                    <a href="#" className="icon icon-bordered icon-rounded icon-sm float-left mt-10 mb-0 mr-10 align-middle">
                      <i className="pe-7s-users" />
                    </a>
                    <h4 className="text-gray pt-10 align-middle">Don't have an Account? Register Now.</h4>
                  </div>
                  <hr />
                  <p className="text-success fw-bold">
                    Please! Complete the form to complete the Registration process
                  </p>
                  <div className="row">
                    <div className="mb-3 col-md-6">
                      <label className="name">Name</label>
                      <input id="name" value={userData?.name} readOnly className="form-control" type="text" />
                      <span className="text-danger">{errors?.name?.message}</span>
                    </div>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="email">Email Address</label>
                      <input id="email" value={userData?.email} readOnly className="form-control" type="email" />
                      <span className="text-danger">{errors?.email?.message}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-12">
                      <label htmlFor="contact">Contact Number</label>
                      <input value={userData?.phone} readOnly className="form-control" id="contact" type="number" />
                      <span className="text-danger">{errors?.phone?.message}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-12">
                      <label htmlFor="aadhar">Aadhar Number </label>
                      <input id="aadhar" {
                        ...register('aadhar_number', {
                          required: "Aadhar is required",
                          // pattern:{
                          //   value:/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
                          //   message:"Invalid Aadhar Number"
                          // }
                        })
                      } className="form-control" type="number" />
                      <span className="text-danger">{errors?.aadhar_number?.message}</span>
                    </div>
                    <div className="mb-3 col-md-12">
                      <label htmlFor="pan">Pan Card Number </label>
                      <input id="pan" {
                        ...register('pan_number', {
                          required: "Pan is required",
                          // pattern:{
                          //   value:/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                          //   message:"Invalid Pan Number"
                          // }
                        })
                      } className="form-control" type="text" />
                      <span className="text-danger">{errors?.pan_number?.message}</span>
                    </div>
                    <div className="mb-3 col-md-4 mt-3">
                      <label htmlFor="imagefront" className="btn btn-theme-colored1 btn-xs w-100">
                        Aadhar Front Image
                      </label>
                      <input id="imagefront" name="aadhar_front" {
                        ...register('aadhar_front', {
                          required: "Aadhar Front is required",
                          onChange: (e) => handleUploadFile(e)
                        })
                      } className="form-control d-none" type="file" />
                      <span className="text-danger">{errors?.aadhar_front?.message}</span>
                      {images.aadhar_front && <img src={URL.createObjectURL(images.aadhar_front)} className="regimg" />}
                    </div>
                    <div className="mb-3 col-md-4  mt-3">
                      <label htmlFor="imageback" className="btn btn-theme-colored1 btn-xs w-100">
                        Aadhar Back Image
                      </label>
                      <input id="imageback" name="aadhar_back" {
                        ...register('aadhar_back', {
                          required: "Aadhar Back is required",
                          onChange: (e) => handleUploadFile(e)
                        })
                      } className="form-control d-none" type="file" />
                      <span className="text-danger">{errors?.aadhar_back?.message}</span>
                      {images.aadhar_back && <img src={URL.createObjectURL(images.aadhar_back)} className="regimg" />}
                    </div>
                    <div className="mb-3 col-md-4 text-end mt-3">
                      <label htmlFor="pancard" className="btn btn-theme-colored1 btn-xs w-100">
                        Pan Card Image
                      </label>
                      <input id="pancard" name="pan_image" {
                        ...register('pan_image', {
                          required: "Pan Card is required",
                          onChange: (e) => handleUploadFile(e)
                        })
                      } className="form-control d-none" type="file" />
                      <span className="text-danger">{errors?.pan_image?.message}</span>
                      {images.pan_image && <img src={URL.createObjectURL(images.pan_image)} className="regimg" />}
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-12">
                      <label htmlFor="regaddress">Address</label>
                      <textarea {
                        ...register('address', {
                          required: "Address is required",
                        })
                      } className="form-control" id="regaddress" rows={1} defaultValue={""} />
                      <span className="text-danger">{errors?.address?.message}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-6">
                      <label htmlFor="acc">Account Number</label>
                      <input id="acc" {
                        ...register('accountNumber', {
                          required: "Account Number is required",
                          pattern: {
                            value: /^[0-9]{9,18}$/,
                            message: "Invalid Account Number"
                          }
                        })
                      } className="form-control" type="number" />
                      <span className="text-danger">{errors?.accountNumber?.message}</span>
                    </div>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="ifsc">IFSC Code</label>
                      <input id="ifsc" {
                        ...register('ifsc', {
                          required: "IFSC Code is required",
                          // pattern:{
                          //   value:/^[A-Z]{4}[0-9]{7}$/,
                          //   message:"Invalid IFSC Code"
                          // }
                        })
                      } className="form-control" type="text" />
                      <span className="text-danger">{errors?.ifsc?.message}</span>
                    </div>
                  </div>
                  <div className="row justify-content-between">
                    <div className="mb-3 col-md-5  mt-3">
                      <label htmlFor="10th" className="btn btn-theme-colored1 btn-xs w-100">
                        10th Marksheet attachment
                      </label>
                      <input id="10th" name="marksheet_image" {
                        ...register('marksheet_image', {
                          required: "Marksheet is required",
                          onChange: (e) => handleUploadFile(e)
                        })
                      } className="form-control d-none" type="file" />
                      <span className="text-danger">{errors?.marksheet_image?.message}</span>
                      {images.marksheet_image && <img src={URL.createObjectURL(images.marksheet_image)} className="regimg" />}
                    </div>
                    <div className="mb-3 col-md-5  mt-3">
                      <label htmlFor="chkimg" className="btn btn-theme-colored1 btn-xs w-100">
                        Passbook/Cheque Image
                      </label>
                      <input id="chkimg" name="passbook_cheque" {
                        ...register('passbook_cheque', {
                          required: "Passbook/Cheque Image is required",
                          onChange: (e) => handleUploadFile(e)
                        })
                      } className="form-control d-none" type="file" />
                      <span className="text-danger">{errors?.passbook_cheque?.message}</span>
                      {images.passbook_cheque && <img src={URL.createObjectURL(images.passbook_cheque)} className="regimg" />}
                    </div>
                  </div>
                  {/*---- submit Button ----*/}
                  <div className="row align-items-end mt-5">
                    <div className="col-lg-6">
                      <div className="tm-sc-button">
                        <button type="submit" className="btn btn-dark d-grid mt-15">Register
                          Now</button>
                      </div>
                    </div>
                  </div>
                </form>
                {/*----- Registration Form End -----*/}
              </div>
            </div>
          </div>
        </section>
        {/*----- Login Contaienr End -----*/}
      </div >
    </Layout>

  )
}

export default BecomePos