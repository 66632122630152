import React from "react";
import Header from "../Header";
import Footer from "../Footer";

function TermReturn() {
  return (
    <>
      <Header />
      <div>
        <div className="insurance-cantainer">
          <h1>Term Return Of Premium</h1>
        </div>
        <div className="cantainer">
          <div className="box-info">
            <div className="box-1">
              <h2>
                "Understanding Term Life Insurance Premiums: Factors and
                Considerations"
              </h2>
              <p>
                Term life insurance premiums refer to the cost an individual
                pays to maintain coverage over a specified term, typically
                ranging from 10 to 30 years. The premium is determined based on
                several factors, including the insured individual's age, health,
                lifestyle, coverage amount, and the term length selected. Age
                plays a significant role in determining the premium, with
                younger individuals often paying lower premiums. Health and
                lifestyle choices, such as smoking or engaging in high-risk
                activities, can increase premiums due to the associated health
                risks. The coverage amount also affects the premium, with higher
                coverage requiring higher payments.
              </p>
              <p>
                Additionally, the chosen term length impacts the premium. Longer
                terms generally result in higher premiums due to the increased
                risk of the insured passing away during the policy's duration.
                To calculate the premium, insurance companies use actuarial
                tables and risk assessment models to estimate the likelihood of
                a claim being made during the term. It's crucial for individuals
                to carefully consider their coverage needs and budget to select
                an appropriate term and coverage amount that aligns with their
                financial goals and circumstances while ensuring they can
                comfortably afford the premiums. Comparing quotes from different
                insurers can also help in finding the most suitable and
                cost-effective term life insurance policy.
              </p>
            </div>
            <div className="box-2 premium-insurance">
              <img src="/assetss/images/premium-insurance.jpg" alt="Erroe" />
            </div>
          </div>
        </div>
        <div className="vision-info">
          <div className="vision-box">
            <div className="img-box">
              <img src="./assetss/images/vision.png" alt="Error" />
            </div>
            <div className="vision-box1">
              <h2>Our vision</h2>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend to repeat predefined chunks as necessary,
              </p>
            </div>
          </div>
        </div>
        <div className="btn-cantainer">
          <div className="btn-1">
            <h1>Solving your Insurance Needs on Time</h1>
          </div>
          <div className="btn-1">
            <h2>
              Get world-class insurance coverage that not only protects you but
              also helps you build wealth. Let us be your insurance partner.
            </h2>
            <div className="btn-info">
              <button>Get Started</button>
              <button>Contact Us</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TermReturn;
