import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { PostDataWithToken } from "../../api/apiHelper";
import Footer from "../common/Footer";
import Header from "../common/Header";
import Layout from "../common/Layout";
import {
  handleChangePassword,
  sendErrorMessage,
  sendSuccessMessage,
} from "../Services/PosService";

const ChangePassword = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm({ mode: "onBlur" });

  const password = useRef({});
  password.current = watch("password", "");
  const navigate = useNavigate();
  const onSubmit = (data) => {
    let postData = {
      password: data.password,
      oldPassword: data.oldPassword,
    };
    handleChangePassword(postData)
      .then((response) => {
        if (response.status === true) {
          sendSuccessMessage(response);
          navigate("/");
        } else {
          sendErrorMessage(response);
        }
      })
      .catch((err) => {
        sendErrorMessage(err);
      });
  };
  return (
    <Layout>
      {/*----- Change password Section start -----*/}
      <section className="about-us-section">
        {/*----- Blur set effect -----*/}
        <div className="blur-bg-blocks">
          <aside className="blur-bg-set">
            <div className="blur-bg blur-bg-a" />
            <div className="blur-bg blur-bg-b" />
            <div className="blur-bg blur-bg-c" />
          </aside>
        </div>

        {/*----- container start -----*/}
        <div className="container-lg container-fluid">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-8 col-12 text-center mx-auto mt-5 pt-5 pb-lg-5 mb-lg-5">
              <h1 className="text-capitalize about-heading mx-auto">
                Change PosP Credentials
              </h1>

              <p className="my-5 fs-2">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit
                voluptates quisquam nulla reiciendis illum placeat dolorem sed.
                Corporis officiis explicabo non nulla, fuga doloremque magni
                cumque, ratione iste amet voluptatum. Lorem ipsum dolor sit
                amet.
              </p>
            </div>

            <div className="col-12" />

            {/*----- About us image -----*/}
            <div className="col-md-5">
              <img
                src="./assets/images/aboutus.png"
                alt=""
                className="w-100 me-auto d-block"
              />
            </div>

            <div className="col-md-6">
              {/*---- Change password form start ----*/}
              <h1 className="mb-4 mt-lg-0 mt-4 fs-1">Changed Your Password</h1>

              <form name="login-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="row mx-0 px-0">
                  <div className="col-12 px-lg-0 mb-4">
                    <div className="form-floating pb-4">
                      <input
                        id="form_username_email32"
                        name="password"
                        {...register("oldPassword", {
                          required: "Old Password is required",
                        })}
                        className="form-control fs-2"
                        type="password"
                      />

                      <label htmlFor="form_username_email32">
                        Old Password
                      </label>

                      <p className="f-error fs-4">{errors?.email?.message}</p>
                    </div>
                  </div>

                  <div className="col-12 px-lg-0 mb-4">
                    <div className="form-floating pb-4">
                      <input
                        type="password"
                        id="form_password1"
                        name="password"
                        {...register("password", {
                          required: "Password is required",
                        })}
                        className="form-control fs-2"
                      />

                      <label htmlFor="form_password1">Password</label>

                      <p className="f-error fs-4">
                        {errors?.password?.message}
                      </p>
                    </div>
                  </div>

                  <div className="col-12 px-lg-0 mb-4">
                    <div className="form-floating pb-4">
                      <input
                        id="form_passwordfd"
                        name="confirmPassword"
                        {...register("confirmPassword", {
                          required: "Confirm Password is required",
                          validate: (value) => {
                            return (
                              value === password.current ||
                              "Passwords do not match"
                            );
                          },
                        })}
                        className="form-control fs-2"
                        type="password"
                      />

                      <label htmlFor="form_passwordfd">Confirm Password</label>
                    </div>
                  </div>

                  <div className="col-md-4 px-lg-0 mb-4">
                    <button
                      type="submit"
                      className="btn btn-primary w-100 py-3 fs-3"
                    >
                      Change Password
                    </button>
                  </div>
                </div>
              </form>
              {/*---- change password form End ----*/}
            </div>
          </div>
        </div>
        {/*----- container End -----*/}
      </section>
      {/*----- Change password Section End -----*/}

      {/*----- Login Contaienr Start -----*/}
      <section className="d-none">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3 mb-40">
              <h4 className="text-gray mt-0 pt-10 fs-2"> Change Password</h4>
              <hr />

              {/*---- Login form start ----*/}
              <form
                name="login-form"
                className="clearfix"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="row">
                  <div className="mb-3 col-md-12">
                    <label htmlFor="form_username_email32">Old Password</label>
                    <input
                      id="form_username_email32"
                      name="password"
                      {...register("oldPassword", {
                        required: "Old Password is required",
                      })}
                      className="form-control fs-3"
                      type="password"
                    />
                    <span className="text-danger fs-4">
                      {errors?.email?.message}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3 col-md-12">
                    <label htmlFor="form_password1">Password</label>
                    <input
                      id="form_password1"
                      name="password"
                      {...register("password", {
                        required: "Password is required",
                      })}
                      className="form-control fs-3"
                      type="password"
                    />
                    <span className="text-danger">
                      {errors?.password?.message}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3 col-md-12">
                    <label htmlFor="form_passwordfd">Confirm Password</label>
                    <input
                      id="form_passwordfd"
                      name="confirmPassword"
                      {...register("confirmPassword", {
                        required: "Confirm Password is required",
                        validate: (value) => {
                          return (
                            value === password.current ||
                            "Passwords do not match"
                          );
                        },
                      })}
                      className="form-control"
                      type="password"
                    />
                  </div>
                </div>
                <div className="clearfix">
                  <div className="checkbox  mt-15 float-start">
                    {/* <label htmlFor="form_checkbox">
                        <input id="form_checkbox" name="form_checkbox" type="checkbox" />
                        Remember me </label> */}
                  </div>
                  <div className="mb-3 tm-sc-button mt-10 float-end">
                    <button
                      type="submit"
                      className="btn btn-dark btn-xs"
                      style={{ width: "auto" }}
                    >
                      Change Password
                    </button>
                  </div>
                </div>
              </form>
              {/*---- Login form End ----*/}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ChangePassword;
