import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Layout from "../common/Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  dispatchResetQuickQuote,
  resetQuickQuoteResults,
  dispatchResetMotorQuote,
  resetSelectedPlan,
  dispatchResetProcessFlowMotor,
} from "../../store/actions/userActions";
import { makeid } from "../../store/reducers/userReducers";
import HomeSection from "../common/Homepage/HomeSection";
const Index = () => {
  const [vType, setVtype] = useState({ vehivleType: null, vehicleModel: null });
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const plusRef = useRef(null);

  const apiRequestQQ = useSelector((state) => state.root.apiRequestQQ);
  const motorRequest = useSelector((state) => state.root.motorRequest);
  const dispatch = useDispatch([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (apiRequestQQ.VariantCode.Digit === undefined) {
      dispatchResetQuickQuote("posId", apiRequestQQ.posId);
      dispatchResetMotorQuote("podId", motorRequest.posId);
      dispatchResetMotorQuote();
    }
    if (
      apiRequestQQ.MobileNumber != "" &&
      apiRequestQQ.ManufaturingDate != "" &&
      apiRequestQQ.RegistrationDate != ""
    ) {
      dispatchResetQuickQuote("posId", apiRequestQQ.posId);
      dispatchResetMotorQuote("podId", motorRequest.posId);
      dispatchResetMotorQuote();
      dispatch(resetQuickQuoteResults());
      dispatch(resetSelectedPlan());
    }
    dispatchResetQuickQuote("posId", apiRequestQQ.posId);
    dispatchResetMotorQuote("podId", motorRequest.posId);
    dispatchResetMotorQuote();
    dispatchResetProcessFlowMotor();
    dispatch(resetQuickQuoteResults());
    dispatchResetProcessFlowMotor();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (plusRef.current && !plusRef.current.contains(event.target)) {
        setIsPopUpVisible(false);
      }
    };

    if (isPopUpVisible) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isPopUpVisible]);

  const setVehicleType = (vehicleType, vehicleModel) => {
    const updatedVType = {
      vehicleModal: vehicleModel,
      vehicleType: vehicleType,
    };
    console.log("=-=-=-=-=-=-", updatedVType);
    navigate("/motor-process", {
      state: updatedVType,
    });
    setVtype(updatedVType);
  };
  // const toggleModal = () => console.log(vType);
  // const toggleModal = () => (navigate("/motor-process", { state: vType }));

  var settingsInsuranceSlider = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 1800,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var settingsCustomerSlider = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const toggleCallIcon = () => {
    // alert()
    setIsPopUpVisible(!isPopUpVisible);

    // plus.addEventListener('click', plusToggle);
  };

  // function plusToggle() {

  // }

  return (
    <Layout>
      {/*----- Floating call us button start -----*/}
      <div className="centered">
        <div
          className={`plus ${isPopUpVisible ? "plus--active" : ""}`}
          ref={plusRef}
          id="plus"
          onClick={() => toggleCallIcon()}
        >
          <i className="fas fa-phone-alt"></i>

          <div className="plus__line plus__line--v">
            <div className="cllcenternumber">
              <p className="fs-3 fw-bold mb-0">
                "Get in Touch with Us"
                <br />
                <span>
                  <a href="tel:+917862002244" className="me-2">
                    +91 - 7862002244
                  </a>
                  /
                  <a href="tel:01244363759" className="ms-2">
                    0124 - 4363759
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/*----- Floating call us button end -----*/}

      {/*----- Hero Banner Section start -----*/}
      <div className="welcomesec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="welcomecnt">
                <h2>The Futuristic Way To </h2>
                <h3>Buy Insurance</h3>
                <ul>
                  <li>
                    <a href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                      >
                        <path
                          d="M23.3398 12.5C23.3403 14.6442 22.7047 16.7404 21.5137 18.5234C20.3226 20.3064 18.6295 21.6962 16.6485 22.5168C14.6675 23.3374 12.4876 23.5521 10.3846 23.1336C8.28163 22.7152 6.34996 21.6824 4.83397 20.166C3.82431 19.1616 3.02392 17.9668 2.47913 16.6509C1.93434 15.3351 1.65597 13.9242 1.66014 12.5H3.12108C3.12108 17.6714 7.3286 21.8789 12.5 21.8789C17.6714 21.8789 21.8789 17.6714 21.8789 12.5C21.8789 7.32862 17.6714 3.1211 12.5 3.1211C11.2254 3.12108 9.96414 3.38125 8.79357 3.88568C7.623 4.39011 6.56773 5.12818 5.69237 6.05469H8.54491V7.51563H3.41795V2.39258H4.87938V4.79981C6.39913 3.29521 8.3301 2.27393 10.4291 1.86457C12.5281 1.45521 14.7014 1.67608 16.6751 2.49937C18.6489 3.32265 20.3349 4.71154 21.5208 6.49113C22.7068 8.27072 23.3397 10.3614 23.3398 12.5Z"
                          fill="black"
                        />
                        <rect
                          x="11.1111"
                          y="8.33337"
                          width="1.38889"
                          height="5.55555"
                          fill="#00B0CB"
                        />
                        <rect
                          width="1.38889"
                          height="5.55555"
                          transform="matrix(0 1 1 0 11.1111 13.1945)"
                          fill="#00B0CB"
                        />
                      </svg>{" "}
                      Renew Policy
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_1_51)">
                          <path
                            d="M12.4699 15.2193C13.9859 15.2193 15.2193 13.9858 15.2193 12.4698C15.2193 10.9538 13.9859 9.72034 12.4699 9.72034C10.9538 9.72034 9.7204 10.9538 9.7204 12.4698C9.7204 13.9858 10.9538 15.2193 12.4699 15.2193ZM12.4699 10.8073C13.3866 10.8073 14.1324 11.553 14.1324 12.4698C14.1324 13.3866 13.3866 14.1323 12.4699 14.1323C11.5531 14.1323 10.8074 13.3866 10.8074 12.4698C10.8074 11.553 11.5531 10.8073 12.4699 10.8073Z"
                            fill="#00B0CB"
                          />
                          <path
                            d="M24.3962 11.9264H22.2427C21.9696 6.96096 17.9787 2.97011 13.0133 2.69701V0.543478C13.0133 0.243388 12.7699 0 12.4698 0C12.1697 0 11.9264 0.243388 11.9264 0.543478V3.22509C11.9264 3.52518 12.1697 3.76857 12.4698 3.76857C17.2678 3.76857 21.1711 7.67192 21.1711 12.4698C21.1711 17.2678 17.2678 21.1711 12.4698 21.1711C7.67192 21.1711 3.76857 17.2678 3.76857 12.4698C3.76857 9.13804 5.71168 6.05525 8.71884 4.61621C8.98958 4.48659 9.10408 4.16214 8.97446 3.8914C8.84493 3.62074 8.5202 3.50625 8.24964 3.63578C6.60734 4.42165 5.21766 5.64819 4.23098 7.1827C3.31522 8.60698 2.79103 10.2369 2.69801 11.9264H0.543478C0.243388 11.9264 0 12.1697 0 12.4698C0 12.7699 0.243388 13.0133 0.543478 13.0133H2.69701C2.97011 17.9787 6.96096 21.9696 11.9264 22.2427V24.3962C11.9264 24.6963 12.1697 24.9397 12.4698 24.9397C12.7699 24.9397 13.0133 24.6963 13.0133 24.3962V22.2427C17.9787 21.9696 21.9696 17.9787 22.2427 13.0133H24.3962C24.6963 13.0133 24.9397 12.7699 24.9397 12.4698C24.9397 12.1697 24.6963 11.9264 24.3962 11.9264Z"
                            fill="black"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1_51">
                            <rect width="25" height="25" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>{" "}
                      Track Policy
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_1_59)">
                          <path
                            d="M12.0859 18.1875C12.1123 18.2136 12.1411 18.2372 12.1719 18.2578L12.2187 18.2832C12.2539 18.3058 12.293 18.3217 12.334 18.3301L12.3828 18.3438C12.4576 18.3585 12.5346 18.3585 12.6094 18.3438L12.6601 18.3281L12.7187 18.3106C12.7388 18.3013 12.7584 18.2908 12.7773 18.2793L12.8183 18.2559C12.8507 18.2342 12.8808 18.2093 12.9082 18.1816L18.1641 12.9316C18.2296 12.8806 18.2836 12.8163 18.3224 12.7428C18.3611 12.6693 18.3839 12.5885 18.389 12.5056C18.3942 12.4226 18.3816 12.3396 18.3522 12.2619C18.3229 12.1842 18.2773 12.1136 18.2185 12.0549C18.1598 11.9962 18.0892 11.9506 18.0115 11.9212C17.9338 11.8918 17.8508 11.8793 17.7679 11.8844C17.685 11.8896 17.6041 11.9123 17.5306 11.9511C17.4572 11.9899 17.3928 12.0438 17.3418 12.1094L13.0859 16.3594V0.585938C13.0859 0.430537 13.0242 0.281502 12.9143 0.171617C12.8044 0.0617325 12.6554 0 12.5 0C12.3446 0 12.1956 0.0617325 12.0857 0.171617C11.9758 0.281502 11.9141 0.430537 11.9141 0.585938V16.3594L7.65819 12.1094C7.54546 12.0217 7.40458 11.9781 7.26201 11.987C7.11945 11.9959 6.98504 12.0565 6.88404 12.1575C6.78303 12.2585 6.72241 12.3929 6.71355 12.5355C6.70469 12.678 6.74821 12.8189 6.83593 12.9316L12.0859 18.1875Z"
                            fill="black"
                          />
                          <path
                            d="M24.4141 17.1875C24.2587 17.1875 24.1096 17.2492 23.9997 17.3591C23.8899 17.469 23.8281 17.618 23.8281 17.7734V22.0703C23.8281 22.5365 23.6429 22.9836 23.3133 23.3133C22.9836 23.6429 22.5365 23.8281 22.0703 23.8281H2.92969C2.46349 23.8281 2.01638 23.6429 1.68673 23.3133C1.35707 22.9836 1.17188 22.5365 1.17188 22.0703V17.7734C1.17188 17.618 1.11014 17.469 1.00026 17.3591C0.890373 17.2492 0.741338 17.1875 0.585938 17.1875C0.430537 17.1875 0.281502 17.2492 0.171617 17.3591C0.0617325 17.469 0 17.618 0 17.7734L0 22.0703C0 22.8473 0.308663 23.5925 0.858086 24.1419C1.40751 24.6913 2.15269 25 2.92969 25H22.0703C22.8473 25 23.5925 24.6913 24.1419 24.1419C24.6913 23.5925 25 22.8473 25 22.0703V17.7734C25 17.618 24.9383 17.469 24.8284 17.3591C24.7185 17.2492 24.5695 17.1875 24.4141 17.1875Z"
                            fill="#00B0CB"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1_59">
                            <rect width="25" height="25" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>{" "}
                      Download Policy
                    </a>
                  </li>
                </ul>
              </div>

              <div className="welcomeimg">
                <img
                  src={"/assetss/images/welcomeimg.png"}
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*----- Hero Banner Section End -----*/}

      {/*----- Insurance Card MMV start -----*/}

      <div className="insuranceType">
        <div className="container-fluid container-lg">
          <div className="insuranceTypebox">
            <h2 className="insheadingsm">
              The Futuristic Way To
              <strong>Buy Insurance</strong>
            </h2>
            <ul>
              <li id="4w">
                <label htmlFor="4w" className="insradiobox">
                  <div onClick={() => setVehicleType("4w", "4w")}>
                    <img
                      src={"/assetss/images/caricon.png"}
                      alt="img"
                      className="img-fluid"
                    />
                    <span className="insutitle">
                      <strong>Pvt Car</strong>
                    </span>
                    <input type="radio" checked="" name="radio" />
                    <span className="checkmark"></span>
                  </div>
                </label>
              </li>

              <li id="2w">
                <label htmlFor="2w" className="insradiobox">
                  <div
                    onClick={() => {
                      setVehicleType("2w", "2w");
                    }}
                  >
                    <img
                      src={"/assetss/images/bikeicon.png"}
                      alt="img"
                      className="img-fluid"
                    />
                    <span className="insutitle">
                      <strong>Bike</strong>
                    </span>
                    <input type="radio" checked="" name="radio" />
                    <span className="checkmark"></span>
                  </div>
                </label>
              </li>

              <li id="taxi">
                <label htmlFor="taxi" className="insradiobox">
                  <div
                    onClick={() => {
                      setVehicleType("pcv", "pcv");
                    }}
                  >
                    <img
                      src={"/assetss/images/taxiicon.png"}
                      alt="img"
                      className="img-fluid"
                    />
                    <span className="insutitle">
                      <strong>Taxi</strong>
                    </span>
                    <input type="radio" checked="" name="radio" />
                    <span className="checkmark"></span>
                  </div>
                </label>
              </li>

              <li>
                <label className="insradiobox">
                  <div
                    onClick={() => {
                      setVehicleType("gcv", "gcv");
                    }}
                  >
                    <img
                      src={"/assetss/images/gcvicon.png"}
                      alt="img"
                      className="img-fluid"
                    />
                    <span className="insutitle">
                      <strong>GCV</strong>
                    </span>
                    <input type="radio" checked="" name="radio" />
                    <span className="checkmark"></span>
                  </div>
                </label>
              </li>

              <li>
                <label className="insradiobox">
                  <div>
                    <img
                      src={"/assetss/images/paicon.png"}
                      alt="img"
                      className="img-fluid"
                    />
                    <span className="insutitle">
                      <strong>PA</strong>
                    </span>
                    <input type="radio" checked="" name="radio" />
                    <span className="checkmark"></span>
                  </div>
                </label>
              </li>

              <li>
                <label className="insradiobox">
                  <div>
                    <img
                      src={"/assetss/images/hearticon.png"}
                      alt="img"
                      className="img-fluid"
                    />
                    <span className="insutitle">
                      <strong>Health</strong>
                    </span>
                    <input type="radio" checked="" name="radio" />
                    <span className="checkmark"></span>
                  </div>
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/*----- Insurance Card MMV End -----*/}

      {/*----- Intro section start -----*/}
      {/* Expert Cover Cards  */}
      <div className="whychose">
        <h2 className="whychooseheading">
          Why Choose <strong>Expert Cover</strong>
        </h2>
        <div className="container">
          <div className="whychoosebox">
            <img
              src={"/assetss/images/millionicon.png"}
              alt="img"
              className="img-fluid"
            />
            <h2>Over 9 million</h2>
            <p>
              Our commitment to excellence has earned the trust of over 9
              million satisfied customers. Experience top-notch insurance
              solutions with Expert Cover.
            </p>
          </div>

          <div className="whychoosebox">
            <img
              src={"/assetss/images/insurersicon.png"}
              alt="img"
              className="img-fluid"
            />
            <h2>50+ insurers</h2>
            <p>
              We collaborate with more than 50 trusted insurers, providing you
              with a diverse range of insurance options tailored to your unique
              needs.
            </p>
          </div>

          <div className="whychoosebox">
            <img
              src={"/assetss/images/greatpriceicon.png"}
              alt="img"
              className="img-fluid"
            />
            <h2>Great Prices & Value</h2>
            <p>
              Enjoy great prices and unbeatable value on insurance plans. Our
              goal is to ensure you receive the best coverage at prices that fit
              your budget.
            </p>
          </div>

          <div className="whychoosebox">
            <img
              src={"/assetss/images/claimsicon.png"}
              alt="img"
              className="img-fluid"
            />
            <h2>Claims</h2>
            <p>
              We've streamlined the claims process for your convenience. Get
              peace of mind knowing that we're here to support you during
              unexpected events.
            </p>
          </div>
        </div>
        {/* <div className="whychoseslidersm slider">
          <div className="whychoosebox">
            <img
              src={"/assetss/images/millionicon.png"}
              alt="img"
              className="img-fluid"
            />
            <h2>Over 9 million</h2>
            <p>
              It was popularised in the 1960s with the release of Letraset
              sheets containing Lorem Ipsum passages
            </p>
          </div>

          <div className="whychoosebox">
            <img
              src={"/assetss/images/insurersicon.png"}
              alt="img"
              className="img-fluid"
            />
            <h2>50+ insurers</h2>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered
            </p>
          </div>

          <div className="whychoosebox">
            <img
              src={"/assetss/images/greatpriceicon.png"}
              alt="img"
              className="img-fluid"
            />
            <h2>Great Price</h2>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered
            </p>
          </div>

          <div className="whychoosebox">
            <img
              src={"/assetss/images/claimsicon.png"}
              alt="img"
              className="img-fluid"
            />
            <h2>Claims</h2>
            <p>
              The standard chunk of Lorem Ipsum used since the 1500s is
              reproduced below for those interested.
            </p>
          </div>
        </div> */}
      </div>
      {/*----- Intro section End -----*/}

      {/*----- Capability Section Start -----*/}
      <div className="insuranceslider slider">
        <Slider {...settingsInsuranceSlider}>
          <div className="helthinsbox mb-5">
            <div className="helthinsicon">
              <img
                src={"/assetss/images/helthinsicon.png"}
                alt="img"
                className="img-fluid"
              />
            </div>
            <h2>Health Insurance</h2>
            <h3>
              Protect Yourself & <br /> your Family against disease <br />{" "}
              including <span className="fw-bolder">COVID-19</span>
            </h3>
            {/* <h5>COVID-19</h5> */}
            <div className="insuimg">
              <img src={"/assetss/images/protectimg.png"} alt="img-fluid" />
            </div>
          </div>

          <div className="helthinsbox mt-5">
            <div className="helthinsicon">
              <img
                src={"/assetss/images/terminsicon.png"}
                alt="img"
                className="img-fluid"
              />
            </div>
            <h2>Term Insurance</h2>
            <h3>
              Secure your <span className="fw-bolder">Family’s</span> <br />
              Financial Future
            </h3>

            {/* <h6>Financial Future</h6>  */}
            <div className="insuimg">
              <img src={"/assetss/images/familyimg.png"} alt="img-fluid" />
            </div>
          </div>

          <div className="helthinsbox mb-5">
            <div className="helthinsicon">
              <img
                src={"/assetss/images/homeinsurance.png"}
                alt="img"
                className="img-fluid"
              />
            </div>
            <h2>Home Insurance</h2>
            <h3>
              50 Lakhs cover for your <br /> home starting at{" "}
              <span className="fw-bolder">
                {" "}
                <br />
                ₹80/month*{" "}
              </span>
            </h3>
            <h5> </h5>
            <div className="insuimg">
              <img
                src={"/assetss/images/houseinsuranceimg.png"}
                alt="img-fluid"
              />
            </div>
          </div>

          <div className="helthinsbox mt-5">
            <div className="helthinsicon">
              <img
                src={"/assetss/images/businessicon.png"}
                alt="img"
                className="img-fluid"
              />
            </div>
            <h2>Business Insurance</h2>
            <h3>
              One stop solution for all your{" "}
              <span className="fw-bolder">Business </span>Insurance <br /> needs
            </h3>

            {/* <h6></h6>  */}
            <div className="insuimg">
              <img src={"/assetss/images/businessimg.png"} alt="img-fluid" />
            </div>
          </div>

          <div className="helthinsbox mb-5">
            <div className="helthinsicon">
              <img
                src={"/assetss/images/integrity.png"}
                alt="img"
                className="img-fluid"
              />
            </div>
            <h2 className="py-2">PA Insurance</h2>
            <h3>
              Secure your financial well-being <br />
              for unexpected life events
            </h3>
            {/* <h6></h6> */}
            {/* <h5>PA Insurance</h5> */}

            <div className="insuimg">
              <img
                style={{ marginLeft: "1rem" }}
                src={"/assetss/images/accident.png"}
                alt="img-fluid"
              />
            </div>
          </div>

          <div className="helthinsbox mt-5">
            <div className="helthinsicon">
              <img
                src={"/assetss/images/car-white.png"}
                alt="img"
                className="img-fluid"
              />
            </div>
            <h2>Motor Insurance</h2>
            <h3>
              Insure your vehicle & <br /> hit the road with confidence
            </h3>
            {/* <h6>hit the road with confidence</h6> */}
            <div className="insuimg">
              <img
                src={"/assetss/images/car-slider.png"}
                alt="img-fluid"
                style={{ width: "38rem", marginLeft: "10rem" }}
              />
            </div>
            {/* Secure your financial well-being with Personal Accident (PA) Insurance for unexpected life events */}
            {/* Insure your vehicle and hit the road with confidence. */}
          </div>
        </Slider>
      </div>
      {/*----- Capability Section End -----*/}

      {/*----- Mobile App section start -----*/}
      <div className="expertcover">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="expertcoverheading">Expert Cover Advantages</h2>
              <p className="expcovertitle">
                When you buy insurance from us, you get more than just financial
                safety. You get: our promise of simplifying complex insurance
                terms and conditions, quick stress-free claims, instant quotes
                from top insurers and we being present for you in the toughest
                of times.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="expercoverlist">
                <div className="expertcoverbox">
                  <img
                    src="/assetss/images/bestpriceicon.png"
                    alt="img"
                    className="img-fluid"
                  />
                  <div className="expertcnt">
                    <h2>
                      One of the Best <br /> Prices
                    </h2>
                    <h3>Guaranteed</h3>
                  </div>
                </div>

                <div className="expertcoverbox">
                  <img
                    src="/assetss/images/unbaisedicon.png"
                    alt="img"
                    className="img-fluid"
                  />
                  <div className="expertcnt">
                    <h2>Unbiased Advice</h2>
                    <h3>Keeping customers first</h3>
                  </div>
                </div>

                <div className="expertcoverbox">
                  <img
                    src="/assetss/images/reliableicon.png"
                    alt="img"
                    className="img-fluid"
                  />
                  <div className="expertcnt">
                    <h2>100% Reliable</h2>
                    <h3>
                      Regulated by <br /> IRDAI
                    </h3>
                  </div>
                </div>

                <div className="expertcoverbox">
                  <img
                    src="/assetss/images/claimsupporticon.png"
                    alt="img"
                    className="img-fluid"
                  />
                  <div className="expertcnt">
                    <h2>Claims Support</h2>
                    <h3>Made stress-free</h3>
                  </div>
                </div>

                <div className="expertcoverbox">
                  <img
                    src="/assetss/images/happyicon.png"
                    alt="img"
                    className="img-fluid"
                  />
                  <div className="expertcnt">
                    <h2>Happy to Help</h2>
                    <h3>Every day of the week</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*----- Mobile App section End -----*/}

      <div className="ourpartner">
        <div className="container">
          <h2 className="ourpartnerheading">Our Partners</h2>
          <ul className="partnerlogos">
            <li>
              <img
                src="./assetss/images/digitimg.png"
                alt="img"
                className="img-fluid"
              />
            </li>
            <li>
              <img
                src="./assetss/images/bajajimg.png"
                alt="img"
                className="img-fluid"
              />
            </li>
            <li>
              <img
                src="./assetss/images/kotakimg.png"
                alt="img"
                className="img-fluid"
              />
            </li>
            <li>
              <img
                src="./assetss/images/shiramimg.png"
                alt="img"
                className="img-fluid"
              />
            </li>
            <li>
              <img
                src="./assetss/images/sbiimg.png"
                alt="img"
                className="img-fluid"
              />
            </li>
          </ul>

          {/* <div className="partnerlogo slider d-flex flex-wrap d-none">
            <div className="m-5">
              <img
                src="./assetss/images/digitimg.png"
                alt="img"
                className="img-fluid"
              />
            </div>
            <div className="m-5">
              <img
                src="./assetss/images/bajajimg.png"
                alt="img"
                className="img-fluid"
              />
            </div>
            <div className="m-5">
              <img
                src="./assetss/images/kotakimg.png"
                alt="img"
                className="img-fluid"
              />
            </div>
            <div className="m-5">
              <img
                src="./assetss/images/shiramimg.png"
                alt="img"
                className="img-fluid"
              />
            </div>
            <div className="m-5">
              <img
                src="./assetss/images/sbiimg.png"
                alt="img"
                className="img-fluid"
              />
            </div>
          </div> */}
        </div>
      </div>
      {/*----- Our Partners Section End -----*/}

      {/*----- Testimonial Section start -----*/}
      <div className="customersec">
        <div className="container">
          <h2 className="testimonialheding">Our Customers Love Us</h2>
          <div className="testimonial slider">
            <Slider {...settingsCustomerSlider}>
              <div className="testimonialbox">
                <img
                  src={"/assetss/images/quoteicon.png"}
                  alt="img"
                  className="img-fluid"
                />
                <p>
                  I was pleasantly surprised by the exceptional service and
                  coverage Expert Cover provided. Their insurance options are
                  tailored to my needs, and their claims process was effortless.
                  Highly recommended!
                </p>
                <h2>Ananya Joshi</h2>
                <h3>Delhi</h3>
              </div>

              <div className="testimonialbox">
                <img
                  src={"/assetss/images/quoteicon.png"}
                  alt="img"
                  className="img-fluid"
                />
                <p>
                  Expert Cover made the insurance process simple and
                  stress-free. Their competitive prices and the variety of
                  insurers they work with gave me confidence in my choice. Thank
                  you for your excellent service!
                </p>
                <h2>Shivraj Singh</h2>
                <h3>Jaipur Rajasthan</h3>
              </div>

              <div className="testimonialbox">
                <img
                  src={"/assetss/images/quoteicon.png"}
                  alt="img"
                  className="img-fluid"
                />
                <p>
                  I've been a loyal customer of Expert Cover for years, and they
                  consistently deliver quality insurance options. Their
                  responsive team and quick claims processing make them my top
                  choice.
                </p>
                <h2>Vikram</h2>
                <h3>Bikaner Rajasthan</h3>
              </div>

              <div className="testimonialbox">
                <img
                  src={"/assetss/images/quoteicon.png"}
                  alt="img"
                  className="img-fluid"
                />
                <p>
                  Exceptional service, competitive prices, and a wide range of
                  insurance options. Expert Cover truly stands out in the
                  insurance industry. I'm a satisfied customer for life!
                </p>
                <h2>Vinod Joshi</h2>
                <h3>Delhi</h3>
              </div>

              <div className="testimonialbox">
                <img
                  src={"/assetss/images/quoteicon.png"}
                  alt="img"
                  className="img-fluid"
                />
                <p>
                  Expert Cover exceeded my expectations with their transparent
                  policies and outstanding customer service. I wholeheartedly
                  recommend them for all your insurance needs.
                </p>
                <h2>Priya Sengupta</h2>
                <h3>Kolkata, West Bengal</h3>
              </div>

              <div className="testimonialbox">
                <img
                  src={"/assetss/images/quoteicon.png"}
                  alt="img"
                  className="img-fluid"
                />
                <p>
                  I've had the pleasure of dealing with Expert Cover, and their
                  commitment to providing the best insurance solutions is
                  remarkable. They've got my trust and loyalty.
                </p>
                <h2>Arun Gupta</h2>
                <h3>Chennai, Tamil Nadu</h3>
              </div>
            </Slider>
          </div>
        </div>
      </div>
      {/*----- Testimonial Section End -----*/}

      {/*----- Our Partners Section Start -----*/}
      {/* 
      <HomeSection
        modal={modal}
        setModal={(val) => setModal(val)}
        toggleModal={toggleModal}
        vehicleType={vType.vehivleType}
        VehicleModel={vType.vehicleModel}
      /> */}
    </Layout>
  );
};

export default Index;
