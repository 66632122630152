import React from "react";
import "./footerpages.css";
import Header from "../Header";
import Footer from "../Footer";

function SeniorCitizen() {
  return (
    <>
      <Header />
      <div>
        <div className="insurance-cantainer">
          <h1>Senior Citizen Health insurance</h1>
        </div>
        <div className="cantainer">
          <div className="box-info">
            <div className="box-1">
              <h2>
                "Golden Years Well-Protected: Senior Citizen Health Insurance
                Explained"
              </h2>
              <p>
                Senior citizen health insurance is a specialized insurance
                product tailored to cater to the unique healthcare needs of
                individuals in their golden years. As we age, healthcare
                expenses tend to rise due to potential health issues and the
                need for regular medical care. Senior citizen health insurance
                helps mitigate these costs and ensures that elderly individuals
                receive adequate medical attention without financial strain.
                These insurance plans typically cover a range of medical
                expenses, including hospitalization, doctor consultations,
                diagnostic tests, medications, pre-existing conditions, and
                sometimes even critical illnesses. The coverage provided can
                vary based on the insurance policy, so it's crucial to carefully
                review the policy details and choose a plan that suits specific
                health requirements.
              </p>
              <p>
                Premiums for senior citizen health insurance may be higher
                compared to standard health insurance due to the increased
                health risks associated with age. However, the peace of mind and
                financial security it offers make it a vital investment for
                seniors and their families. It's important to consider factors
                like coverage limits, co-payments, waiting periods, and network
                hospitals while selecting a plan. Additionally, understanding
                the policy's renewal terms and the process for claim settlement
                is essential to ensure a smooth healthcare experience during the
                later stages of life.
              </p>
            </div>
            <div className="box-2 senior-Citizen">
              <img src="./assetss/images/seniorinsurance.png" alt="Erroe" />
            </div>
          </div>
        </div>
        <div className="vision-info">
          <div className="vision-box">
            <div className="img-box">
              <img src="./assetss/images/vision.png" alt="Error" />
            </div>
            <div className="vision-box1">
              <h2>Our vision</h2>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend to repeat predefined chunks as necessary,
              </p>
            </div>
          </div>
        </div>
        <div className="btn-cantainer">
          <div className="btn-1">
            <h1>Solving your Insurance Needs on Time</h1>
          </div>
          <div className="btn-1">
            <h2>
              Get world-class insurance coverage that not only protects you but
              also helps you build wealth. Let us be your insurance partner.
            </h2>
            <div className="btn-info">
              <button>Get Started</button>
              <button>Contact Us</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SeniorCitizen;
