import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import Header2 from "./Header2";

const Layout = ({ children, header2 }) => {
  return (
    <>
      {/*----- Main Container Start -----*/}
      <div className="main-container">
        {/*----- Header Start -----*/}
        {header2 ? <Header /> : <Header />}
        {/*----- Header End -----*/}

        {children}

        {/*----- Footer Start -----*/}
        {header2 != true && <Footer />}

        {/*----- Footer End -----*/}
      </div>
    </>
  );
};

export default Layout;
