import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { PostData } from '../../api/apiHelper';
import { sendErrorMessage } from '../Services/PosService';
import Layout from '../common/Layout';


const ForgetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const navigate = useNavigate();

  const handleForget = (data) => {
    PostData('auth/forget', data).then((response) => {
      if (response.status === true) {
        console.log("first", response.data)
        navigate("/resetpassword", { state: { email: data.email } });
      } else {
        sendErrorMessage(response);
      }
    });
  }


  return (
    <>
      <Layout>
        <section className="mylogin mt-5 py-5">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-6 col-12 d-lg-block d-none">
                <img src="/assets/images/g8.png" className="w-100" alt="" />
              </div>

              <div className="col-lg-5 ms-auto">
                <form onSubmit={handleSubmit(handleForget)}>
                  <div className="card shadow-lg border-0">
                    <div className="card-body p-5">
                      <div className="form-floating mb-5 pb-4">
                        <input
                          type="email"
                          className="form-control"
                          id="umail"
                          placeholder="Enter User Email"
                          {...register("email", {
                            required: "Email is required",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: "Invalid email address",
                            },
                          })}
                        />
                        <label htmlFor="umail">
                          Enter Email
                          <span className="text-danger">*</span>
                        </label>

                        <p className="f-error">{errors?.email?.message}</p>
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary w-100 py-3 fs-1">
                        Submit
                      </button>

                      <Link to={'/login'} className="text-decoration-underline fs-2 fw-bold text-dark mt-4 d-block text-end">
                        Already Have an account? Login
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Layout >
    </>
  )
}

export default ForgetPassword