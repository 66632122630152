import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  CUSTOMER_TYPE,
  POLICY_TYPE,
  insurers,
  kycDoc,
  kycDocName,
  kycType
} from '../../utility/constants'
import { useForm } from 'react-hook-form'
import {
  dispatchMotorQuote,
  dispatchProcessFlowMotor
} from '../../../store/actions/userActions'
import { getKyc, processFlow } from '../../common/Homepage/TPApiCall'
import { toast } from 'react-toastify'
import { getVehiclePincode } from '../../Services/masterServices'
import ReactSelect from '../../common/Tags/ReactSelectProposal'
import { load } from 'react-cookies'
import RedirectionModal from '../../common/ProposalPage/RedirectionModal'
import { useNavigate } from 'react-router-dom'

const KYC = ({ setActiveTab, kycStatusCheck, handleStepClick }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    getValues,
    watch,
    clearErrors
  } = useForm({ mode: 'onBlur' })

  const {
    handleSubmit: handleSubmit1,
    register: register1,
    formState: { errors: errors1 },
    reset: reset1,
    setValue: setValue1,
    clearErrors: clearErrors1
  } = useForm({ mode: 'onBlur' })

  const motorRequest = useSelector(state => state?.root?.motorRequest)
  const processFlowObjMotor = useSelector(
    state => state?.root?.processFlowObjMotor
  )
  const kycConfig = useSelector(state => state?.root?.kycConfig)
  const selectedPlan = useSelector(state => state?.root?.selectedPlan)
  const [proceedSpinner, setProceedSpinner] = useState(false)
  const [proceedBackSpinner, setProceedBackSpinner] = useState(false)
  const [kycMode, setKycMode] = useState('ckyc')
  const [kycStatus, setKycStatus] = useState(false)
  const [pincodeData, setPincodeData] = useState([])
  const [redirectionModal, setRedirectionModal] = useState(false)
  const [redirectionUrl, setRedirectionUrl] = useState(null)
  const [pincodeError, setPincodeError] = useState(false)
  const [kycFields, setKycFields] = useState('')
  const [address, setAddress] = useState({
    addressLine1: motorRequest?.addressLine1 || '',
    addressLine2: motorRequest?.addressLine2 || '',
    addressLine3: motorRequest?.addressLine3 || '',
    pincode: motorRequest?.pincode || ''
  })
  const [addressErrors, setAddressErrors] = useState({})
  const QuickQouteResult = useSelector(state => state?.root?.QuickQouteResult)
  const navigate = useNavigate()

  useEffect(() => {
    if (kycConfig) {
      setKycFields(
        kycConfig?.KycFieldConfig.filter(
          kycType => kycType.kycType === motorRequest?.kycType?.toLowerCase()
        )
      )
      dispatchMotorQuote(
        'kycFields',
        kycConfig?.KycFieldConfig.filter(
          kycType => kycType.kycType === motorRequest?.kycType?.toLowerCase()
        )
      )
      console.log(
        'kycFields',
        kycConfig?.KycFieldConfig.filter(
          kycType => kycType.kycType === motorRequest?.kycType?.toLowerCase()
        )
      )
    }
    setValue('docName', motorRequest?.docName)
  }, [kycConfig, kycStatusCheck])

  const handleAddressChange = e => {
    const { name, value } = e.target
    if (name === 'addressLine1') {
      setAddressErrors(prev => ({
        ...prev,
        addressLine1: ''
      }))
    } else if (name === 'addressLine2') {
      setAddressErrors(prev => ({
        ...prev,
        addressLine2: ''
      }))
    } else if (name === 'addressLine3') {
      setAddressErrors(prev => ({
        ...prev,
        addressLine3: ''
      }))
    }
    setAddress({ ...address, [name]: value })
    dispatchMotorQuote(name, value)
  }

  const validateAddressFields = () => {
    let isValid = true
    const newErrors = {}

    if (!address.addressLine1) {
      newErrors.addressLine1 = 'Address 1 is required'
      isValid = false
    }
    if (!address.addressLine2) {
      newErrors.addressLine2 = 'Address 2 is required'
      isValid = false
    }
    if (!address.addressLine3) {
      newErrors.addressLine3 = 'Address 3 is required'
      isValid = false
    }

    setAddressErrors(newErrors)
    return isValid
  }

  const handleNextClick = () => {
    if (motorRequest?.pincode === '') {
      setPincodeError(true)
      return
    } else {
      setPincodeError(false)
    }
    if (validateAddressFields()) {
      if (selectedPlan?.insurerId === 10 || selectedPlan?.PersonalAccident) {
        setActiveTab(2)
        handleStepClick(2)
      } else {
        setActiveTab(3)
        handleStepClick(3)
      }
      const dataForFlow = {
        ...processFlowObjMotor,
        customerLeadId: motorRequest?.customerLeadId,
        motorRequest: { ...motorRequest },
        processDiscription: 'kycAndUserDetails',
        step: 'step8',
        step8: {
          ...processFlowObjMotor.step8,
          kycAndUserDetails: {
            ...processFlowObjMotor.step8.kycAndUserDetails,
            maritalStatus: motorRequest?.maritalStatus,
            addressLine1: motorRequest?.addressLine1,
            addressLine2: motorRequest?.addressLine2,
            addressLine3: motorRequest?.addressLine3,
            cityId: motorRequest?.cityId,
            city: motorRequest?.city,
            stateId: motorRequest?.stateId,
            state: motorRequest?.state,
            pincode: motorRequest?.pincode
          },
          motorRequest: { ...motorRequest },
          planDetails: selectedPlan
        }
      }
      processFlow(dataForFlow)
    }
  }

  useEffect(() => {
    getVehiclePincode()
      .then(response => {
        console.log('RESPONSE PINCODE DATA', response)
        if (response.status === true) {
          let i = 0
          let arr = []
          let arr1 = []
          let j = 0
          while (i < response.data.length) {
            arr.push({
              value: response.data[i].Pin_Code,
              label:
                response.data[i].Pin_Code + ' - ' + response.data[i].District,
              District: response.data[i].District,
              City: response.data[i].City,
              State: response.data[i].State,
              cityId: response.data[i].cityId,
              stateId: response.data[i].stateId
            })

            i++
          }
          setPincodeData(arr)
        }
      })
      .catch(err => console.log(err))
  }, [])

  const submitKycDetails = async data => {

    console.log('kycConfig?.kycConfig?.kycStep', kycConfig?.kycConfig?.kycStep)
    console.log('kycStatus', kycStatus)
    setProceedSpinner(true)
    let newData = { ...motorRequest }

    if (
      kycConfig?.kycConfig?.kycStep === 'in_proposal' ||
      kycConfig?.kycConfig?.kycStep === 'after_proposal'
    ) {
      for (let key in data) {
        if (key === 'documentNo') {
          dispatchMotorQuote(`docNumber`, data[key])
          newData[key] = data[key]
        } else {
          dispatchMotorQuote(`${key}`, data[key])
          newData[key] = data[key]
        }
      }
      const dataForFlow = {
        ...processFlowObjMotor,
        customerLeadId: motorRequest?.customerLeadId,
        motorRequest: { ...motorRequest },
        processDiscription: 'kycAndUserDetails',
        step: 'step8',
        step8: {
          ...processFlowObjMotor.step8,
          motorRequest: { ...motorRequest },
          kycAndUserDetails: newData,
          kycConfig: kycConfig,
          planDetails: selectedPlan
        }
      }
      processFlow(dataForFlow)
      if (selectedPlan?.insurerId === 10 || selectedPlan?.PersonalAccident) {
        setActiveTab(2);
      } else {
        setActiveTab(3);
      }
    }


    if (!kycStatus && kycConfig?.kycConfig?.kycStep === 'before_proposal') {
      for (let key in data) {
        dispatchMotorQuote(`${key}`, data[key])
        newData[key] = data[key]
      }

      try {
        const response = await getKyc(newData, selectedPlan)
        if (response?.data?.kycStatus === 'success') {
          let newDataForFlow = { ...data }
          newDataForFlow.firstName = motorRequest?.firstName
          const newData = {
            ...newDataForFlow,
            ...data,
            kycStatus: 'completed',
            kycType: motorRequest?.kycType
          }
          dispatchProcessFlowMotor('step8.kycAndUserDetails', newData)
          dispatchMotorQuote('kycStatus', 'completed')
          dispatchMotorQuote('kycData', response?.data?.kycData)
          toast.success('KYC Successful', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
          })
          const dataForFlow = {
            ...processFlowObjMotor,
            customerLeadId: motorRequest?.customerLeadId,
            motorRequest: { ...motorRequest },
            processDiscription: 'kycAndUserDetails',
            step: 'step8',
            step8: {
              ...processFlowObjMotor.step8,
              motorRequest: { ...motorRequest },
              kycAndUserDetails: newData,
              kycConfig: kycConfig,
              planDetails: selectedPlan
            }
          }
          processFlow(dataForFlow)
          setKycStatus(true)
          setProceedSpinner(false)
        } else if (response?.data?.kycStatus === 'failure') {
          dispatchMotorQuote('kycStatus', 'failure')
          toast.error(
            `${
              response?.data?.errors
                ? response?.data?.errors[0]?.displayMessage
                : 'KYC Failed. Please check your details and try again'
            }`,
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light'
            }
          )
          setProceedSpinner(false)
        } else {
          setProceedSpinner(false)
          toast.error(
            `${
              response?.data?.errors
                ? response?.data?.errors[0]?.displayMessage
                : 'KYC Failed. Please check your details and try again'
            }`,
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light'
            }
          )
        }
      } catch (error) {
        toast.error(
          `${'KYC Failed. Please check your details and try again'}`,
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
          }
        )
        setProceedSpinner(false)
      }
    }

    if (kycStatus) {
      setActiveTab(2)
    }
  }

  const handleSelectPincode = data => {
    setPincodeError(false)
    console.log('pincode data', data)
    dispatchMotorQuote('cityId', data?.cityId)
    dispatchMotorQuote('stateId', data?.stateId)
    dispatchMotorQuote('pincode', data?.value)
    dispatchMotorQuote('city', data?.City)
    dispatchMotorQuote('state', data?.State)
    reset({
      city: data?.City,
      state: data?.State,
      docName: getValues('docName')
    })

    // setValue("pincode", data?.Pin_Code);
  }

  const handleUploadFile = e => {
    console.log('e.target.files', e.target)
  }

  const handleInputChange = e => {
    const value = e?.target?.value.toUpperCase()
    setValue(e?.target?.name, value)
  }

  const handleMaritalStatus = e => {
    const value = e.target.value
    console.log('value', value)
    dispatchMotorQuote('maritalStatus', value)
  }

  const handlePreviousPage = () => {
    debugger
    setProceedBackSpinner(true)
    const newData = {
      customerLeadId: motorRequest?.customerLeadId,
      step: 'step7',
      motorRequest: { ...motorRequest },
      processDiscription: 'quotation',
      step7: {
        ...processFlowObjMotor.step7,
        motorRequest: { ...motorRequest },
        QuickQouteResult: { ...QuickQouteResult }
      }
    }
    processFlow(newData)
    setTimeout(() => {
      navigate(`/quotelist/${motorRequest?.customerLeadId}`)
      setProceedBackSpinner(false)
    }, [1000])
  }

  return (
    <form onSubmit={handleSubmit(submitKycDetails)} className='w-100'>
      <div className='posformbox  mx-auto'>
        <div className='row'>

          <div className='col-md-6 mb-2'>
            <div className='onwer-details'>
              <div
                className='did-floating-label-content'
                style={{ marginBottom: '0px' }}
              >
                <>
                  <select
                    className='did-floating-input'
                    {...register('docName', {
                      required: 'Please Select Document Type to Verify'
                    })}
                    // defaultValue={kycStatusCheck ? motorRequest?.docName : ""}
                    disabled={kycStatus ? true : false}
                  >
                    <option value=''>Select Document Type</option>
                    {kycConfig?.KycFieldConfig.map(
                      item =>
                        item?.kycType === kycType?.ckyc && (
                          <option
                            value={item?.docName}
                            selected={
                              motorRequest?.docName === item?.docName
                                ? true
                                : false
                            }
                            onClick={() => reset('')}
                          >
                            {kycDocName[item.docName]}
                          </option>
                        )
                    )}
                  </select>
                  <label htmlFor='idxx4' className='did-floating-label'>
                    Select Document Type
                    <span className='text-danger'>*</span>
                  </label>
                </>
              </div>
              <p className='f-error text-danger'>{errors?.docName?.message}</p>
            </div>
          </div>

          {kycConfig &&
            kycFields &&
            kycFields.map(
              field =>
                watch('docName') === field?.docName &&
                field?.requiredField?.map(input => (
                  <div className='col-md-6 mb-3 mt-3 mt-md-0' key={input}>
                    {input === 'gender' ? (
                      <div className='did-floating-label-content'>
                        <select
                          name={input}
                          className='did-floating-input'
                          id={input}
                          {...register(input, {
                            required: `${input} is required`
                          })}
                        >
                          <option value='' className='text-capitalize'>
                            {input}
                          </option>
                          <option
                            value='M'
                            selected={
                              kycStatusCheck && motorRequest?.gender === 'M'
                            }
                          >
                            Male
                          </option>
                          <option
                            value='F'
                            selected={
                              kycStatusCheck && motorRequest?.gender === 'F'
                            }
                          >
                            Female
                          </option>
                          <option
                            value='O'
                            selected={
                              kycStatusCheck && motorRequest?.gender === 'O'
                            }
                          >
                            Other
                          </option>
                        </select>
                        <label
                          htmlFor={input}
                          className='did-floating-label text-capitalize'
                        >
                          {input}
                          <span className='text-danger'>*</span>
                        </label>
                        <p className='f-error text-danger'>
                          {errors?.[input]?.message}
                        </p>
                      </div>
                    ) : input === 'pincode' ? (
                      <div className='onwer-details'>
                        <div className='did-floating-label-content'>
                          <ReactSelect
                            options={pincodeData}
                            onChange={val => {
                              handleSelectPincode(val)
                            }}
                            placeholder={input}
                            className='form-select did-floating-input'
                            defaultValue={motorRequest?.pincode}
                          />
                          <label htmlFor='idxx4' className='did-floating-label'>
                            Select Pincode
                            <span className='text-danger'>*</span>
                          </label>
                          {pincodeError && (
                            <p className='f-error text-danger'>
                              Pincode is required
                            </p>
                          )}
                        </div>
                      </div>
                    ) : (
                      // </div>
                      <div className='did-floating-label-content'>
                        <input
                          type={
                            input === 'dob'
                              ? 'date'
                              : input === 'docLink'
                              ? 'file'
                              : input === 'mobileNumber'
                              ? 'number'
                              : 'text'
                          }
                          className='did-floating-input'
                          {...register(input, {
                            required: `${input} is required`,
                            pattern:
                              input === 'docNumber'
                                ? {
                                    value: new RegExp(field.regexPattern),
                                    message: 'Invalid document number format'
                                  }
                                : undefined
                          })}
                          defaultValue={
                            motorRequest?.customerType ===
                            CUSTOMER_TYPE.INDIVIDUAL
                              ? input === 'firstName'
                                ? motorRequest?.kycData?.fullName
                                  ? motorRequest?.kycData?.fullName
                                  : motorRequest?.middleName
                                  ? `${motorRequest?.firstName} ${motorRequest?.middleName}`
                                  : `${motorRequest?.firstName}`
                                : input === 'lastName'
                                ? `${motorRequest?.lastName}`
                                : input === 'dob'
                                ? motorRequest?.dob
                                : input === 'docNumber'
                                ? motorRequest?.docNumber
                                : null
                              : input === 'firstName'
                              ? motorRequest?.kycData?.fullName
                                ? motorRequest?.kycData?.fullName
                                : motorRequest?.middleName
                                ? `${motorRequest?.firstName} ${motorRequest?.middleName} ${motorRequest?.lastName}`
                                : `${motorRequest?.firstName} ${motorRequest?.lastName}`
                              : input === 'lastName'
                              ? `${motorRequest?.lastName}`
                              : input === 'dob'
                              ? motorRequest?.dob
                              : input === 'docNumber'
                              ? motorRequest?.docNumber
                              : null
                          }
                          maxLength={20}
                          name={input}
                          onChange={handleInputChange}
                        />
                        <label
                          htmlFor={input}
                          className='did-floating-label text-capitalize'
                        >
                          {input === 'docNumber' ? field.label : input}
                          <span className='text-danger'>*</span>
                        </label>
                        <p className='f-error text-danger text-capitalize'>
                          {errors?.[input]?.message}
                        </p>
                      </div>
                    )}
                  </div>
                ))
            )}

          {kycConfig?.kycConfig?.kycStep !== 'in_proposal' &&
            motorRequest?.kycStatus !== 'completed' &&
            kycConfig?.kycConfig?.kycStep !== 'after_proposal' && (
              <div className='col-md-12'>
                <div className='next-btn-btn d-flex gap-5'>
                  <button
                    type='button'
                    className='btn btn-primary mb-5 mt-5 py-3 w-100 fs-2 fw-bold'
                    onClick={() => handlePreviousPage()}
                  >
                    Back to Quotation
                  </button>
                  <button
                    type={
                      selectedPlan?.Api_name === insurers.Digit.name ||
                      selectedPlan?.Api_name === insurers.TATA.name
                        ? 'button'
                        : 'submit'
                    }
                    className='btn btn-primary mb-5 mt-5 py-3 w-100 fs-2 fw-bold'
                    // onClick={() => handleNextStep()}
                  >
                    {proceedSpinner ? (
                      <div
                        class='spinner-border text-light'
                        role='status'
                      ></div>
                    ) : (
                      'Complete KYC'
                    )}
                  </button>
                </div>
              </div>
            )}

          {(kycConfig?.kycConfig?.kycStep === 'in_proposal' ||
            kycConfig?.kycConfig?.kycStep === 'after_proposal' ||
            motorRequest?.kycStatus === 'completed') && (
            <>
              <div className='col-md-6 mb-2'>
                <div className='onwer-details'>
                  <div className='did-floating-label-content'>
                    <select
                      name='maritalStatus'
                      className='did-floating-input'
                      id='maritalStatus'
                      // {...register("maritalStatus", {
                      //   required: "Marital Status is required",
                      // })}
                      onChange={handleMaritalStatus}
                    >
                      <option value=''>Marital Status</option>
                      <option value='married'>Married</option>
                      <option value='unmarried'>Unmarried</option>
                    </select>
                    <label htmlFor='idxx4' className='did-floating-label'>
                      Marital Status
                      <span className='text-danger'>*</span>
                    </label>
                    <p className='f-error text-danger'>
                      {errors?.maritalStatus?.message}
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-6 mb-2'>
                <div className='onwer-details'>
                  <div className='did-floating-label-content'>
                    <input
                      type='text'
                      className=' did-floating-input'
                      placeholder=''
                      {...register('addressLine1', {
                        required: 'Address 1 is required'
                      })}
                      value={address.addressLine1}
                      name='addressLine1'
                      onChange={handleAddressChange}
                      defaultValue={motorRequest?.addressLine1}
                      maxLength={30}

                      //   onChange={(e) => handleSelectAddress1(e.target.value)}
                    />
                    <label htmlFor='idxx4' className='did-floating-label'>
                      Address 1<span className='text-danger'>*</span>
                    </label>
                    <p className='f-error text-danger'>
                      {addressErrors.addressLine1}
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-6 mb-2'>
                <div className='onwer-details'>
                  <div className='did-floating-label-content'>
                    <input
                      type='text'
                      className=' did-floating-input'
                      placeholder=''
                      name='addressLine2'
                      onChange={handleAddressChange}
                      defaultValue={motorRequest?.addressLine2}
                      maxLength={30}
                    />
                    <label htmlFor='idxx4' className='did-floating-label'>
                      Address 2<span className='text-danger'>*</span>
                    </label>
                    <p className='f-error text-danger'>
                      {addressErrors.addressLine2}
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-6 mb-2'>
                <div className='onwer-details'>
                  <div className='did-floating-label-content'>
                    <input
                      type='text'
                      className=' did-floating-input'
                      placeholder=''
                      name='addressLine3'
                      onChange={handleAddressChange}
                      defaultValue={motorRequest?.addressLine3}
                      maxLength={30}
                    />
                    <label htmlFor='idxx4' className='did-floating-label'>
                      Address 3<span className='text-danger'>*</span>
                    </label>
                    <p className='f-error text-danger'>
                      {addressErrors.addressLine3}
                    </p>
                  </div>
                </div>
              </div>

              {/* <div className='col-md-6 mb-2'>
                <div className='onwer-details'>
                  <div className='did-floating-label-content fs-4'>
                    <ReactSelect
                      options={pincodeData}
                      onChange={val => {
                        handleSelectPincode(val)
                      }}
                      placeholder='Select area pincode'
                      className='form-select did-floating-input'
                      defaultValue={motorRequest?.pincode}
                    />
                    <label htmlFor='idxx4' className='did-floating-label'>
                      Select Pincode
                      <span className='text-danger'>*</span>
                    </label>
                    {pincodeError && (
                      <p className='f-error text-danger'>Pincode is required</p>
                    )}
                  </div>
                </div>
              </div> */}
              {kycFields &&
                !kycFields.some(
                  field =>
                    field.requiredField &&
                    field.requiredField.includes('pincode')
                ) && (
                  <div className='col-md-6 mb-2'>
                    <div className='onwer-details'>
                      <div className='did-floating-label-content fs-4'>
                        <ReactSelect
                          options={pincodeData}
                          onChange={val => handleSelectPincode(val)}
                          placeholder='Select area pincode'
                          className='form-select did-floating-input'
                          defaultValue={motorRequest?.pincode}
                        />
                        <label htmlFor='idxx4' className='did-floating-label'>
                          Select Pincode
                          <span className='text-danger'>*</span>
                        </label>
                        {pincodeError && (
                          <p className='f-error text-danger'>
                            Pincode is required
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )}

              <div className='col-md-6 mb-2'>
                <div className='onwer-details'>
                  <div className='did-floating-label-content'>
                    <input
                      type='text'
                      placeholder=''
                      className='did-floating-input'
                      {...register('city', {
                        required: 'City is required'
                      })}
                      disabled
                      defaultValue={motorRequest?.city}
                    />
                    <label
                      htmlFor='idxx4'
                      className='did-floating-label uppercase'
                    >
                      Enter City
                      <span className='text-danger'>*</span>
                    </label>
                    <p className='f-error text-danger'>{addressErrors.city}</p>
                  </div>
                </div>
              </div>
              <div className='col-md-6 mb-2'>
                <div className='onwer-details'>
                  <div className='did-floating-label-content'>
                    <input
                      list='text'
                      placeholder=''
                      name='State'
                      {...register('state', {
                        required: 'State is required'
                      })}
                      defaultValue={motorRequest?.state}
                      disabled
                      className='did-floating-input uppercase'
                    />
                    <label htmlFor='idxx4' className='did-floating-label'>
                      Enter State
                      <span className='text-danger'>*</span>
                    </label>
                    <p className='f-error text-danger'>{addressErrors.state}</p>
                  </div>
                </div>
              </div>
              <div className='col-md-12 d-flex gap-4'>
                <button
                  type='button'
                  className='btn btn-primary mb-5 mt-5 py-3 w-50 fs-2 fw-bold'
                  onClick={() => handlePreviousPage()}
                >
                  Back to Quotation
                </button>
                <div className='next-btn-btn'>
                  <button
                    type='submit'
                    onClick={
                      kycConfig?.kycConfig?.kycStep === 'in_proposal' ||
                      kycConfig?.kycConfig?.kycStep === 'after_proposal'
                        ? null
                        : handleNextClick
                    }
                    className='btn btn-primary mb-5 mt-5 py-3 w-50 fs-2 fw-bold'
                  >
                    Next
                  </button>
                </div>
              </div>
            </>
          )}
        </div>

        <></>
      </div>
      {redirectionModal && (
        <RedirectionModal
          show={redirectionModal}
          setModal={setRedirectionModal}
          url={redirectionUrl}
        />
      )}
    </form>
  )
}

export default KYC
