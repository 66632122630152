import React from "react";
import Layout from "../../common/Layout";

const Faq = () => {
  return (
    <Layout>
      {/*------ Faq Section start -----*/}
      <section className="Faq-section my-5">
        {/*----- Blur set effect -----*/}
        <div className="blur-bg-blocks">
          <aside className="blur-bg-set">
            <div className="blur-bg blur-bg-a" />
            <div className="blur-bg blur-bg-b" />
            <div className="blur-bg blur-bg-c" />
          </aside>
        </div>

        {/*----- container start -----*/}
        <div className="container-lg container-fluid">
          <div className="row justify-content-between">
            <div className="col-lg-8 col-12 text-center mx-auto mt-5 pt-5 pb-lg-5 mb-lg-5">
              <h1 className="text-capitalize about-heading mx-auto">
                Expert Cover FAQ'S
              </h1>
              <p className="my-5 fs-2">
                The basic concept of insurance is that one party, the insurer,
                will guarantee payment for an uncertain future event. Meanwhile,
                another party, the insured or the policyholder, pays a smaller
                premium to the insurer in exchange for that protection on that
                uncertain future occurrence.
              </p>
            </div>
          </div>

          <div className="row">
            {/*--- Left Sidebr (Faq llist) ---*/}
            <div className="col-lg-3 col-12">
              <div className="left-side nav-faqsidebar">
                <div id="faqsidebar" className="hide-side affix-top">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <a
                        href="#auto-insurance"
                        data-bs-toggle="tab"
                        className="nav-link active"
                      >
                        car Insurance Questions
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        href="#home-insurance"
                        data-bs-toggle="tab"
                        className="nav-link"
                      >
                        GCV Insurance Questions
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        href="#claims"
                        data-bs-toggle="tab"
                        className="nav-link"
                      >
                        PCV Insurance Questions
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        href="#policy"
                        data-bs-toggle="tab"
                        className="nav-link"
                      >
                        Bike Insurance Questions
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/*----- Right content box (Faq discription) -----*/}
            <div className="col-lg-9 faq-right-side mt-lg-0 mt-5">
              <div className="tab-content ps-lg-5">
                <div className="tab-pane fade show active" id="auto-insurance">
                  <div className="accordion" id="minecar">
                    {/*----- accordion -----*/}
                    <div className="accordion-item bg-transparent mb-4">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button fs-1 px-lg-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#minecar1"
                        >
                          What is the difference between Collision and
                          Comprehensive coverage for my automobile?
                        </button>
                      </h2>

                      <div
                        id="minecar1"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#minecar"
                      >
                        <div className="accordion-body fs-2 p-lg-5 p-sm-3">
                          Motor insurance content can be different in different
                          countries. generally, it's designed keeping in mind
                          the conditions of the country. It can be grounded on
                          the business, accidents, or in some cases, the value
                          of the insurance( sum insured). There are primarily
                          two main types of vehicle insurance in India — Third-
                          party Liability Plan and Comprehensive Insurance Plan.
                          still, countries similar as the USA have classified
                          the Comprehensive plan further into two types. These
                          are Comprehensive and Collision insurance.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item bg-transparent mb-4">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed fs-1 px-lg-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#minecar2"
                        >
                          What is an endorsement?
                        </button>
                      </h2>

                      <div
                        id="minecar2"
                        className="accordion-collapse collapse"
                        data-bs-parent="#minecar"
                      >
                        <div className="accordion-body fs-2 p-lg-5 p-sm-3">
                          An endorsement is an amendment or special clause to a
                          document or contract, an authorizing signature, or a
                          public declaration of support. Specific types include
                          insurance, signature, and license endorsements.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item bg-transparent mb-4">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed fs-1 px-lg-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#minecar3"
                        >
                          What is a no-claim bonus?
                        </button>
                      </h2>

                      <div
                        id="minecar3"
                        className="accordion-collapse collapse"
                        data-bs-parent="#minecar"
                      >
                        <div className="accordion-body fs-2 p-lg-5 p-sm-3">
                          A no claim bonus is the amount of money that the
                          insurance holder gets if they are not claiming the
                          insurance during the policy terms. This bonus helps
                          the insurance policyholder be stress-free because they
                          get the financial help either way.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item bg-transparent mb-4">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed fs-1 px-lg-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#minecar4"
                        >
                          How can you cancel your car insurance policy during
                          the policy terms?
                        </button>
                      </h2>

                      <div
                        id="minecar4"
                        className="accordion-collapse collapse"
                        data-bs-parent="#minecar"
                      >
                        <div className="accordion-body fs-2 p-lg-5 p-sm-3">
                          First of all, it is possible to cancel your car
                          insurance policy during your policy terms. Moreover,
                          you need to submit the requirements to avail of this
                          feature. You also need to prove to the furnace
                          complaint that you have bought another car insurance
                          policy online and prove the same to the RTO. You need
                          to submit the documents to the insurance providers.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item bg-transparent mb-4">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed fs-1 px-lg-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#minecar5"
                        >
                          Is it possible to get a four-wheeler insurance policy
                          without any inspection?
                        </button>
                      </h2>

                      <div
                        id="minecar5"
                        className="accordion-collapse collapse"
                        data-bs-parent="#minecar"
                      >
                        <div className="accordion-body fs-2 p-lg-5 p-sm-3">
                          You can get a four-wheeler insurance policy online
                          without any inspection. Inspection is not necessary to
                          be safer and more secure while riding a 4 wheeler.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item bg-transparent mb-4">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed fs-1 px-lg-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#minecar6"
                        >
                          How to pay a car insurance policy online?
                        </button>
                      </h2>

                      <div
                        id="minecar6"
                        className="accordion-collapse collapse"
                        data-bs-parent="#minecar"
                      >
                        <div className="accordion-body fs-2 p-lg-5 p-sm-3">
                          Paying for the car insurance policy will take only a
                          few minutes. You can quickly pay your premium online
                          using different gateways like Paytm, net banking, and
                          debit card.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="home-insurance">
                  <div className="accordion" id="minegcv">
                    {/*----- accordion -----*/}
                    <div className="accordion-item bg-transparent mb-4">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button fs-1 px-lg-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#minegcv1"
                        >
                          What is the difference between Collision and
                          Comprehensive coverage for my automobile?
                        </button>
                      </h2>

                      <div
                        id="minegcv1"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#minegcv"
                      >
                        <div className="accordion-body fs-2 p-lg-5 p-sm-3">
                          Motor insurance content can be different in different
                          countries. generally, it's designed keeping in mind
                          the conditions of the country. It can be grounded on
                          the business, accidents, or in some cases, the value
                          of the insurance( sum insured). There are primarily
                          two main types of vehicle insurance in India — Third-
                          party Liability Plan and Comprehensive Insurance Plan.
                          still, countries similar as the USA have classified
                          the Comprehensive plan further into two types. These
                          are Comprehensive and Collision insurance.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item bg-transparent mb-4">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed fs-1 px-lg-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#minegcv2"
                        >
                          What is an endorsement?
                        </button>
                      </h2>

                      <div
                        id="minegcv2"
                        className="accordion-collapse collapse"
                        data-bs-parent="#minegcv"
                      >
                        <div className="accordion-body fs-2 p-lg-5 p-sm-3">
                          An endorsement is an amendment or special clause to a
                          document or contract, an authorizing signature, or a
                          public declaration of support. Specific types include
                          insurance, signature, and license endorsements.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item bg-transparent mb-4">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed fs-1 px-lg-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#minegcv3"
                        >
                          What is a no-claim bonus?
                        </button>
                      </h2>

                      <div
                        id="minegcv3"
                        className="accordion-collapse collapse"
                        data-bs-parent="#minegcv"
                      >
                        <div className="accordion-body fs-2 p-lg-5 p-sm-3">
                          A no claim bonus is the amount of money that the
                          insurance holder gets if they are not claiming the
                          insurance during the policy terms. This bonus helps
                          the insurance policyholder be stress-free because they
                          get the financial help either way.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item bg-transparent mb-4">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed fs-1 px-lg-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#minegcv4"
                        >
                          How can you cancel your GCV insurance policy during
                          the policy terms?
                        </button>
                      </h2>

                      <div
                        id="minegcv4"
                        className="accordion-collapse collapse"
                        data-bs-parent="#minegcv"
                      >
                        <div className="accordion-body fs-2 p-lg-5 p-sm-3">
                          First of all, it is possible to cancel your GCV
                          insurance policy during your policy terms. Moreover,
                          you need to submit the requirements to avail of this
                          feature. You also need to prove to the furnace
                          complaint that you have bought another GCV insurance
                          policy online and prove the same to the RTO. You need
                          to submit the documents to the insurance providers.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item bg-transparent mb-4">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed fs-1 px-lg-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#minegcv5"
                        >
                          Is it possible to get a GCV insurance policy without
                          any inspection?
                        </button>
                      </h2>

                      <div
                        id="minegcv5"
                        className="accordion-collapse collapse"
                        data-bs-parent="#minegcv"
                      >
                        <div className="accordion-body fs-2 p-lg-5 p-sm-3">
                          You can get a GCV insurance policy online without any
                          inspection. Inspection is not necessary to be safer
                          and more secure while riding a GCV.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item bg-transparent mb-4">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed fs-1 px-lg-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#minegcv6"
                        >
                          How to pay a GCV insurance policy online?
                        </button>
                      </h2>

                      <div
                        id="minegcv6"
                        className="accordion-collapse collapse"
                        data-bs-parent="#minegcv"
                      >
                        <div className="accordion-body fs-2 p-lg-5 p-sm-3">
                          Paying for the GCV insurance policy will take only a
                          few minutes. You can quickly pay your premium online
                          using different gateways like Paytm, net banking, and
                          debit card.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="claims">
                  <div className="accordion" id="minepcv">
                    {/*----- accordion -----*/}
                    <div className="accordion-item bg-transparent mb-4">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button fs-1 px-lg-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#minepcv1"
                        >
                          What is the difference between Collision and
                          Comprehensive coverage for my automobile?
                        </button>
                      </h2>

                      <div
                        id="minepcv1"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#minepcv"
                      >
                        <div className="accordion-body fs-2 p-lg-5 p-sm-3">
                          Motor insurance content can be different in different
                          countries. generally, it's designed keeping in mind
                          the conditions of the country. It can be grounded on
                          the business, accidents, or in some cases, the value
                          of the insurance( sum insured). There are primarily
                          two main types of vehicle insurance in India — Third-
                          party Liability Plan and Comprehensive Insurance Plan.
                          still, countries similar as the USA have classified
                          the Comprehensive plan further into two types. These
                          are Comprehensive and Collision insurance.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item bg-transparent mb-4">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed fs-1 px-lg-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#minepcv2"
                        >
                          What is an endorsement?
                        </button>
                      </h2>

                      <div
                        id="minepcv2"
                        className="accordion-collapse collapse"
                        data-bs-parent="#minepcv"
                      >
                        <div className="accordion-body fs-2 p-lg-5 p-sm-3">
                          An endorsement is an amendment or special clause to a
                          document or contract, an authorizing signature, or a
                          public declaration of support. Specific types include
                          insurance, signature, and license endorsements.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item bg-transparent mb-4">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed fs-1 px-lg-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#minepcv3"
                        >
                          What is a no-claim bonus?
                        </button>
                      </h2>

                      <div
                        id="minepcv3"
                        className="accordion-collapse collapse"
                        data-bs-parent="#minepcv"
                      >
                        <div className="accordion-body fs-2 p-lg-5 p-sm-3">
                          A no claim bonus is the amount of money that the
                          insurance holder gets if they are not claiming the
                          insurance during the policy terms. This bonus helps
                          the insurance policyholder be stress-free because they
                          get the financial help either way.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item bg-transparent mb-4">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed fs-1 px-lg-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#minepcv4"
                        >
                          How can you cancel your PCV insurance policy during
                          the policy terms?
                        </button>
                      </h2>

                      <div
                        id="minepcv4"
                        className="accordion-collapse collapse"
                        data-bs-parent="#minepcv"
                      >
                        <div className="accordion-body fs-2 p-lg-5 p-sm-3">
                          First of all, it is possible to cancel your PCV
                          insurance policy during your policy terms.Moreover,
                          you need to submit the requirements to avail of this
                          feature.You also need to prove to the furnace
                          complaint that you have bought another PCV insurance
                          policy online and prove the same to the RTO. You need
                          to submit the documents to the insurance providers.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item bg-transparent mb-4">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed fs-1 px-lg-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#minepcv5"
                        >
                          Is it possible to get a PCV insurance policy without
                          any inspection?
                        </button>
                      </h2>

                      <div
                        id="minepcv5"
                        className="accordion-collapse collapse"
                        data-bs-parent="#minepcv"
                      >
                        <div className="accordion-body fs-2 p-lg-5 p-sm-3">
                          You can get a PCV insurance policy online without any
                          inspection.Inspection is not necessary to be safer and
                          more secure while riding a PCV.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item bg-transparent mb-4">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed fs-1 px-lg-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#minepcv6"
                        >
                          How to pay a PCV insurance policy online?
                        </button>
                      </h2>

                      <div
                        id="minepcv6"
                        className="accordion-collapse collapse"
                        data-bs-parent="#minepcv"
                      >
                        <div className="accordion-body fs-2 p-lg-5 p-sm-3">
                          Paying for the PCV insurance policy will take only a
                          few minutes. You can quickly pay your premium online
                          using different gateways like Paytm, net banking, and
                          debit card.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="policy">
                  <div className="accordion" id="vehicleAccordion">
                    {/*----- accordion -----*/}
                    <div className="accordion-item bg-transparent mb-4">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button fs-1 px-lg-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#vehicleAccordion1"
                        >
                          What is the difference between Collision and
                          Comprehensive coverage for my automobile?
                        </button>
                      </h2>

                      <div
                        id="vehicleAccordion1"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#vehicleAccordion"
                      >
                        <div className="accordion-body fs-2 p-lg-5 p-sm-3">
                          Motor insurance content can be different in different
                          countries. generally, it's designed keeping in mind
                          the conditions of the country. It can be grounded on
                          the business, accidents, or in some cases, the value
                          of the insurance( sum insured). There are primarily
                          two main types of vehicle insurance in India — Third-
                          party Liability Plan and Comprehensive Insurance Plan.
                          still, countries similar as the USA have classified
                          the Comprehensive plan further into two types. These
                          are Comprehensive and Collision insurance.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item bg-transparent mb-4">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed fs-1 px-lg-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#vehicleAccordion2"
                        >
                          What is an endorsement?
                        </button>
                      </h2>

                      <div
                        id="vehicleAccordion2"
                        className="accordion-collapse collapse"
                        data-bs-parent="#vehicleAccordion"
                      >
                        <div className="accordion-body fs-2 p-lg-5 p-sm-3">
                          An endorsement is an amendment or special clause to a
                          document or contract, an authorizing signature, or a
                          public declaration of support. Specific types include
                          insurance, signature, and license endorsements.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item bg-transparent mb-4">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed fs-1 px-lg-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#vehicleAccordion3"
                        >
                          What is a no-claim bonus?
                        </button>
                      </h2>

                      <div
                        id="vehicleAccordion3"
                        className="accordion-collapse collapse"
                        data-bs-parent="#vehicleAccordion"
                      >
                        <div className="accordion-body fs-2 p-lg-5 p-sm-3">
                          A no claim bonus is the amount of money that the
                          insurance holder gets if they are not claiming the
                          insurance during the policy terms. This bonus helps
                          the insurance policyholder be stress-free because they
                          get the financial help either way.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item bg-transparent mb-4">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed fs-1 px-lg-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#vehicleAccordion4"
                        >
                          How can you cancel your bike insurance policy during
                          the policy terms?
                        </button>
                      </h2>

                      <div
                        id="vehicleAccordion4"
                        className="accordion-collapse collapse"
                        data-bs-parent="#vehicleAccordion"
                      >
                        <div className="accordion-body fs-2 p-lg-5 p-sm-3">
                          First of all, it is possible to cancel your bike
                          insurance policy during your policy terms.Moreover,
                          you need to submit the requirements to avail of this
                          feature.You also need to prove to the furnace
                          complaint that you have bought another bike insurance
                          policy online and prove the same to the RTO. You need
                          to submit the documents to the insurance providers.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item bg-transparent mb-4">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed fs-1 px-lg-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#vehicleAccordion5"
                        >
                          Is it possible to get a bike insurance policy without
                          any inspection?
                        </button>
                      </h2>

                      <div
                        id="vehicleAccordion5"
                        className="accordion-collapse collapse"
                        data-bs-parent="#vehicleAccordion"
                      >
                        <div className="accordion-body fs-2 p-lg-5 p-sm-3">
                          You can get a bike insurance policy online without any
                          inspection.Inspection is not necessary to be safer and
                          more secure while riding a bike.
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item bg-transparent mb-4">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed fs-1 px-lg-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#vehicleAccordion6"
                        >
                          How to pay a bike insurance policy online?
                        </button>
                      </h2>

                      <div
                        id="vehicleAccordion6"
                        className="accordion-collapse collapse"
                        data-bs-parent="#vehicleAccordion"
                      >
                        <div className="accordion-body fs-2 p-lg-5 p-sm-3">
                          Paying for the bike insurance policy will take only a
                          few minutes. You can quickly pay your premium online
                          using different gateways like Paytm, net banking, and
                          debit card.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*----- container End -----*/}
      </section>
      {/*------ Faq Section End -----*/}
    </Layout>
  );
};

export default Faq;
