import React from "react";
import { NavLink } from "react-router-dom";
import Layout from "../../../common/Layout";

const InsuranceProcess1 = () => {
  return (
    <Layout>
      <div className="main-content-area">
        <section
          className="page-title layer-overlay overlay-theme-colored4-9 section-typo-light bg-img-center"
          data-tm-bg-img="images/bg/bg1.jpg"
        >
          <div className="container pt-50 pb-50">
            <div className="section-content">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h2 className="title">Make your policy</h2>
                  <nav className="breadcrumbs" role="navigation" aria-label="Breadcrumbs">
                    <div className="breadcrumbs">
                      <span>
                        <a href="index.html" rel="home">
                          Home
                        </a>
                      </span>
                      <span>
                        <i className="fa fa-angle-right" />
                      </span>
                      <span>
                        <a href="#">Creat policy</a>
                      </span>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Section: Service details */}
        <section>
          <div className="container pb-70">
            <div className="section-content">
              <div className="row justify-content-between">
                <div className="col-lg-3">
                  <div className="sidebar">
                    <div className="tm-sidebar-nav-menu-style2">
                      <div className="widget widget_nav_menu">
                        <ul className="nav nav-tabs d-block">
                          <li className="nav-item">
                            <a href="#car" data-toggle="tab" className=" active">
                              Car Insurance
                            </a>
                          </li>
                          <li className="nav-item">
                            <a href="#bike" data-toggle="tab">
                              bike Insurance
                            </a>
                          </li>
                          <li className="nav-item">
                            <a href="#gcv" data-toggle="tab">
                              GCV insaurance
                            </a>
                          </li>
                          <li className="nav-item">
                            <a href="#pcv" data-toggle="tab">
                              PCV insaurance
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="tab-content position-relative pt-5 pb-0" id="myTabContent">
                    <div className="tab-pane fade row-ribbon-1 pos show active" id="car">
                      <form action="#">
                        <div className="form-group position-relative mb-4">
                          <label htmlFor="Insurancetype" className="form-label">
                            Select your policy Status :-
                          </label>
                          <select className="custom-select" id="Insurancetype">
                            <option className="d-none" selected>
                              Policy status
                            </option>
                            <option>New policy</option>
                            <option>Renew on policy</option>
                            <option>ABC Renewal</option>
                            <option>Already quoted</option>
                          </select>
                        </div>
                        <div className="form-group position-relative mb-4">
                          <label htmlFor="Insurancetype" className="form-label">
                            Select previous Insurance provider :-
                          </label>
                          <select className="custom-select" id="Insurancetype">
                            <option className="d-none" selected>
                              Previous insurer
                            </option>
                            <option>Raja Ram</option>
                            <option>Ashok Finance LTD.</option>
                            <option>AU Small finence bank</option>
                            <option>ICICI Lambord</option>
                          </select>
                        </div>
                        <div className="form-group position-relative mb-4">
                          <label htmlFor="vechileno" className="form-label">
                            Enter old policy registration
                          </label>
                          {/*----- Input warning -----*/}
                          <p className="m-0 f-error">(Input not valid)</p>
                          <input
                            type="text"
                            className="form-control"
                            id="vechileno"
                            placeholder="Policy Registration code"
                          />
                        </div>
                        <div className="form-group position-relative mb-4">
                          <label htmlFor="vechileno" className="form-label">
                            Enter RC Registration code
                          </label>
                          {/*----- Input warning -----*/}
                          <p className="m-0 f-error">(Input not valid)</p>
                          <input
                            type="text"
                            className="form-control"
                            id="vechileno"
                            placeholder="Registration code (e.g.UK-07-ES-3527)"
                          />
                        </div>
                        <div className="form-group position-relative mb-4">
                          <label htmlFor="vechileno" className="form-label">
                            Enter quotation code
                          </label>
                          {/*----- Input warning -----*/}
                          <p className="m-0 f-error">(Input not valid)</p>
                          <input
                            type="text"
                            className="form-control"
                            id="vechileno"
                            placeholder="Insert Quotation code"
                          />
                        </div>
                        <div className="form-group position-relative mt-4">
                          <NavLink
                            to="/insurance-mmv"
                            className="btn btn-theme-colored1 btn-xs btn-round mt-2 text-white w-auto"
                          >
                            Get your policy
                          </NavLink>
                          <br />
                          <NavLink to="/insurance-mmv" className="mt-2 d-inline-block text-decoration-underline">
                            Don't have RC Registration?
                          </NavLink>
                        </div>
                      </form>
                    </div>
                    <div className="tab-pane fade  row-ribbon-2 pt-4" id="bike">
                      <form action="#">
                        <div className="form-group position-relative mb-4">
                          <label htmlFor="Insurancetype" className="form-label">
                            Select your policy Status :-
                          </label>
                          <select className="custom-select" id="Insurancetype">
                            <option className="d-none" selected>
                              Policy status
                            </option>
                            <option>New policy</option>
                            <option>Renew on policy</option>
                            <option>Already quoted</option>
                          </select>
                        </div>
                        <div className="form-group position-relative mb-4">
                          <label htmlFor="vechileno" className="form-label">
                            Enter RC Registration code
                          </label>
                          {/*----- Input warning -----*/}
                          <p className="m-0 f-error">(Input not valid)</p>
                          <input
                            type="text"
                            className="form-control"
                            id="vechileno"
                            placeholder="Registration code (e.g.UK-07-ES-3527)"
                          />
                        </div>
                        <div className="form-group position-relative mb-4">
                          <label htmlFor="vechileno" className="form-label">
                            Enter quotation code
                          </label>
                          {/*----- Input warning -----*/}
                          <p className="m-0 f-error">(Input not valid)</p>
                          <input
                            type="text"
                            className="form-control"
                            id="vechileno"
                            placeholder="Insert Quotation code"
                          />
                        </div>
                        <div className="form-group position-relative mt-4">
                          <NavLink
                            to="/insurance-mmv"
                            className="btn btn-theme-colored1 btn-xs btn-round mt-2 text-white w-auto"
                          >
                            Get your policy
                          </NavLink>
                          <br />
                          <NavLink to="/insurance-mmv" className="mt-2 d-inline-block text-decoration-underline">
                            Don't have RC Registration?
                          </NavLink>
                        </div>
                      </form>
                    </div>
                    <div className="tab-pane fade  row-ribbon-3 pt-4" id="gcv">
                      <form action="#">
                        <div className="form-group position-relative mb-4">
                          <label htmlFor="Insurancetype" className="form-label">
                            Select your policy Status :-
                          </label>
                          <select className="custom-select" id="Insurancetype">
                            <option className="d-none" selected>
                              Policy status
                            </option>
                            <option>New policy</option>
                            <option>Renew on policy</option>
                            <option>Already quoted</option>
                          </select>
                        </div>
                        <div className="form-group position-relative mb-4">
                          <label htmlFor="vechileno" className="form-label">
                            Enter RC Registration code
                          </label>
                          {/*----- Input warning -----*/}
                          <p className="m-0 f-error">(Input not valid)</p>
                          <input
                            type="text"
                            className="form-control"
                            id="vechileno"
                            placeholder="Registration code (e.g.UK-07-ES-3527)"
                          />
                        </div>
                        <div className="form-group position-relative mb-4">
                          <label htmlFor="vechileno" className="form-label">
                            Enter quotation code
                          </label>
                          {/*----- Input warning -----*/}
                          <p className="m-0 f-error">(Input not valid)</p>
                          <input
                            type="text"
                            className="form-control"
                            id="vechileno"
                            placeholder="Insert Quotation code"
                          />
                        </div>
                        <div className="form-group position-relative mt-4">
                          <NavLink
                            to="/insurance-mmv"
                            className="btn btn-theme-colored1 btn-xs btn-round mt-2 text-white w-auto"
                          >
                            Get your policy
                          </NavLink>
                          <br />
                          <NavLink to="/insurance-mmv" className="mt-2 d-inline-block text-decoration-underline">
                            Don't have RC Registration?
                          </NavLink>
                        </div>
                      </form>
                    </div>
                    <div className="tab-pane fade  row-ribbon-4 pt-4" id="pcv">
                      <form action="#">
                        <div className="form-group position-relative mb-4">
                          <label htmlFor="Insurancetype" className="form-label">
                            Select your policy Status :-
                          </label>
                          <select className="custom-select" id="Insurancetype">
                            <option className="d-none" selected>
                              Policy status
                            </option>
                            <option>New policy</option>
                            <option>Renew on policy</option>
                            <option>Already quoted</option>
                          </select>
                        </div>
                        <div className="form-group position-relative mb-4">
                          <label htmlFor="vechileno" className="form-label">
                            Enter RC Registration code
                          </label>
                          {/*----- Input warning -----*/}
                          <p className="m-0 f-error">(Input not valid)</p>
                          <input
                            type="text"
                            className="form-control"
                            id="vechileno"
                            placeholder="Registration code (e.g.UK-07-ES-3527)"
                          />
                        </div>
                        <div className="form-group position-relative mb-4">
                          <label htmlFor="vechileno" className="form-label">
                            Enter quotation code
                          </label>
                          {/*----- Input warning -----*/}
                          <p className="m-0 f-error">(Input not valid)</p>
                          <input
                            type="text"
                            className="form-control"
                            id="vechileno"
                            placeholder="Insert Quotation code"
                          />
                        </div>
                        <div className="form-group position-relative mt-4">
                          <NavLink
                            to="/insurance-mmv"
                            className="btn btn-theme-colored1 btn-xs btn-round mt-2 text-white w-auto"
                          >
                            Get your policy
                          </NavLink>
                          <br />
                          <NavLink to="/insurance-mmv" className="mt-2 d-inline-block text-decoration-underline">
                            Don't have RC Registration?
                          </NavLink>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default InsuranceProcess1;
