import React from "react";
import "./footerpages.css";
import Header from "../Header";
import Footer from "../Footer";

function InvestmentNriPlan() {
  return (
    <>
      <Header />
      <div>
        <div className="insurance-cantainer">
          <h1>Investment Plan For NRIs</h1>
        </div>
        <div className="cantainer">
          <div className="box-info">
            <div className="box-1">
              <h2>
                "Tailored Investment Strategies for Non-Resident Indians (NRIs):
                Maximizing Returns Abroad"
              </h2>
              <p>
                Creating a suitable investment plan for Non-Resident Indians
                (NRIs) in the realm of insurance involves a strategic approach
                to ensure financial security and growth. NRIs can opt for
                various insurance products that cater to their unique
                circumstances and goals. Term Life Insurance: This provides a
                straightforward life coverage for a specified term. NRIs should
                consider their family's financial needs and potential
                liabilities when choosing the coverage amount and term. Health
                Insurance: NRI families should secure comprehensive health
                insurance to cover medical expenses for themselves and their
                families during visits to India or in their country of
                residence. This can include coverage for critical illnesses and
                emergencies.
              </p>
              <p>
                Endowment Policies: Endowment plans are a mix of insurance and
                savings. They offer a lump sum amount after a specified period
                or upon the policyholder's demise. NRIs can utilize this for
                long-term financial planning. Annuity Plans: NRIs looking for a
                regular income post-retirement can consider annuity plans, where
                a lump sum amount is converted into a series of payments over a
                defined period. NRI Pension Plans: These are long-term plans
                that enable NRIs to build a retirement corpus by making regular
                contributions during their earning years. The accumulated amount
                is then paid out as a pension post-retirement.
              </p>
            </div>
            <div className="box-2 Nri-investment">
              <img src="./assetss/images/Nri1.png" alt="Erroe" />
            </div>
          </div>
        </div>
        <div className="vision-info">
          <div className="vision-box">
            <div className="img-box">
              <img src="./assetss/images/vision.png" alt="Error" />
            </div>
            <div className="vision-box1">
              <h2>Our vision</h2>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend to repeat predefined chunks as necessary,
              </p>
            </div>
          </div>
        </div>
        <div className="btn-cantainer">
          <div className="btn-1">
            <h1>Solving your Insurance Needs on Time</h1>
          </div>
          <div className="btn-1">
            <h2>
              Get world-class insurance coverage that not only protects you but
              also helps you build wealth. Let us be your insurance partner.
            </h2>
            <div className="btn-info">
              <button>Get Started</button>
              <button>Contact Us</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default InvestmentNriPlan;
