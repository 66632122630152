import React from "react";
import "./footerpages.css";
import Header from "../Header";
import Footer from "../Footer";

function SpouseTermPlan() {
  return (
    <>
      <Header />
      <div>
        <div className="insurance-cantainer">
          <h1>Spouse Term Plan</h1>
        </div>
        <div className="cantainer">
          <div className="box-info">
            <div className="box-1">
              <h2>
                "Securing Futures Together: Spouse Term Insurance Plans for
                Financial Protection"
              </h2>
              <p>
                A spouse term insurance plan is a financial product designed to
                provide essential protection and financial security for your
                partner in the unfortunate event of your demise. It's a form of
                life insurance where you, as the policyholder, pay a regular
                premium for a specified term. In case of your untimely passing
                during this term, the policy provides a predetermined sum
                assured to your spouse or chosen beneficiary. This type of
                insurance is crucial to ensure that your spouse has a financial
                safety net to cover outstanding debts, daily expenses, mortgage
                payments, children's education, and other living costs. It eases
                the burden during an emotionally and financially challenging
                time, allowing your loved ones to cope and maintain their
                standard of living.
              </p>
              <p>
                When considering a spouse term insurance plan, it's essential to
                assess the coverage amount carefully. Factors like your spouse's
                lifestyle, financial needs, outstanding loans, and future
                obligations should be taken into account to determine the
                appropriate coverage. By investing in a spouse term insurance
                plan, you are not only providing financial security for your
                partner but also fostering peace of mind, knowing that your
                loved ones will have the necessary resources to move forward and
                achieve their life goals, even in your absence.
              </p>
            </div>
            <div className="box-2 spouse-insurance">
              <img src="./assetss/images/spouse.jpg" alt="Erroe" />
            </div>
          </div>
        </div>
        <div className="vision-info">
          <div className="vision-box">
            <div className="img-box">
              <img src="./assetss/images/vision.png" alt="Error" />
            </div>
            <div className="vision-box1">
              <h2>Our vision</h2>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend to repeat predefined chunks as necessary,
              </p>
            </div>
          </div>
        </div>
        <div className="btn-cantainer">
          <div className="btn-1">
            <h1>Solving your Insurance Needs on Time</h1>
          </div>
          <div className="btn-1">
            <h2>
              Get world-class insurance coverage that not only protects you but
              also helps you build wealth. Let us be your insurance partner.
            </h2>
            <div className="btn-info">
              <button>Get Started</button>
              <button>Contact Us</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SpouseTermPlan;
