import React from "react";
import Layout from "../common/Layout";

const NewLogin = () => {
  return (
    <Layout>
      {/*----- Login section start -----*/}
      <section className="mylogin mt-5 py-5">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-5 col-12">
              <img src="./assets/images/g8.png" className="w-100" alt />
            </div>

            <div className="col-lg-5 ms-auto">
              <nav className="detilsNav">
                <div className="nav nav-tabs border-0 justify-content-center" id="nav-tab">
                  <button
                    className="nav-link border-0 rounded-0 active"
                    data-bs-toggle="tab"
                    data-bs-target="#gcvinsuretab"
                    type="button"
                  >
                    <i className="fas fa-user-plus me-3" />
                    PosP
                  </button>

                  <button
                    className="nav-link rounded-0 border-0"
                    data-bs-toggle="tab"
                    data-bs-target="#bikeinsuretab"
                    type="button"
                  >
                    <i className="fas fa-users me-3" />
                    Customer
                  </button>

                  <button
                    className="nav-link rounded-0 border-0"
                    data-bs-toggle="tab"
                    data-bs-target="#carinsuretab"
                    type="button"
                  >
                    <i className="fas fa-chalkboard-teacher me-3" />
                    Employee
                  </button>
                </div>
              </nav>

              <div className="tab-content mt-5">
                <div className="tab-pane fade show active" id="gcvinsuretab">
                  <div className="card shadow-lg border-0">
                    <div className="card-body p-5">
                      <div className="form-floating mb-5">
                        <input type="email" className="form-control" id="Email" placeholder="Enter Email" />
                        <label htmlFor="Email">Enter Email</label>
                      </div>
                      <div className="form-floating mb-5">
                        <input type="password" className="form-control" id="PSsword" placeholder="Enter Password" />
                        <label htmlFor="PSsword">Enter Password</label>
                      </div>

                      <a href="#" className="btn btn-primary w-100 py-3 fs-2">
                        Login
                      </a>
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="bikeinsuretab">
                  <div className="card shadow-lg border-0">
                    <div className="card-body p-5">
                      <div className="form-floating mb-5">
                        <input type="email" className="form-control" id="Email" placeholder="Enter Email" />
                        <label htmlFor="Email">Enter Email</label>
                      </div>

                      <div className="form-floating mb-5">
                        <input type="password" className="form-control" id="PSsword" placeholder="Enter Password" />
                        <label htmlFor="PSsword">Enter Password</label>
                      </div>

                      <a href="#" className="btn btn-primary w-100 py-3 fs-2">
                        Login
                      </a>
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="carinsuretab">
                  <div className="card shadow-lg border-0">
                    <div className="card-body p-5">
                      <div className="form-floating mb-5">
                        <input type="email" className="form-control" id="Email" placeholder="Enter Email" />
                        <label htmlFor="Email">Enter Email</label>
                      </div>

                      <div className="form-floating mb-5">
                        <input type="password" className="form-control" id="PSsword" placeholder="Enter Password" />
                        <label htmlFor="PSsword">Enter Password</label>
                      </div>

                      <a href="#" className="btn btn-primary w-100 py-3 fs-2">
                        Login
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*----- Login section End -----*/}
    </Layout>
  );
};

export default NewLogin;
