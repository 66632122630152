import React from "react";

const WhyExpertCoverBuy = () => {
  return (
    <div className="buyexpertcover">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="buyexpheading">
              Why buy From <span>Expert Cover?</span>
            </h2>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="buyexpbox">
              <img
                src={"/assetss/images/platinumicon.png"}
                alt="img"
                className="img-fluid"
              />
              <h2>Trusted Insurance Partner</h2>
              <p>
                We have a long-standing reputation as a trusted insurance
                partner. Our commitment to quality and reliability ensures peace
                of mind for our clients.
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="buyexpbox">
              <img
                src={"/assetss/images/affordableicon.png"}
                alt="img"
                className="img-fluid"
              />
              <h2>Affordable Coverage Options</h2>
              <p>
                We offer a range of affordable coverage options tailored to your
                needs. You can protect what matters most without breaking the
                bank.
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="buyexpbox">
              <img
                src={"/assetss/images/supporticon.png"}
                alt="img"
                className="img-fluid"
              />
              <h2>Rapid Claim Support</h2>
              <p>
                Our dedicated support team ensures fast and efficient claims
                processing. We're here to assist you every step of the way
                during your claim process.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyExpertCoverBuy;
