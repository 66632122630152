import React, { useState } from "react";
import { FaRegCopy } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const CopyLinkComponent = ({ link }) => {
  const [hovered, setHovered] = useState(false);
  const [tooltipText, setTooltipText] = useState("Copy link to clipboard");

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(link);
      setTooltipText("Copied!");
      setTimeout(() => setTooltipText("Copy link to clipboard"), 5000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={copyToClipboard}
      style={{
        cursor: "pointer",
        display: "inline-block",
        position: "relative",
      }}
    >
      {hovered && (
        <Tooltip
          id="copy-tooltip"
          effect="solid"
          place="right"
          getContent={() => tooltipText}
          overridePosition={({ left, top }) => ({ top, left })}
          className="custom-tooltip"
        >
          <span>{tooltipText}</span>
        </Tooltip>
      )}
      <FaRegCopy className="mx-1" data-tooltip-id="copy-tooltip" />
    </div>
  );
};

export default CopyLinkComponent;
