import React from "react";

const MyQuote = () => {
  function showdown() {
    document.getElementById("idvmeasure").classList.toggle("idvcustomshow");
  }

  function showdown1() {
    document.getElementById("addcover").classList.toggle("idvcustomshow");
  }

  function showdown2() {
    document.getElementById("addon").classList.toggle("idvcustomshow");
  }

  return (
    <>
      {/*----- Main Container Start -----*/}
      <div className="main-container">
        <header>
          {/*----- Top header start -----*/}
          <div className="header-top">
            <div className="container">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-3 col-12">
                  {/*----- Website Logo -----*/}
                  <a className="navbar-brand d-block" href="index.html">
                    <img src="./assets/images/logo.png" alt className="w-100" />
                  </a>
                </div>
                {/*----- Website information container -----*/}
                <div className="col-md-8 col-12 cta-box text-end">
                  {/*----- Search Button -----*/}
                  <a href="index.html" className="btn btn-outline-light py-3 px-4 bg-white text-dark">
                    <i className="fa fa-arrow-left" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/*----- Top header End -----*/}
        </header>
        {/*----- Quote List container start -----*/}
        <section className="quotelist my-5">
          <div className="container-fluid px-lg-5 px-3">
            <div className="Vehicleshortdisc card bg-light px-3 py-4">
              <div className="row mx-0 px-0 justify-content-between">
                <div className="col-8 d-flex justify-content-between">
                  <div>
                    <p className="mb-0 fs-4 fw-bold text-uppercase">Maruti alto 800 lxi airbag (796 CC) - Petrol</p>
                    <p className="my-3 fs-4">Private Car - 2019 - HR-26 Gurgaon</p>
                    <p className="mb-0 fs-4 text-uppercase">
                      <strong>Quotation Number</strong> - PIBLMTRPC292202943432
                    </p>
                  </div>
                  <div>
                    <p className="mb-0 fs-4 text-uppercase">
                      <strong className="text-capitalize">Previous Insurer</strong> - ACKO
                    </p>
                    <p className="my-3 fs-4 text-uppercase">
                      <strong className="text-capitalize">Previous Policy Type</strong> - Comprehensive
                    </p>
                    <p className="mb-0 fs-4 text-uppercase">
                      <strong className="text-capitalize">Policy Expiry Date</strong> - ACKO
                    </p>
                  </div>
                  <div>
                    <p className="mb-0 fs-4 text-uppercase">
                      <strong className="text-capitalize">Manufacture Date</strong> - 01-Sept-2011
                    </p>
                    <p className="my-3 fs-4 text-uppercase">
                      <strong className="text-capitalize">Registration Date</strong> - 01-Sept-2011
                    </p>
                  </div>
                </div>
                <div className="col-4 text-end">
                  <a href="#editmmvmodal" data-bs-toggle="modal" className="btn btn-outline-primary fs-3 px-4 py-2">
                    Edit Details
                  </a>
                </div>
              </div>
            </div>
            <div className="vehiclefilter card bg-light px-3 py-4 my-5">
              <div className="row mx-0">
                <div className="col-lg-3">
                  <div className="radiofilter">
                    <input type="radio" className="d-none" id="myradio1" name="ptyepe" defaultChecked />
                    <label htmlFor="myradio1">Comprehensive</label>
                    <input type="radio" id="myradio2" className="d-none" name="ptyepe" />
                    <label htmlFor="myradio2">Third Party</label>
                    <input type="radio" id="myradio3" className="d-none" name="ptyepe" />
                    <label htmlFor="myradio3">OD Only</label>
                  </div>
                </div>
                <div className="col-lg-2 position-relative">
                  <div className="radiofilter position-relative" id="radiofilter123" onClick={showdown}>
                    <p className="mb-2 py-1 fs-4">
                      IDV of Car - <span>Best Value</span>
                      <i className="fa fa-caret-down fs-3 align-middle mb-1 ms-3" />
                    </p>
                  </div>
                  <div id="idvmeasure" className="idvcustom shadow">
                    <div className="form-check mb-3 ps-0">
                      <input className="form-check-input mx-3" type="radio" id="aacche" name="njgidv" />
                      <label className="form-check-label fs-4" htmlFor="aacche">
                        Best Value
                      </label>
                    </div>
                    <div className="form-check ps-0">
                      <input className="form-check-input mx-3" type="radio" id="aacche12" name="njgidv" />
                      <label className="form-check-label fs-4" htmlFor="aacche12">
                        Choose Your Own IDV
                      </label>
                      <div className="mycustomidv">
                        <div className="form-floating mt-3">
                          <input type="number" className="form-control" placeholder=" " />
                          <label htmlFor="floatingSelect">Select IDV Value</label>
                        </div>
                        <div className="d-flex justify-content-between align-items-center my-2">
                          <small>Min - 1140</small>
                          <small>Max - 28880</small>
                        </div>
                        <a href="#" className="btn btn-primary fs-3 px-3 py-2 mt-3">
                          Update
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 position-relative">
                  <div className="radiofilter position-relative" onClick={showdown1}>
                    <p className="mb-2 py-1 fs-4">
                      Additional Covers
                      <i className="fa fa-caret-down fs-3 align-middle mb-1 ms-3" />
                    </p>
                  </div>
                  <div id="addcover" className="idvcustom shadow">
                    <div className="form-check mb-3">
                      <input className="form-check-input" type="checkbox" id="adcover" />
                      <label className="form-check-label fs-4" htmlFor="adcover">
                        Zero Depth
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input className="form-check-input" type="checkbox" id="adcover1" />
                      <label className="form-check-label fs-4" htmlFor="adcover1">
                        Road Side Assistance
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input className="form-check-input" type="checkbox" id="adcover2" />
                      <label className="form-check-label fs-4" htmlFor="adcover2">
                        Invoice Cover
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input className="form-check-input" type="checkbox" id="adcover3" />
                      <label className="form-check-label fs-4" htmlFor="adcover3">
                        Tyre Secure
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input className="form-check-input" type="checkbox" id="adcover4" />
                      <label className="form-check-label fs-4" htmlFor="adcover4">
                        Driver Cover
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input className="form-check-input" type="checkbox" id="adcover5" />
                      <label className="form-check-label fs-4" htmlFor="adcover5">
                        Personal Accidental Cover
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input className="form-check-input" type="checkbox" id="adcover6" />
                      <label className="form-check-label fs-4" htmlFor="adcover6">
                        Invoice Cover
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 position-relative">
                  <div className="radiofilter position-relative" onClick={showdown2}>
                    <p className="mb-2 py-1 fs-4">
                      Addons
                      <i className="fa fa-caret-down fs-3 align-middle mb-1 ms-3" />
                    </p>
                  </div>
                  <div id="addon" className="idvcustom shadow">
                    <div className="form-check mb-3">
                      <input className="form-check-input" type="checkbox" id="adon" />
                      <label className="form-check-label fs-4" htmlFor="adon">
                        Electrical Accessories
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input className="form-check-input" type="checkbox" id="adon1" />
                      <label className="form-check-label fs-4" htmlFor="adon1">
                        AAI Membership
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input className="form-check-input" type="checkbox" id="adon2" />
                      <label className="form-check-label fs-4" htmlFor="adon2">
                        Anti Theft Device
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input className="form-check-input" type="checkbox" id="adon3" />
                      <label className="form-check-label fs-4" htmlFor="adon3">
                        Non Electrical Accessories
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input className="form-check-input" type="checkbox" id="adon5" />
                      <label className="form-check-label fs-4" htmlFor="adon5">
                        TPPD
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="qlist">
              <div className="row mx-0 px-0 align-items-center">
                <div className="col-lg-4 col-12">
                  <div className="forsticky">
                    <div className="card p-0 mt-3  bg-transparent border-0">
                      <div className="card-body p-0">
                        <div className="row mx-0 justify-content-between align-items-center px-3 bg-primary-light">
                          <div className="col-lg-4 ps-lg-0">
                            <img src="./assets/images/digitinsur.png" className="Iscomimg" alt />
                          </div>
                          <div className="col-lg-8 pe-lg-0">
                            <div className="text-end">
                              <p className="fw-bold mb-0 fs-3">
                                IDV
                                <span className="fw-normal"> 3,29,234</span>
                              </p>
                              <p className="mb-0 fs-3 fw-light">( 3,29,234 -  3,29,234 IDV Range)</p>
                            </div>
                          </div>
                        </div>
                        <div className="buybtn text-center d-flex justify-content-between px-3 pt-3 border border-3 border-bottom-0">
                          <div className>
                            <small className="d-block fs-5 fw-bold mt-1">(For 1 year)</small>
                            <a href="#" className="btn btn-primary fs-3 px-4 py-2 my-2">
                              1,234
                            </a>
                            <a href="#" className="d-block fs-4 text-dark text-decoration-underline">
                              Premium Breakup
                            </a>
                          </div>
                          <div>
                            <small className="d-block fs-5 fw-bold mt-1">(For 3 year)</small>
                            <a href="#" className="btn btn-primary fs-3 px-4 py-2 my-2 disabled">
                              N/A
                            </a>
                            <a href="#" className="d-block fs-4 text-dark text-decoration-underline d-none">
                              Premium Breakup
                            </a>
                          </div>
                          <div>
                            <small className="d-block fs-5 fw-bold mt-1">(For 5 year)</small>
                            <a href="#" className="btn btn-primary fs-3 px-4 py-2 my-2 disabled">
                              N/A
                            </a>
                            <a href="#" className="d-block fs-4 text-dark text-decoration-underline d-none">
                              Premium Breakup
                            </a>
                          </div>
                        </div>
                        <div className="adons fs-4 pt-4 px-3 border border-3 border-top-0">
                          <ul className="p-0 row mx-0">
                            <li className="col-lg-6 ps-lg-0 mb-2">
                              Zero Depreciation Cover
                              <span className="text-danger">
                                (<i className="fa fa-times" />)
                              </span>
                            </li>
                            <li className="col-lg-6 pe-lg-0 mb-2">
                              Key Replacement ( 105)
                              <span className="text-danger">
                                (<i className="fa fa-times" />)
                              </span>
                            </li>
                            <li className="col-lg-6 ps-lg-0 mb-2">
                              Engine Gear Box Cover
                              <span className="text-danger">
                                (<i className="fa fa-times" />)
                              </span>
                            </li>
                            <li className="col-lg-6 pe-lg-0 mb-2">
                              Zero Depreciation Cover
                              <span className="text-danger">
                                (<i className="fa fa-times" />)
                              </span>
                            </li>
                            <li className="col-lg-6 ps-lg-0 mb-2">
                              Key Replacement ( 105)
                              <span className="text-danger">
                                (<i className="fa fa-times" />)
                              </span>
                            </li>
                            <li className="col-lg-6 pe-lg-0 mb-2">
                              Engine Gear Box Cover
                              <span className="text-danger">
                                (<i className="fa fa-times" />)
                              </span>
                            </li>
                            <li className="col-lg-6 ps-lg-0 mb-2">
                              Zero Depreciation Cover
                              <span className="text-success">
                                (<i className="fa fa-check" />)
                              </span>
                            </li>
                            <li className="col-lg-6 pe-lg-0 mb-2">
                              Key Replacement ( 105)
                              <span className="text-success">
                                (<i className="fa fa-check" />)
                              </span>
                            </li>
                            <li className="col-lg-6 ps-lg-0 mb-2">
                              Engine Gear Box Cover
                              <span className="text-success">
                                (<i className="fa fa-check" />)
                              </span>
                            </li>
                            <li className="col-lg-6 pe-lg-0 mb-2">
                              Zero Depreciation Cover
                              <span className="text-success">
                                (<i className="fa fa-check" />)
                              </span>
                            </li>
                            <li className="col-lg-6 ps-lg-0 mb-2">
                              Key Replacement ( 105)
                              <span className="text-success">
                                (<i className="fa fa-check" />)
                              </span>
                            </li>
                            <li className="col-lg-6 pe-lg-0 mb-2">
                              Engine Gear Box Cover
                              <span className="text-success">
                                (<i className="fa fa-check" />)
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="forsticky">
                    <div className="card p-0 mt-3  bg-transparent border-0">
                      <div className="card-body p-0">
                        <div className="row mx-0 justify-content-between align-items-center px-3 bg-primary-light">
                          <div className="col-lg-4 ps-lg-0">
                            <img src="./assets/images/iciciinsur-removebg-preview.png" className="Iscomimg" alt />
                          </div>
                          <div className="col-lg-8 pe-lg-0">
                            <div className="text-end">
                              <p className="fw-bold mb-0 fs-3">
                                IDV
                                <span className="fw-normal"> 3,29,234</span>
                              </p>
                              <p className="mb-0 fs-3 fw-light">( 3,29,234 -  3,29,234 IDV Range)</p>
                            </div>
                          </div>
                        </div>
                        <div className="buybtn text-center d-flex justify-content-between px-3 pt-3 border border-3 border-bottom-0">
                          <div className>
                            <small className="d-block fs-5 fw-bold mt-1">(For 1 year)</small>
                            <a href="#" className="btn btn-primary fs-3 px-4 py-2 my-2">
                              1,234
                            </a>
                            <a href="#" className="d-block fs-4 text-dark text-decoration-underline">
                              Premium Breakup
                            </a>
                          </div>
                          <div>
                            <small className="d-block fs-5 fw-bold mt-1">(For 3 year)</small>
                            <a href="#" className="btn btn-primary fs-3 px-4 py-2 my-2 disabled">
                              N/A
                            </a>
                            <a href="#" className="d-block fs-4 text-dark text-decoration-underline d-none">
                              Premium Breakup
                            </a>
                          </div>
                          <div>
                            <small className="d-block fs-5 fw-bold mt-1">(For 5 year)</small>
                            <a href="#" className="btn btn-primary fs-3 px-4 py-2 my-2 disabled">
                              N/A
                            </a>
                            <a href="#" className="d-block fs-4 text-dark text-decoration-underline d-none">
                              Premium Breakup
                            </a>
                          </div>
                        </div>
                        <div className="adons fs-4 pt-4 px-3 border border-3 border-top-0">
                          <ul className="p-0 row mx-0">
                            <li className="col-lg-6 ps-lg-0 mb-2">
                              Zero Depreciation Cover
                              <span className="text-danger">
                                (<i className="fa fa-times" />)
                              </span>
                            </li>
                            <li className="col-lg-6 pe-lg-0 mb-2">
                              Key Replacement ( 105)
                              <span className="text-danger">
                                (<i className="fa fa-times" />)
                              </span>
                            </li>
                            <li className="col-lg-6 ps-lg-0 mb-2">
                              Engine Gear Box Cover
                              <span className="text-danger">
                                (<i className="fa fa-times" />)
                              </span>
                            </li>
                            <li className="col-lg-6 pe-lg-0 mb-2">
                              Zero Depreciation Cover
                              <span className="text-danger">
                                (<i className="fa fa-times" />)
                              </span>
                            </li>
                            <li className="col-lg-6 ps-lg-0 mb-2">
                              Key Replacement ( 105)
                              <span className="text-danger">
                                (<i className="fa fa-times" />)
                              </span>
                            </li>
                            <li className="col-lg-6 pe-lg-0 mb-2">
                              Engine Gear Box Cover
                              <span className="text-danger">
                                (<i className="fa fa-times" />)
                              </span>
                            </li>
                            <li className="col-lg-6 ps-lg-0 mb-2">
                              Zero Depreciation Cover
                              <span className="text-success">
                                (<i className="fa fa-check" />)
                              </span>
                            </li>
                            <li className="col-lg-6 pe-lg-0 mb-2">
                              Key Replacement ( 105)
                              <span className="text-success">
                                (<i className="fa fa-check" />)
                              </span>
                            </li>
                            <li className="col-lg-6 ps-lg-0 mb-2">
                              Engine Gear Box Cover
                              <span className="text-success">
                                (<i className="fa fa-check" />)
                              </span>
                            </li>
                            <li className="col-lg-6 pe-lg-0 mb-2">
                              Zero Depreciation Cover
                              <span className="text-success">
                                (<i className="fa fa-check" />)
                              </span>
                            </li>
                            <li className="col-lg-6 ps-lg-0 mb-2">
                              Key Replacement ( 105)
                              <span className="text-success">
                                (<i className="fa fa-check" />)
                              </span>
                            </li>
                            <li className="col-lg-6 pe-lg-0 mb-2">
                              Engine Gear Box Cover
                              <span className="text-success">
                                (<i className="fa fa-check" />)
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="forsticky">
                    <div className="card p-0 mt-3  bg-transparent border-0">
                      <div className="card-body p-0">
                        <div className="row mx-0 justify-content-between align-items-center px-3 bg-primary-light">
                          <div className="col-lg-4 ps-lg-0">
                            <img src="./assets/images/hdfcinsur-removebg-preview.png" className="Iscomimg" alt />
                          </div>
                          <div className="col-lg-8 pe-lg-0">
                            <div className="text-end">
                              <p className="fw-bold mb-0 fs-3">
                                IDV
                                <span className="fw-normal"> 3,29,234</span>
                              </p>
                              <p className="mb-0 fs-3 fw-light">( 3,29,234 -  3,29,234 IDV Range)</p>
                            </div>
                          </div>
                        </div>
                        <div className="buybtn text-center d-flex justify-content-between px-3 pt-3 border border-3 border-bottom-0">
                          <div className>
                            <small className="d-block fs-5 fw-bold mt-1">(For 1 year)</small>
                            <a href="#" className="btn btn-primary fs-3 px-4 py-2 my-2">
                              1,234
                            </a>
                            <a href="#" className="d-block fs-4 text-dark text-decoration-underline">
                              Premium Breakup
                            </a>
                          </div>
                          <div>
                            <small className="d-block fs-5 fw-bold mt-1">(For 3 year)</small>
                            <a href="#" className="btn btn-primary fs-3 px-4 py-2 my-2 disabled">
                              N/A
                            </a>
                            <a href="#" className="d-block fs-4 text-dark text-decoration-underline d-none">
                              Premium Breakup
                            </a>
                          </div>
                          <div>
                            <small className="d-block fs-5 fw-bold mt-1">(For 5 year)</small>
                            <a href="#" className="btn btn-primary fs-3 px-4 py-2 my-2 disabled">
                              N/A
                            </a>
                            <a href="#" className="d-block fs-4 text-dark text-decoration-underline d-none">
                              Premium Breakup
                            </a>
                          </div>
                        </div>
                        <div className="adons fs-4 pt-4 px-3 border border-3 border-top-0">
                          <ul className="p-0 row mx-0">
                            <li className="col-lg-6 ps-lg-0 mb-2">
                              Zero Depreciation Cover
                              <span className="text-danger">
                                (<i className="fa fa-times" />)
                              </span>
                            </li>
                            <li className="col-lg-6 pe-lg-0 mb-2">
                              Key Replacement ( 105)
                              <span className="text-danger">
                                (<i className="fa fa-times" />)
                              </span>
                            </li>
                            <li className="col-lg-6 ps-lg-0 mb-2">
                              Engine Gear Box Cover
                              <span className="text-danger">
                                (<i className="fa fa-times" />)
                              </span>
                            </li>
                            <li className="col-lg-6 pe-lg-0 mb-2">
                              Zero Depreciation Cover
                              <span className="text-danger">
                                (<i className="fa fa-times" />)
                              </span>
                            </li>
                            <li className="col-lg-6 ps-lg-0 mb-2">
                              Key Replacement ( 105)
                              <span className="text-danger">
                                (<i className="fa fa-times" />)
                              </span>
                            </li>
                            <li className="col-lg-6 pe-lg-0 mb-2">
                              Engine Gear Box Cover
                              <span className="text-danger">
                                (<i className="fa fa-times" />)
                              </span>
                            </li>
                            <li className="col-lg-6 ps-lg-0 mb-2">
                              Zero Depreciation Cover
                              <span className="text-success">
                                (<i className="fa fa-check" />)
                              </span>
                            </li>
                            <li className="col-lg-6 pe-lg-0 mb-2">
                              Key Replacement ( 105)
                              <span className="text-success">
                                (<i className="fa fa-check" />)
                              </span>
                            </li>
                            <li className="col-lg-6 ps-lg-0 mb-2">
                              Engine Gear Box Cover
                              <span className="text-success">
                                (<i className="fa fa-check" />)
                              </span>
                            </li>
                            <li className="col-lg-6 pe-lg-0 mb-2">
                              Zero Depreciation Cover
                              <span className="text-success">
                                (<i className="fa fa-check" />)
                              </span>
                            </li>
                            <li className="col-lg-6 ps-lg-0 mb-2">
                              Key Replacement ( 105)
                              <span className="text-success">
                                (<i className="fa fa-check" />)
                              </span>
                            </li>
                            <li className="col-lg-6 pe-lg-0 mb-2">
                              Engine Gear Box Cover
                              <span className="text-success">
                                (<i className="fa fa-check" />)
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*----- Quote List container End -----*/}
      </div>
      {/*----- Main Container End -----*/}
    </>
  );
};

export default MyQuote;
