import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  handleLoginPos,
  sendErrorInfo,
  sendErrorMessage,
} from "../Services/PosService";
import cookie from "react-cookies";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveUserDetails } from "../../store/actions/userActions";
import Layout from "../common/Layout";
import { TabPane, TabContent } from "reactstrap";
import { PostDataWithToken } from "../../api/apiHelper";
import { startsWith } from "lodash";
const Login = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset: resetPosForm,
  } = useForm({ mode: "onBlur" });
  const {
    register: register1,
    formState: { errors: errors1 },
    handleSubmit: handleSubmit1,
    reset: reset1,
  } = useForm({ mode: "onBlur" });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modal, setModal] = React.useState(true);
  const [OTP, setOTP] = useState("");
  const [loginAs, setLoginAs] = React.useState("Customer");
  const toggle = () => setModal(!modal);
  const [userError, setUserError] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");

  const onSubmit = (data) => {
    handleLoginPos(data).then((response) => {
      if (response.status === true) {
        cookie.save("access_token", response.data.access_token, { path: "/" });
        dispatch(saveUserDetails(response.data));
        if (loginAs === "Pos") {
          if (response.data.isVerified === false) {
            navigate("/postraining");
          } else {
            window.location.href =
              "https://pos.expertcover.in/?id=" + response.data.access_token;
          }
        }
        // if (loginAs !== "Pos") {
        //   if (response.data.isVerified === true) {
        //     navigate("/crm/dashboard");
        //   } else {
        //     navigate("/postraining");
        //   }
        // }
      } else {
        console.log(response);
        // sendErrorMessage(response);
        console.log("error check", response.data.message);
        setErrorResponse(response?.data?.message);
        setUserError(true);
        console.log("userErrorResponse", userError);
      }
    });
  };

  const customerLogin = (data) => {
    if (OTP == "") {
      let postData = {
        phone: data.MobileNumber,
      };
      PostDataWithToken("auth/customer-login", postData).then((response) => {
        if (response.status === true) {
          setOTP(response.data);
          reset1({ otp: response.data });
        }
      });
    } else {
      let postData = {
        phone: data.MobileNumber,
        otp: data.otp,
      };
      PostDataWithToken("auth/verify-otp", postData).then((response) => {
        if (response.status === true) {
          cookie.save("access_token", response.data.access_token, {
            path: "/",
          });
          dispatch(saveUserDetails(response.data));
          navigate("/");
        }
      });
    }
  };
  return (
    <Layout>
      <section className="mylogin mt-5 py-5">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6 col-12 d-lg-block d-none">
              <img
                src="/assetss/images/LoginPageImg.png"
                className="w-100"
                alt=""
              />
            </div>
            <div className="col-lg-5 ms-auto">
              <nav className="detilsNav">
                <div
                  className="nav nav-tabs border-0 justify-content-center"
                  id="nav-tab"
                >
                  {/* <button
                    className={
                      loginAs === "Pos"
                        ? "nav-link rounded-0 border-0 active"
                        : "nav-link rounded-0 border-0"
                    }
                    onClick={() => {
                      setLoginAs("Pos");
                      resetPosForm();
                    }}
                  >
                    <i className="fas fa-user-plus me-3" />
                    PosP
                  </button> */}
                  <button
                    className={
                      loginAs === "Customer"
                        ? "nav-link rounded-0 border-0 active"
                        : "nav-link rounded-0 border-0"
                    }
                    onClick={() => {
                      setLoginAs("Customer");
                      reset1();
                      setUserError(false);
                    }}
                  >
                    <i className="fas fa-users me-3" />
                    Customer
                  </button>
                </div>
              </nav>
              <TabContent activeTab={loginAs}>
                {/* <TabPane tabId="Pos">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="card shadow-lg border-0">
                      <div className="card-body p-5">
                        <div className="form-floating mb-5 pb-4">
                          <input
                            type="email"
                            className="form-control"
                            id="Email"
                            {...register("email", {
                              required: "Email is required",
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Invalid email address",
                              },
                            })}
                            placeholder="Enter Email"
                            onChange={(e) => setUserError(false)}
                          />
                          <label htmlFor="Email">Enter Email</label>

                          <p className="f-error fs-4">
                            {errors?.email?.message}
                          </p>
                        </div>

                        <div className="form-floating mb-5  pb-4">
                          <input
                            type="password"
                            {...register("password", {
                              required: "Password is required",
                            })}
                            className="form-control"
                            id="PSsword"
                            placeholder="Enter Password"
                            onChange={(e) => setUserError(false)}
                          />
                          <label htmlFor="PSsword">Enter Password</label>

                          <p className="f-error fs-4">
                            {errors?.password?.message}
                          </p>
                        </div>

                        {userError && (
                          <p
                            className="f-error fs-4 "
                            style={{ position: "relative", bottom: "4rem" }}
                          >
                            {errorResponse}
                          </p>
                        )}

                        <button
                          type="submit"
                          className="btn btn-primary w-100 py-3 fs-1"
                        >
                          Login
                        </button>

                        <Link
                          to={"/forgetpassword"}
                          className="text-decoration-underline fs-2 fw-bold text-dark mt-4 d-block text-end"
                        >
                          Forgot password
                        </Link>
                      </div>
                    </div>
                  </form>
                </TabPane> */}
                <TabPane tabId="Customer">
                  <form onSubmit={handleSubmit1(customerLogin)}>
                    <div className="card shadow-lg border-0">
                      <div className="card-body p-5">
                        <div className="form-floating mb-5 pb-4">
                          <input
                            type="number"
                            {...register1("MobileNumber", {
                              required: "Mobile Number is required",
                              pattern: {
                                value:
                                  /((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/,
                                message: "Invalid Mobile Number",
                              },
                            })}
                            className="form-control fs-3 my-2"
                            id="DJYOGI1"
                            placeholder="Mobile Number"
                            onInput={(e) => {
                              e.target.value = e.target.value.slice(0, 10);
                            }}
                            maxLength="10"
                          />
                          <label htmlFor="DJYOGI1">Mobile Number</label>

                          <p className="f-error fs-4">
                            {errors1?.MobileNumber?.message}
                          </p>
                        </div>
                        {OTP != "" && (
                          <div className="form-floating mb-5  pb-4">
                            <input
                              type="text"
                              {...register1("otp", {
                                required: "OTP is required",
                              })}
                              className="form-control"
                              id="PSsword"
                              placeholder="Enter OTP"
                            />
                            <label htmlFor="PSsword">Enter OTP</label>

                            <p className="f-error fs-4">
                              {errors1?.otp?.message}
                            </p>
                          </div>
                        )}

                        <button
                          type="submit"
                          className="btn btn-primary w-100 py-3 fs-1"
                        >
                          {OTP == "" ? "Request OTP" : "Login"}
                        </button>
                      </div>
                    </div>
                  </form>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Login;
