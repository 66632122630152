import React from "react";
import "./footerpages.css";
import Header from "../Header";
import Footer from "../Footer";

function Cyber() {
  return (
    <>
      <Header />
      <div>
        <div className="insurance-cantainer">
          <h1>Cyber insurance</h1>
        </div>
        <div className="cantainer">
          <div className="box-info">
            <div className="box-1">
              <h2>
                "Cyber Insurance: Navigating the Evolving Landscape and
                Increasing Importance"
              </h2>
              <p>
                Cyber-insurance is a specialty insurance product intended to
                protect businesses from Internet-based risks, and more generally
                from risks relating to information technology infrastructure and
                activities. Risks of this nature are typically excluded from
                traditional commercial general liability policies or at least
                are not specifically defined in traditional insurance products.
                Coverage provided by cyber-insurance policies may include
                first-party coverage against losses such as data destruction,
                extortion, theft, hacking, and denial of service attacks;
                liability coverage indemnifying companies for losses to others
                caused, for example, by errors and omissions, failure to
                safeguard data, or defamation; and other benefits including
                regular security-audit, post-incident public relations and
                investigative expenses, and criminal reward funds.
              </p>
              <p>
                Because the cyber-insurance market in many countries is
                relatively small compared to other insurance products, its
                overall impact on emerging cyber threats is difficult to
                quantify.[1] As the impact to people and businesses from cyber
                threats is also relatively broad when compared to the scope of
                protection provided by insurance products , insurance companies
                continue to develop their services. According to a survey,[2]
                46% of all breaches have an effect on companies with fewer than
                1,000 employees. In this case, strong security measures and
                cyber liability insurance may be necessary.
              </p>
            </div>
            <div className="box-2 cyber">
              <img src="./assetss/images/Cyber.png" alt="Erroe" />
            </div>
          </div>
        </div>
        <div className="vision-info">
          <div className="vision-box">
            <div className="img-box">
              <img src="./assetss/images/vision.png" alt="Error" />
            </div>
            <div className="vision-box1">
              <h2>Our vision</h2>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend to repeat predefined chunks as necessary,
              </p>
            </div>
          </div>
        </div>
        <div className="btn-cantainer">
          <div className="btn-1">
            <h1>Solving your Insurance Needs on Time</h1>
          </div>
          <div className="btn-1">
            <h2>
              Get world-class insurance coverage that not only protects you but
              also helps you build wealth. Let us be your insurance partner.
            </h2>
            <div className="btn-info">
              <button>Get Started</button>
              <button>Contact Us</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Cyber;
