import React from "react";
import "./footerpages.css";
import Header from "../Header";
import Footer from "../Footer";

function FamilyHealthInsurance() {
  return (
    <>
      <Header />
      <div>
        <div className="insurance-cantainer">
          <h1>Family Health insurance</h1>
        </div>
        <div className="cantainer">
          <div className="box-info">
            <div className="box-1">
              <h2>
                "Comprehensive Guide to Family Health Insurance: Coverage,
                Benefits, and Options"
              </h2>
              <p>
                Family health insurance provides a vital safety net, ensuring
                the health and well-being of your loved ones. It's a type of
                insurance policy that typically covers medical expenses for all
                members of a family under a single plan. These plans usually
                encompass a range of medical services, including doctor visits,
                hospital stays, prescription medications, preventive care, and
                more. The advantage of a family health insurance policy is its
                ability to consolidate healthcare coverage for all family
                members, simplifying the administrative aspects and often
                resulting in cost savings compared to individual plans for each
                family member. It offers peace of mind, knowing that your family
                is protected in times of illness or medical emergencies.
              </p>
              <p>
                When considering a family health insurance plan, factors such as
                premium costs, coverage limits, network of healthcare providers,
                and out-of-pocket expenses should be carefully evaluated.
                Premiums are the regular payments made to maintain the insurance
                coverage, and they can vary based on factors like location,
                family size, and chosen coverage options. Understanding the
                policy's coverage in detail, including any exclusions and
                limitations, is essential to ensure it meets your family's
                specific healthcare needs. Regularly reviewing and updating your
                family health insurance coverage as your family's needs evolve
                is a responsible practice to ensure continued adequacy and
                optimal protection for your loved ones.
              </p>
            </div>
            <div className="box-2 familyimg">
              <img src="./assetss/images/familyimg2.png" alt="Erroe" />
            </div>
          </div>
        </div>
        <div className="vision-info">
          <div className="vision-box">
            <div className="img-box">
              <img src="./assetss/images/vision.png" alt="Error" />
            </div>
            <div className="vision-box1">
              <h2>Our vision</h2>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend to repeat predefined chunks as necessary,
              </p>
            </div>
          </div>
        </div>
        <div className="btn-cantainer">
          <div className="btn-1">
            <h1>Solving your Insurance Needs on Time</h1>
          </div>
          <div className="btn-1">
            <h2>
              Get world-class insurance coverage that not only protects you but
              also helps you build wealth. Let us be your insurance partner.
            </h2>
            <div className="btn-info">
              <button>Get Started</button>
              <button>Contact Us</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default FamilyHealthInsurance;
