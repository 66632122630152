import React from "react";
import { Link } from "react-router-dom";
import Header from "../../common/Header";
import Footer from "../../common/Footer";

const Blog = () => {
  return (
    <div>
      {/* <!-- Bread Crumb --> */}
      <Header />
      <div className="breadcrumb">
        <h2>Blog</h2>
      </div>

      {/* <!-- Blog List --> */}
      <div className="bloglist">
        <div className="container">
          <ul>
            <li>
              <div className="bloglistimg">
                <Link to="/blog-details">
                  <img
                    src={"/assetss/images/blogimg01.jpg"}
                    alt="img"
                    className="img-fluid"
                  />
                </Link>
              </div>
              <div className="posttime">17 July, 2023</div>
              <div className="postby">
                <img src={"/assetss/images/posticon.png"} alt="img" /> Posted by
                Admin
              </div>
              <div className="postTitle">
                <a href="#">
                  Understanding and Addressing Teen Depression and Recognizing
                  Its Danger
                </a>
              </div>
            </li>

            <li>
              <div className="bloglistimg">
                <a href="#">
                  <img
                    src={"/assetss/images/blogimg02.jpg"}
                    alt="img"
                    className="img-fluid"
                  />
                </a>
              </div>
              <div className="posttime">17 July, 2023</div>
              <div className="postby">
                <img src={"/assetss/images/posticon.png"} alt="img" /> Posted by
                Admin
              </div>
              <div className="postTitle">
                <a href="#">
                  Understanding and Addressing Teen Depression and Recognizing
                  Its Danger
                </a>
              </div>
            </li>

            <li>
              <div className="bloglistimg">
                <a href="#">
                  <img
                    src={"/assetss/images/blogimg03.jpg"}
                    alt="img"
                    className="img-fluid"
                  />
                </a>
              </div>
              <div className="posttime">17 July, 2023</div>
              <div className="postby">
                <img src={"/assetss/images/posticon.png"} alt="img" /> Posted by
                Admin
              </div>
              <div className="postTitle">
                <a href="#">
                  Understanding and Addressing Teen Depression and Recognizing
                  Its Danger
                </a>
              </div>
            </li>

            <li>
              <div className="bloglistimg">
                <a href="#">
                  <img
                    src={"/assetss/images/blogimg04.jpg"}
                    alt="img"
                    className="img-fluid"
                  />
                </a>
              </div>
              <div className="posttime">17 July, 2023</div>
              <div className="postby">
                <img src={"/assetss/images/posticon.png"} alt="img" /> Posted by
                Admin
              </div>
              <div className="postTitle">
                <a href="#">
                  Understanding and Addressing Teen Depression and Recognizing
                  Its Danger
                </a>
              </div>
            </li>

            <li>
              <div className="bloglistimg">
                <a href="#">
                  <img
                    src={"/assetss/images/blogimg05.jpg"}
                    alt="img"
                    className="img-fluid"
                  />
                </a>
              </div>
              <div className="posttime">17 July, 2023</div>
              <div className="postby">
                <img src={"/assetss/images/posticon.png"} alt="img" /> Posted by
                Admin
              </div>
              <div className="postTitle">
                <a href="#">
                  Understanding and Addressing Teen Depression and Recognizing
                  Its Danger
                </a>
              </div>
            </li>

            <li>
              <div className="bloglistimg">
                <a href="#">
                  <img
                    src={"/assetss/images/blogimg06.jpg"}
                    alt="img"
                    className="img-fluid"
                  />
                </a>
              </div>
              <div className="posttime">17 July, 2023</div>
              <div className="postby">
                <img src={"/assetss/images/posticon.png"} alt="img" /> Posted by
                Admin
              </div>
              <div className="postTitle">
                <a href="#">
                  Understanding and Addressing Teen Depression and Recognizing
                  Its Danger
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
