import React from 'react'
import Layout from '../../../common/Layout'
import { TabContent, TabPane } from 'reactstrap'
import { NavLink } from 'react-router-dom'
const InsuranceProcess2 = () => {
  const [activeTab, setActiveTab] = React.useState('1')
  const toggleTab = tab => setActiveTab(tab)
  return (
    <Layout>
      <div className="main-content-area">
        {/* Section: page title */}
        <section className="page-title layer-overlay overlay-theme-colored4-9 section-typo-light bg-img-center" data-tm-bg-img="assets/images/bg/bg1.jpg">
          <div className="container pt-50 pb-50">
            <div className="section-content">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h2 className="title">Vehicle's MMV</h2>
                  <nav className="breadcrumbs" role="navigation" aria-label="Breadcrumbs">
                    <div className="breadcrumbs">
                      <span><a href="index.html" rel="home">Home</a></span>
                      <span><i className="fa fa-angle-right" /></span>
                      <span><a href="#">Creat policy</a></span>
                      <span><i className="fa fa-angle-right" /></span>
                      <span><a href="#">Additional details</a></span>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Section: Service details */}
        <div className="container pb-70">
          <div className="section-content">
            <div className="row justify-content-between">
              <div className="col-lg-12">
                <TabContent activeTab={activeTab} >
                  <TabPane tabId="1" >
                    <fieldset>
                      <div className="form-group position-relative mb-4">
                        <label htmlFor="RTO" className="form-label">
                          Select prefered RTO for vehicle
                        </label>
                        <p className="m-0 f-error">(RTO City selection required*)</p>
                        <select className="custom-select" id="RTO">
                          <option className="d-none" selected>Select RTO Location</option>
                          <option>Ajmer</option>
                          <option>Bikaner</option>
                          <option>Jaipur</option>
                          <option>Chittorgarh</option>
                          <option>Banswara</option>
                          <option>Bundi</option>
                          <option>Kota</option>
                        </select>
                      </div>
                      <div className="form-group position-relative mb-4">
                        <label htmlFor="Manufacturer" className="form-label">
                          Select Manufacturer
                        </label>
                        <p className="m-0 f-error">(Manufacturer selection required*)</p>
                        <select className="custom-select" id="Manufacturer">
                          <option className="d-none" selected>Select Manufacturer</option>
                          <option>Hero</option>
                          <option>Maruti Suzuki</option>
                          <option>Dutson</option>
                          <option>BMW</option>
                          <option>Bharat Benz</option>
                          <option>Volvo</option>
                          <option>Wolkswagen</option>
                        </select>
                        <div className="selectInput d-flex justify-content-start flex-wrap mt-4">
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemaker" className="d-none form-control" id="v1" />
                          <label htmlFor="v1" className="forImg">
                            <img src="assets/images/lml.png" alt="" className="w-100" />
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemaker" className="d-none form-control" id="v2" />
                          <label htmlFor="v2" className="forImg">
                            <img src="assets/images/jawa.png" alt="" className="w-100" />
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemaker" className="d-none form-control" id="v3" />
                          <label htmlFor="v3" className="forImg">
                            <img src="assets/images/hero.png" alt="" className="w-100" />
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemaker" className="d-none form-control" id="v4" />
                          <label htmlFor="v4" className="forImg">
                            <img src="assets/images/honda.png" alt="" className="w-100" />
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemaker" className="d-none form-control" id="v5" />
                          <label htmlFor="v5" className="forImg">
                            <img src="assets/images/HEROHONDA.png" alt="" className="w-100" />
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemaker" className="d-none form-control" id="v6" />
                          <label htmlFor="v6" className="forImg">
                            <img src="assets/images/yamaha.png" alt="" className="w-100" />
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemaker" className="d-none form-control" id="v7" />
                          <label htmlFor="v7" className="forImg">
                            <img src="assets/images/MAHINDRA.png" alt="" className="w-100" />
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemaker" className="d-none form-control" id="v8" />
                          <label htmlFor="v8" className="forImg">
                            <img src="assets/images/KTM.png" alt="" className="w-100" />
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemaker" className="d-none form-control" id="v9" />
                          <label htmlFor="v9" className="forImg">
                            <img src="assets/images/HYOSUNG.png" alt="" />
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemaker" className="d-none form-control" id="v10" />
                          <label htmlFor="v10" className="forImg">
                            <img src="assets/images/ROYAL_ENFIELD.png" alt="" />
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemaker" className="d-none form-control" id="v11" />
                          <label htmlFor="v11" className="forImg">
                            <img src="assets/images/lml.png" alt="" />
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemaker" className="d-none form-control" id="v12" />
                          <label htmlFor="v12" className="forImg">
                            <img src="assets/images/jawa.png" alt="" />
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemaker" className="d-none form-control" id="v13" />
                          <label htmlFor="v13" className="forImg">
                            <img src="assets/images/hero.png" alt="" />
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemaker" className="d-none form-control" id="v14" />
                          <label htmlFor="v14" className="forImg">
                            <img src="assets/images/honda.png" alt="" />
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemaker" className="d-none form-control" id="v15" />
                          <label htmlFor="v15" className="forImg">
                            <img src="assets/images/HEROHONDA.png" alt="" />
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemaker" className="d-none form-control" id="v16" />
                          <label htmlFor="v16" className="forImg">
                            <img src="assets/images/yamaha.png" alt="" />
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemaker" className="d-none form-control" id="v17" />
                          <label htmlFor="v17" className="forImg">
                            <img src="assets/images/MAHINDRA.png" alt="" />
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemaker" className="d-none form-control" id="v18" />
                          <label htmlFor="v18" className="forImg">
                            <img src="assets/images/KTM.png" alt="" />
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemaker" className="d-none form-control" id="v19" />
                          <label htmlFor="v19" className="forImg">
                            <img src="assets/images/HYOSUNG.png" alt="" />
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemaker" className="d-none form-control" id="v20" />
                          <label htmlFor="v20" className="forImg">
                            <img src="assets/images/ROYAL_ENFIELD.png" alt="" />
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemaker" className="d-none form-control" id="v21" />
                          <label htmlFor="v21" className="d-flex align-items-center justify-content-center forImg">
                            <p className="m-0">Others</p>
                          </label>
                        </div>
                      </div>
                    </fieldset>
                    <fieldset>
                      <div className="form-group position-relative mb-4">
                        <label htmlFor="Modal" className="form-label">
                          Select Modal
                        </label>
                        <p className="m-0 f-error">(Vehicle modal selection required*)</p>
                        <select className="custom-select" id="Modal">
                          <option className="d-none" selected>Select vehicle modal</option>
                          <option className="text-capitalize">chief classic</option>
                          <option className="text-capitalize">chief dark horse</option>
                          <option className="text-capitalize">chief vintage</option>
                          <option className="text-capitalize">chieftain</option>
                          <option className="text-capitalize">road master</option>
                          <option className="text-capitalize">scout</option>
                          <option className="text-capitalize">bullet standard 350CC</option>
                        </select>
                        <div className="selectInput d-flex justify-content-start flex-wrap mt-4">
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vtype" className="d-none form-control" id="v51" />
                          <label htmlFor="v51" className="text-capitalize">
                            <p className="mb-0">Datsun Ready go</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vtype" className="d-none form-control" id="v52" />
                          <label htmlFor="v52" className="text-capitalize">
                            <p className="mb-0">chief classic</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vtype" className="d-none form-control" id="v53" />
                          <label htmlFor="v53" className="text-capitalize">
                            <p className="mb-0">Chief dark horse</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vtype" className="d-none form-control" id="v54" />
                          <label htmlFor="v54" className="text-capitalize">
                            <p className="mb-0">chief vintage</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vtype" className="d-none form-control" id="v55" />
                          <label htmlFor="v55" className="text-capitalize">
                            <p className="mb-0">chieftain</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vtype" className="d-none form-control" id="v56" />
                          <label htmlFor="v56" className="text-capitalize">
                            <p className="mb-0">Road master</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vtype" className="d-none form-control" id="v57" />
                          <label htmlFor="v57" className="text-capitalize">
                            <p className="mb-0">Scout</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vtype" className="d-none form-control" id="v58" />
                          <label htmlFor="v58" className="text-capitalize">
                            <p className="mb-0">bullet standard 350CC</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vtype" className="d-none form-control" id="v211" />
                          <label htmlFor="v211" className="d-flex align-items-center justify-content-center">
                            <p className="m-0">Others</p>
                          </label>
                        </div>
                      </div>
                      <div className="form-group position-relative mb-4">
                        <label htmlFor="Modal" className="form-label">
                          Select vehicle varient
                        </label>
                        <p className="m-0 f-error">(Vehicle varient selection required*)</p>
                        <select className="custom-select" id="Varient">
                          <option className="d-none" selected>Select vehicle varient</option>
                          <option className="text-capitalize">DUAL TONE (1811 CC) - PETROL</option>
                          <option className="text-capitalize">SOLID COLOR (1811 CC) - PETROL</option>
                          <option className="text-capitalize">CLASSIC STD (1811 CC) - PETROL</option>
                          <option className="text-capitalize">R (999 CC) - PETROL</option>
                          <option className="text-capitalize">XR (999 CC) - PETROL</option>
                          <option className="text-capitalize">JAWA 42 STD (293 CC) - PETROL</option>
                          <option className="text-capitalize">JAWA 42 ABS (293 CC) - PETROL</option>
                        </select>
                        <div className="selectInput d-flex justify-content-start flex-wrap mt-4">
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemodal" className="d-none form-control" id="v511" />
                          <label htmlFor="v511" className="text-capitalize">
                            <p className="mb-0">DUAL TONE (1811 CC) - PETROL</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemodal" className="d-none form-control" id="v522" />
                          <label htmlFor="v522" className="text-capitalize">
                            <p className="mb-0">SOLID COLOR (1811 CC) - PETROL</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemodal" className="d-none form-control" id="v533" />
                          <label htmlFor="v533" className="text-capitalize">
                            <p className="mb-0">CLASSIC STD (1811 CC) - PETROL</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemodal" className="d-none form-control" id="v544" />
                          <label htmlFor="v544" className="text-capitalize">
                            <p className="mb-0">R (999 CC) - PETROL</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemodal" className="d-none form-control" id="v555" />
                          <label htmlFor="v555" className="text-capitalize">
                            <p className="mb-0">XR (999 CC) - PETROL</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemodal" className="d-none form-control" id="v566" />
                          <label htmlFor="v566" className="text-capitalize">
                            <p className="mb-0">JAWA 42 STD (293 CC) - PETROL</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemodal" className="d-none form-control" id="v577" />
                          <label htmlFor="v577" className="text-capitalize">
                            <p className="mb-0">JAWA 42 ABS (293 CC) - PETROL</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemodal" className="d-none form-control" id="v588" />
                          <label htmlFor="v588" className="text-capitalize">
                            <p className="mb-0">bullet 42 Non ABS (293 CC) - PETROL</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vemodal" className="d-none form-control" id="v2511" />
                          <label htmlFor="v2511" className="d-flex align-items-center justify-content-center">
                            <p className="m-0">Others</p>
                          </label>
                        </div>
                      </div>
                      <div className="form-group position-relative mb-4">
                        <label className="form-label">
                          Select vehicle Registration year
                        </label>
                        <p className="m-0 f-error">(Vehicle registration year selection required*)</p>
                        <div className="selectInput d-flex justify-content-start flex-wrap">
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vyear" className="d-none form-control" id="y511" />
                          <label htmlFor="y511" className="text-capitalize">
                            <p className="mb-0">2022</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vyear" className="d-none form-control" id="y522" />
                          <label htmlFor="y522" className="text-capitalize">
                            <p className="mb-0">2021</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vyear" className="d-none form-control" id="y533" />
                          <label htmlFor="y533" className="text-capitalize">
                            <p className="mb-0">2020</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vyear" className="d-none form-control" id="y544" />
                          <label htmlFor="y544" className="text-capitalize">
                            <p className="mb-0">2019</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vyear" className="d-none form-control" id="y555" />
                          <label htmlFor="y555" className="text-capitalize">
                            <p className="mb-0">2018</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vyear" className="d-none form-control" id="y566" />
                          <label htmlFor="y566" className="text-capitalize">
                            <p className="mb-0">2017</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vyear" className="d-none form-control" id="y577" />
                          <label htmlFor="y577" className="text-capitalize">
                            <p className="mb-0">2016</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vyear" className="d-none form-control" id="y588" />
                          <label htmlFor="y588" className="text-capitalize">
                            <p className="mb-0">2015</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vyear" className="d-none form-control" id="y2511" />
                          <label htmlFor="y2511" className="d-flex align-items-center justify-content-center">
                            <p className="m-0">2014</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vyear" className="d-none form-control" id="y251" />
                          <label htmlFor="y251" className="d-flex align-items-center justify-content-center">
                            <p className="m-0">2013</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vyear" className="d-none form-control" id="y25" />
                          <label htmlFor="y25" className="d-flex align-items-center justify-content-center">
                            <p className="m-0">2012</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vyear" className="d-none form-control" id="y2" />
                          <label htmlFor="y2" className="d-flex align-items-center justify-content-center">
                            <p className="m-0">2011</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vyear" className="d-none form-control" id="y15" />
                          <label htmlFor="y15" className="d-flex align-items-center justify-content-center">
                            <p className="m-0">2010</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vyear" className="d-none form-control" id="yq5" />
                          <label htmlFor="yq5" className="d-flex align-items-center justify-content-center">
                            <p className="m-0">2009</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vyear" className="d-none form-control" id="5y" />
                          <label htmlFor="5y" className="d-flex align-items-center justify-content-center">
                            <p className="m-0">2008</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vyear" className="d-none form-control" id="y25y" />
                          <label htmlFor="y25y" className="d-flex align-items-center justify-content-center">
                            <p className="m-0">2007</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vyear" className="d-none form-control" id="y25q" />
                          <label htmlFor="y25q" className="d-flex align-items-center justify-content-center">
                            <p className="m-0">2006</p>
                          </label>
                          {/*----- Radio input with label -----*/}
                          <input type="radio" name="vyear" className="d-none form-control" id="y25d" />
                          <label htmlFor="y25d" className="d-flex align-items-center justify-content-center">
                            <p className="m-0">2005</p>
                          </label>
                          <p className="m-0 f-error position-inherit mx-auto">
                            Note : Years before 2005 is only applicable for Third Party Only
                            Policy.
                          </p>
                        </div>
                      </div>
                    </fieldset>
                    <p className="m-0">
                      <a href="javascript:void(0)" onClick={() => toggleTab("2")} id="SaveAccount" className="btn btn-theme-colored1 btn-round submit text-decoration-none text-white w-auto">
                        Submit
                      </a>
                    </p>
                  </TabPane>
                  <TabPane tabId="2" >
                    <fieldset>
                      <div className="row m-0 ">
                        <div className="col-lg-4 ps-0">
                          <div className="form-group position-relative mb-4">
                            <label htmlFor="odpolicy" className="form-label">
                              Required standalone OD policy?
                            </label>
                            <p className="m-0 f-error">(Input required*)</p>
                            <select className="custom-select" id="odpolicy">
                              <option className="d-none" selected>Select standalone requirments
                              </option>
                              <option>Yes</option>
                              <option>No</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group position-relative mb-4">
                            <label htmlFor="oldstatus" className="form-label">
                              Previous policy's completion status
                            </label>
                            <p className="m-0 f-error">(Input required*)</p>
                            <select className="custom-select" id="oldstatus">
                              <option className="d-none" selected>Select status</option>
                              <option>Not expired</option>
                              <option>Expired within 90 days</option>
                              <option>Expired more than 90 days</option>
                              <option>No information about previous policy</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-4 pe-0">
                          <div className="form-group position-relative mb-4">
                            <label htmlFor="oldtype" className="form-label">
                              Previous policy's varient
                            </label>
                            <p className="m-0 f-error">(Input required*)</p>
                            <select className="custom-select" id="oldtype">
                              <option className="d-none" selected>Select old policy varient
                              </option>
                              <option>Comprehensive</option>
                              <option>Third Party</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-4 ps-0">
                          <div className="form-group position-relative mb-4">
                            <label htmlFor="oldtype" className="form-label">
                              Previous insurance provider
                            </label>
                            <p className="m-0 f-error">(Input required*)</p>
                            <select className="custom-select" id="oldtype">
                              <option className="d-none" selected>Select old policy provider
                              </option>
                              <option>Bajaj finserv</option>
                              <option>ICICI Lambord</option>
                              <option>SBI Insurance</option>
                              <option>HDFC Insurance</option>
                              <option>Hero moto Finence service</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-4 ">
                          <div className="form-group position-relative mb-4">
                            <label htmlFor="mfgmonth" className="form-label w-100">
                              Mfg. month of the year
                            </label>
                            {/*----- Input warning -----*/}
                            <p className="m-0 f-error">(Input not valid)</p>
                            <input type="date" className="form-control" id="mfgmonth" />
                          </div>
                        </div>
                        <div className="col-lg-4 pe-0">
                          <div className="form-group position-relative mb-4">
                            <label htmlFor="regv" className="form-label">
                              Reg. date of vehicle
                            </label>
                            {/*----- Input warning -----*/}
                            <p className="m-0 f-error">(Input not valid)</p>
                            <input type="date" className="form-control" id="regv" />
                          </div>
                        </div>
                        <div className="col-lg-4 ps-0">
                          <div className="form-group position-relative mb-4">
                            <label htmlFor="regv1" className="form-label">
                              OD Policy Expiry Date
                            </label>
                            {/*----- Input warning -----*/}
                            <p className="m-0 f-error">(Input not valid)</p>
                            <input type="date" className="form-control" id="regv1" />
                          </div>
                        </div>
                        <div className="col-lg-4 ">
                          <div className="form-group position-relative mb-4">
                            <label htmlFor="tppolicy" className="form-label">
                              TP policy Insurer
                            </label>
                            <p className="m-0 f-error">(Input required*)</p>
                            <select className="custom-select valid" id="tppolicy">
                              <option className="d-none" selected>Select TP policy provider
                              </option>
                              <option>Bajaj finserv</option>
                              <option>ICICI Lambord</option>
                              <option>SBI Insurance</option>
                              <option>HDFC Insurance</option>
                              <option>Hero moto Finence service</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-4 pe-0">
                          <div className="form-group position-relative mb-4">
                            <label htmlFor="regv2" className="form-label">
                              TP Policy Expiry Date
                            </label>
                            {/*----- Input warning -----*/}
                            <p className="m-0 f-error">(Input not valid)</p>
                            <input type="date" className="form-control" id="regv2" />
                          </div>
                        </div>
                        <div className="col-lg-4 ps-0">
                          <div className="form-group position-relative mb-4">
                            <label htmlFor="prex" className="form-label">
                              Prev. year policy expry date
                            </label>
                            {/*----- Input warning -----*/}
                            <p className="m-0 f-error">(Input not valid)</p>
                            <input type="date" className="form-control" id="prex" />
                          </div>
                        </div>
                        <div className="col-lg-4 ">
                          <div className="form-group position-relative mb-4">
                            <label htmlFor="ncb" className="form-label">
                              Previous No Claim Bonus(NCB)
                            </label>
                            <p className="m-0 f-error">(Input required*)</p>
                            <select className="custom-select" id="ncb">
                              <option className="d-none" selected>Select NCB</option>
                              <option>0%</option>
                              <option>5%</option>
                              <option>10%</option>
                              <option>15%</option>
                              <option>20%</option>
                              <option>25%</option>
                              <option>50%</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-12 m-0 p-0" />
                        <div className="col-lg-4 pe-0">
                          <div className="form-group position-relative mb-4">
                            <label className="form-label d-block">
                              Vehicle ownnership type
                            </label>
                            <div className="form-check form-check-inline position-relative">
                              <input className="form-check-input d-none" type="radio" id="ownertype1" name="ownertype" />
                              <label className="form-check-label" htmlFor="ownertype1">Individial</label>
                            </div>
                            <div className="form-check form-check-inline position-relative">
                              <input className="form-check-input d-none" type="radio" name="ownertype" id="ownertype2" />
                              <label className="form-check-label" htmlFor="ownertype2">Organization</label>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 ps-0">
                          <div className="form-group position-relative mb-4">
                            <label className="form-label d-block">
                              Do you have a valid driving license?
                            </label>
                            <div className="form-check form-check-inline position-relative">
                              <input className="form-check-input d-none" type="radio" id="dlvalid1" name="dlvalid" />
                              <label className="form-check-label" htmlFor="dlvalid1">Yes</label>
                            </div>
                            <div className="form-check form-check-inline position-relative">
                              <input className="form-check-input d-none" type="radio" name="dlvalid" id="dlvalid2" />
                              <label className="form-check-label" htmlFor="dlvalid2">NO</label>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 ">
                          <div className="form-group position-relative mb-4">
                            <label className="form-label d-block">
                              Do you have already Personal Accidental (PA) cover of Rs.
                              1500000?
                            </label>
                            <div className="form-check form-check-inline position-relative">
                              <input className="form-check-input d-none" type="radio" id="Invalid1" name="Invalid" />
                              <label className="form-check-label" htmlFor="Invalid1">Yes</label>
                            </div>
                            <div className="form-check form-check-inline position-relative">
                              <input className="form-check-input d-none" type="radio" name="Invalid" id="Invalid2" />
                              <label className="form-check-label" htmlFor="Invalid2">NO</label>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 pe-0">
                          <div className="form-group position-relative mb-4">
                            <label className="form-label d-block">
                              Owner changed in last 12 months?
                            </label>
                            <div className="form-check form-check-inline position-relative">
                              <input className="form-check-input d-none" type="radio" id="onchan1" name="onchan" />
                              <label className="form-check-label" htmlFor="onchan1">Yes</label>
                            </div>
                            <div className="form-check form-check-inline position-relative">
                              <input className="form-check-input d-none" type="radio" name="onchan" id="onchan2" />
                              <label className="form-check-label" htmlFor="onchan2">NO</label>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 ps-0">
                          <div className="form-group position-relative mb-4">
                            <label className="form-label d-block">
                              Claim made in expiring policy?
                            </label>
                            <div className="form-check form-check-inline position-relative">
                              <input className="form-check-input d-none" type="radio" id="claimmade1" name="claimmade" />
                              <label className="form-check-label" htmlFor="claimmade1">Yes</label>
                            </div>
                            <div className="form-check form-check-inline position-relative">
                              <input className="form-check-input d-none" type="radio" name="claimmade" id="claimmade2" />
                              <label className="form-check-label" htmlFor="claimmade2">NO</label>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group position-relative mb-4">
                            <label className="form-label d-block">
                              Required Only Third Party?
                            </label>
                            <div className="form-check form-check-inline position-relative">
                              <input className="form-check-input d-none" type="radio" id="tparty1" name="tparty" />
                              <label className="form-check-label" htmlFor="tparty1">Yes</label>
                            </div>
                            <div className="form-check form-check-inline position-relative">
                              <input className="form-check-input d-none" type="radio" name="tparty" id="tparty2" />
                              <label className="form-check-label" htmlFor="tparty2">NO</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    <p className="m-0">
                      <a href="javascript:void(0)" onClick={() => toggleTab("3")} id="SaveAccount" className="btn btn-theme-colored1 btn-round submit text-decoration-none text-white w-auto">
                        Submit
                      </a>
                    </p>
                  </TabPane>
                  <TabPane tabId="3" >

                    <fieldset>
                      <div className="row mx-0 justify-content-between align-items-center mb-4">
                        <div className="col-lg-5 order-lg-1 order-2 ps-0">
                          <div className="form-group position-relative mb-4">
                            <label htmlFor="fname" className="form-label">
                              First Name
                            </label>
                            {/*---- Error message ----*/}
                            <p className="m-0 f-error">(Input not valid*)</p>
                            <input type="text" className="form-control" id="fname" placeholder="Enter your first name" />
                          </div>
                          <div className="form-group position-relative mb-4">
                            <label htmlFor="lname" className="form-label">
                              Last Name
                            </label>
                            {/*---- Error message ----*/}
                            <p className="m-0 f-error">(Input not valid*)</p>
                            <input type="text" className="form-control" id="lname" placeholder="Enter your last name" />
                          </div>
                          <div className="form-group position-relative mb-4">
                            <label htmlFor="email" className="form-label">
                              Email address
                            </label>
                            {/*---- Error message ----*/}
                            <p className="m-0 f-error">(Input not valid*)</p>
                            <input type="email" className="form-control" id="email" placeholder="Enter your email" />
                          </div>
                          <div className="d-lg-flex justify-content-between">
                            <div className="form-group position-relative mb-4 w-50">
                              <label htmlFor="cnum" className="form-label">
                                Contact number
                              </label>
                              {/*---- Error message ----*/}
                              <p className="m-0 f-error">(Input not valid*)</p>
                              <input type="number" className="form-control" id="cnum" placeholder="Enter your contact no." />
                            </div>
                            <div className="form-group position-relative mb-4">
                              <label htmlFor="cnum" className="form-label">
                                Enter OTP
                              </label>
                              {/*---- Error message ----*/}
                              <p className="m-0 f-error">(Input not valid*)</p>
                              <input type="number" className="form-control" id="cnum" placeholder="Enter OTP" />
                            </div>
                          </div>
                          <div className="form-group position-relative mb-4">
                            <div className="form-check form-check-inline position-relative">
                              <input className="form-check-input d-none" type="checkbox" id="tnc" name="ownertype" />
                              <label className="form-check-label" htmlFor="tnc">
                                Please accept <a href="#">Privacy Policy</a> and <a href="#">Terms and condition</a>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 order-lg-2 order-1 pe-0">
                          <img src="assets/images/vehicleinsurance.png" alt="" className="text-cetner w-100" />
                        </div>
                      </div>
                    </fieldset>
                    <p className="m-0">
                      <NavLink to="/quotelist" id="SaveAccount" className="btn btn-theme-colored1 btn-round submit text-decoration-none text-white w-auto">
                        Get your quote
                      </NavLink>
                    </p>
                  </TabPane>



                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default InsuranceProcess2