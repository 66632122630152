import React from "react";
import "./footerpages.css";
import Header from "../Header";
import Footer from "../Footer";

function TermInsurance() {
  return (
    <>
      <div>
        <Header />
        <div className="insurance-cantainer">
          <h1>Term insurance</h1>
        </div>
        <div className="cantainer">
          <div className="box-info">
            <div className="box-1">
              <h2>
                "Understanding Term Life Insurance: Your Guide to Essential
                Coverage"
              </h2>
              <p>
                Term life insurance or term assurance is life insurance that
                provides coverage at a fixed rate of payments for a limited
                period of time, the relevant term. After that period expires,
                coverage at the previous rate of premiums is no longer
                guaranteed and the client must either forgo coverage or
                potentially obtain further coverage with different payments or
                conditions. If the life insured dies during the term, the death
                benefit will be paid to the beneficiary. Term insurance is
                typically the least expensive way to purchase a substantial
                death benefit on a coverage amount per premium dollar basis over
                a specific period of time.
              </p>
              <p>
                Term life insurance can be contrasted to permanent life
                insurance such as whole life, universal life, and variable
                universal life, which guarantee coverage at fixed premiums for
                the lifetime of the covered individual unless the policy is
                allowed to lapse due to failure to pay premiums. Term insurance
                is not generally used for estate planning needs or charitable
                giving strategies but is used for pure income replacement needs
                for an individual. Term insurance functions in a manner similar
                to most other types of insurance in that it satisfies claims
                against what is insured if the premiums are up to date and the
                contract has not expired and does not provide for a return of
                premium dollars if no claims are filed.
              </p>
            </div>
            <div className="box-2">
              <img src="/assetss/images/term1.jpg" alt="Error" />
            </div>
          </div>
        </div>
        <div className="vision-info">
          <div className="vision-box">
            <div className="img-box">
              <img src="/assetss/images/vision.png" alt="Error" />
            </div>
            <div className="vision-box1">
              <h2>Our vision</h2>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend to repeat predefined chunks as necessary,
              </p>
            </div>
          </div>
        </div>
        <div className="btn-cantainer">
          <div className="btn-1">
            <h1>Solving your Insurance Needs on Time</h1>
          </div>
          <div className="btn-1">
            <h2>
              Get world-class insurance coverage that not only protects you but
              also helps you build wealth. Let us be your insurance partner.
            </h2>
            <div className="btn-info">
              <button>Get Started</button>
              <button>Contact Us</button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default TermInsurance;
